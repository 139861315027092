import React, { useEffect } from 'react';
import { Redirect, Route } from 'react-router-dom';
import {IonApp, IonRouterOutlet, IonSpinner} from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import {useStore, withStore} from "./store/react-pure-store";
import store from "./store/store";

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import Profile from './views/page/Profile.page';
import Home from './views/page/Home.page';
import Installation from './views/page/Installation.page';
import Equipments from './views/page/Equipments.page';
import Inventory from './views/page/Inventory.page';
import Anomaly from './views/page/Anomaly.page';
import Documents from './views/page/Documents.page'
import Access from './views/page/Access.page';
import Meter from './views/page/Meter.page';
import { populateInstallation } from './controllers/installation.service';
import compose from './store/compose';
import Procedures from "./views/page/Procedures.page";

const ensureReady = boolGetter => Component => props => {
  const ready = boolGetter(useStore(store));

  if (ready) {
    return <Component {...props} />
  }

  return (
    <div style={{ position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
      <IonSpinner name="crescent" />
    </div>
  );
};

const withCurrentInstallation = Component => {
  
  const ReadyComponent = ensureReady(state => state.currentInstallation !== null)(Component);

  return props => {
    const currentInstallation = useStore(store, state => state.currentInstallation);

    useEffect(() => {
      if (currentInstallation === null) {
        const installationId = props.match.params.installationId;

        populateInstallation(installationId).then(() => {
          store.update(state => {
            state.currentInstallation = state.installationList[installationId];
            state.currentInstallationState = 'saved';
          });
        }).catch(console.error);
      }
    }, [props.match.params.installationId, currentInstallation]);

    return <ReadyComponent {...props} />;
  };
};

const ensureInstallationsReady = ensureReady(state => state.installationsReady);

const readyAndWithCurrentInstallation = compose(
  ensureInstallationsReady,
  withCurrentInstallation,
);

const Router: React.FC = compose(
  ensureReady(state => state.regionsReady),
  withStore(store, state => ({
    selectedRegion: state.selectedRegion,
  })),
)(({ selectedRegion }) => (
  <IonReactRouter>
    <IonRouterOutlet>
      <Route exact path="/profile" component={Profile} />
      <Route exact path="/procedures" component={Procedures} />
      <Route exact path="/home" component={ensureInstallationsReady(Home)} />
      <Route exact path="/installation/:installationId" component={readyAndWithCurrentInstallation(Installation)} />
      <Route exact path="/installation/:installationId/equipments" component={readyAndWithCurrentInstallation(Equipments)} />
      <Route exact path="/installation/:installationId/equipment/:equipmentId" component={readyAndWithCurrentInstallation(Inventory)} />
      <Route exact path="/installation/:installationId/equipment/:equipmentId/anomaly/:anomalyType" component={readyAndWithCurrentInstallation(Anomaly)} />
      <Route exact path="/installation/:installationId/documents" component={readyAndWithCurrentInstallation(Documents)} />
      <Route exact path="/installation/:installationId/access" component={readyAndWithCurrentInstallation(Access)} />
      <Route exact path="/installation/:installationId/meter" component={readyAndWithCurrentInstallation(Meter)} />
      <Route exact path="/" render={() => <Redirect to={selectedRegion === null ? '/profile' : '/home'} />} />
    </IonRouterOutlet>
  </IonReactRouter>
));

const App: React.FC = () => {
  return (
    <IonApp>
      <Router />
    </IonApp>
  );
};

export default App;
