import React, {useEffect, useState} from 'react';
import {useHistory, useLocation, useRouteMatch, matchPath} from 'react-router';
import {
  IonPage, IonRow, IonCol, IonFooter, IonButton, IonIcon, IonItem, IonLabel, IonContent,
  IonCardSubtitle, IonList, IonFab, IonFabButton, IonGrid, IonSpinner
} from '@ionic/react';
import {
  arrowDropdownCircle, arrowDropupCircle, arrowForward, settings, document, key, eye,
  pin, build, calendar, attach, person, checkmarkCircle, arrowBack
} from 'ionicons/icons';
import { withStore } from '../../store/react-pure-store';
import store from '../../store/store';
import iInstallation from '../../models/installation.module';
import { withPatchFilter } from "../../store/pure-store";
import { getUserInfo } from '../../controllers/gapiController';
import { useLeaveConfirm } from '../../helpers/hooks';
import { displayDate } from '../../helpers/date';

const Installation: any = ({ installation, currentInstallationState, installationSaveState, installationSaveError, match: { params: { installationId } }, mergeInstallationToReference, restoreInstallationSaveState }:
  { installation: iInstallation, currentInstallationState: string, installationSaveState: string, installationSaveError: string, match: any, resetCurrentInstallation, mergeInstallationToReference, restoreInstallationSaveState }) => {

  const [detailIsOpen, setDetailIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const match = useRouteMatch();
  const location = useLocation();

  const matching = matchPath(location.pathname, {
    path: match.path,
    exact: match.isExact,
  }) !== null ? true : false;

  useEffect(() => {
    if (matching) {
      setLoading(false);
    }
  }, [matching]);

  const deliveredDocs = Object.values(installation.documentList).filter(doc => doc.delivered === true).length;

  useLeaveConfirm(
    `/installation/${installationId}`,
    `/home`,
    () => currentInstallationState === 'dirty',
    'Vous êtes sur le point de quitter l\'installation sans la sauvegarder. Confirmer ?'
  );

  useEffect(() => {
    if (installationSaveState === 'error') {
      alert('Une erreur s\'est produite lors de l\'enregistrement des modifications, vérifiez la qualité de votre connexion internet\nLog erreur :\n' + installationSaveError);
      restoreInstallationSaveState();
    }
  }, [installationSaveState, installationSaveError, restoreInstallationSaveState]);

  return (

    <IonPage>
      <IonContent color="secondary">
        <IonItem color='primary' class="ion-no-padding">
          <IonGrid class="ion-no-padding">
            <IonRow style={{ height: "60px" }} class="ion-align-items-center">
              <IonCol size="1">
                <IonIcon onClick={history.goBack} icon={arrowBack} style={{ fontSize: "18px" }} />
              </IonCol>
              <IonCol size="11">
                <IonLabel style={{ fontSize: "16px" }}>{installation.name} {installation.DIcode}</IonLabel>
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonItem>
        <IonGrid style={{ padding: "15px 40px 15px 40px", backgroundColor: "#FFFFFF", color: "#000000" }}>
          <IonRow class="ion-text-uppercase" style={{ height: "65px" }}>
            <IonCol size="2">
              {
                installation.urlLogo ? (
                  <img src={installation.urlLogo} alt="logo" style={{ maxWidth: "50px", maxHeight: "50px", borderRadius: "50%" }} />
                ) : (
                    <IonFab>
                      <IonFabButton style={{ maxWidth: "50px", maxHeight: "50px" }} color="medium">Logo</IonFabButton>
                    </IonFab>
                  )
              }
            </IonCol>
            <IonCol size="10" style={{ paddingLeft: "20px", display: "flex", alignItems: "center" }}>
              <IonLabel>{installation.name}</IonLabel>
            </IonCol>
          </IonRow>
          <IonRow style={{ padding: "0px 0px 10px 5px" }}>
            <IonLabel>CODE DI : {installation.DIcode}</IonLabel>
          </IonRow>
          <IonRow>
            <IonCol size="1">
              <IonIcon icon={pin} size="small" />
            </IonCol>
            <IonCol>
              <IonLabel style={{ fontSize: "15px" }}>{installation.address}</IonLabel>
            </IonCol>
          </IonRow>
          <IonRow>
            {installation.P1.toString() === "true" && (
              <>
                <IonCol size="1">
                  <IonIcon icon={build} size="small" />
                </IonCol>
                <IonCol size="2">
                  <IonLabel style={{ fontSize: "15px" }}>P1</IonLabel>
                </IonCol>
              </>
            )
            }
            {
              installation.P2.toString() === "true" && (
                <>
                  {installation.P1.toString() === "false" && (
                    <IonCol size="1">
                      <IonIcon icon={build} size="small" />
                    </IonCol>
                  )
                  }
                  <IonCol size="2">
                    <IonLabel style={{ fontSize: "15px" }}>P2</IonLabel>
                  </IonCol>
                </>
              )
            }
            {
              installation.P3.toString() === "true" && (
                <>
                  {installation.P1.toString() === "false" && installation.P2.toString() === "false" && (
                    <IonCol size="1">
                      <IonIcon icon={build} size="small" />
                    </IonCol>
                  )
                  }
                  <IonCol size="2">
                    <IonLabel style={{ fontSize: "15px" }}>P3</IonLabel>
                  </IonCol>
                </>
              )
            }
          </IonRow>
        </IonGrid>
        {
          detailIsOpen && (
            <>
              <IonGrid style={{ padding: "0px 40px 20px 40px", backgroundColor: "#FFFFFF", color: "#000000", marginTop: "-15px" }}>
                <IonRow>
                  <IonCol size="1">
                    <IonIcon icon={person} size="small" />
                  </IonCol>
                  <IonCol>
                    <IonLabel style={{ fontSize: "15px" }}>Nom du client: {installation.customerName}</IonLabel>
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol size="1">
                    <IonIcon icon={document} size="small" />
                  </IonCol>
                  <IonCol>
                    <IonLabel style={{ fontSize: "15px" }}>N° Contrat: {installation.contractNumber}</IonLabel>
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol size="1">
                    <IonIcon icon={attach} size="small" />
                  </IonCol>
                  <IonCol>
                    <IonLabel style={{ fontSize: "15px" }}>N° Avenant: {installation.endorsementNumber}</IonLabel>
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol size="1">
                    <IonIcon icon={calendar} size="small" />
                  </IonCol>
                  <IonCol>
                    <IonLabel style={{ fontSize: "15px" }} >Date de prise d'effet: {displayDate(installation.effectiveDate)}</IonLabel>
                  </IonCol>
                </IonRow>
              </IonGrid>
            </>
          )
        }

        {detailIsOpen ? (
          <IonRow class="ion-justify-content-center" style={{ marginTop: "-22px" }}>
            <IonIcon color="primary" onClick={() => setDetailIsOpen(!detailIsOpen)} style={{ fontSize: '2.3em' }} icon={arrowDropupCircle} />
          </IonRow>

        ) : (
            <IonRow class="ion-justify-content-center" style={{ marginBottom: "20px", marginTop: "-22px" }}>
              <IonIcon color="primary" onClick={() => setDetailIsOpen(!detailIsOpen)} style={{ fontSize: '2.3em' }} icon={arrowDropdownCircle} />
            </IonRow>
          )}

        <IonList class="ion-no-padding">
          <IonItem onClick={() => setLoading(true)} routerLink={`/installation/${installationId}/equipments`} class="ion-no-padding">
            <IonGrid class="ion-no-padding">
              <IonRow class="ion-align-items-center" style={{ height: "70px" }}>
                <IonCol size="1">
                  {
                    loading ?
                      (
                        <IonSpinner name="crescent" style={{height:"20px", width:"20px"}}/>
                      ) : (
                        <IonIcon style={{ fontSize: '2em' }} icon={settings} size="small" />
                      )
                  }
                </IonCol>
                <IonCol size="10">
                  <IonLabel style={{ fontSize: '0.8em' }}>INVENTAIRE EQUIPEMENT + ANOMALIES
                    <IonCardSubtitle>Quantité: {(Object.keys(installation.equipments)).length}</IonCardSubtitle>
                  </IonLabel>
                </IonCol>
                <IonCol size="1" class="ion-no-padding">
                  <IonIcon style={{ fontSize: '1.5em' }} icon={arrowForward} class="ion-no-padding" />
                </IonCol>
              </IonRow>
            </IonGrid>
          </IonItem>

          <IonItem routerLink={`/installation/${installationId}/documents`} class="ion-no-padding">
            <IonGrid class="ion-no-padding">
              <IonRow class="ion-align-items-center" style={{ height: "70px" }}>
                <IonCol size="1">
                  <IonIcon style={{ fontSize: '2em' }} icon={document} size="small" />
                </IonCol>
                <IonCol size="10">
                  <IonLabel style={{ fontSize: '0.8em' }}>DOCUMENTS REMIS
              <IonCardSubtitle>Quantité: {deliveredDocs}</IonCardSubtitle>
                  </IonLabel>
                </IonCol>
                <IonCol size="1" class="ion-no-padding">
                  <IonIcon style={{ fontSize: '1.5em' }} icon={arrowForward} class="ion-no-padding" />
                </IonCol>
              </IonRow>
            </IonGrid>
          </IonItem>

          <IonItem routerLink={`/installation/${installationId}/access`} class="ion-no-padding">
            <IonGrid class="ion-no-padding">
              <IonRow class="ion-align-items-center" style={{ height: "70px" }}>
                <IonCol size="1">
                  <IonIcon style={{ fontSize: '2em' }} icon={key} size="small" />
                </IonCol>
                <IonCol size="10">
                  <IonLabel style={{ fontSize: '0.8em' }}>MOYENS D'ACCES
              <IonCardSubtitle>Quantité: {(Object.keys(installation.accessList)).length}</IonCardSubtitle>
                  </IonLabel>
                </IonCol>
                <IonCol size="1" class="ion-no-padding">
                  <IonIcon style={{ fontSize: '1.5em' }} icon={arrowForward} class="ion-no-padding" />
                </IonCol>
              </IonRow>
            </IonGrid>
          </IonItem>

          <IonItem routerLink={`/installation/${installationId}/meter`} lines="none" class="ion-no-padding">
            <IonGrid class="ion-no-padding">
              <IonRow class="ion-align-items-center" style={{ height: "70px" }}>
                <IonCol size="1">
                  <IonIcon style={{ fontSize: '2em' }} icon={eye} size="small" />
                </IonCol>
                <IonCol size="10">
                  <IonLabel style={{ fontSize: '0.8em' }}>RELEVES COMPTEURS
              <IonCardSubtitle>Quantité: {(Object.keys(installation.meterList)).length}</IonCardSubtitle>
                  </IonLabel>
                </IonCol>
                <IonCol size="1" class="ion-no-padding">
                  <IonIcon style={{ fontSize: '1.5em' }} icon={arrowForward} class="ion-no-padding" />
                </IonCol>
              </IonRow>
            </IonGrid>
          </IonItem>
        </IonList>
      </IonContent>

      <IonFooter>
        <IonButton class="ion-no-margin" color="success" expand="full" onClick={mergeInstallationToReference} disabled={currentInstallationState !== 'dirty'}>
          {
            currentInstallationState === 'saving' ?
              <IonSpinner name="crescent" style={{height:"20px", width:"20px"}}/> :
              <IonIcon icon={checkmarkCircle} />
          }
          &nbsp; ENREGISTRER & SYNCHRONISER
        </IonButton>
      </IonFooter>
    </IonPage>

  );
};

const mapStateToProps = state => ({
  installation: state.currentInstallation,
  currentInstallationState: state.currentInstallationState,
  installationSaveState: state.installationSaveState,
  installationSaveError: state.installationSaveError,
});

const mapUpdateToProps = ({ match: { params: { installationId } } }) => ({
  mergeInstallationToReference: () => state => {
    state.currentInstallation.technicianNameLastUpdate = getUserInfo().name;
    state.currentInstallation.lastUpdate = new Date();

    state.installationList[installationId] = state.currentInstallation;
    state.currentInstallationState = 'saving';

    return new Promise<void>(resolve => {
      const unsubscribe = store.subscribe(withPatchFilter({
          path: ['currentInstallationState'],
          value: 'saved',
        },
        () => {
          unsubscribe();
          resolve();
        }
      ));
    });
  },
  restoreInstallationSaveState: () => state => {
    state.installationSaveState = 'ok';
    state.installationSaveError = null;
  },
});

export default withStore(store, mapStateToProps, mapUpdateToProps)(Installation);
