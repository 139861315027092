import { OuiNonBOOL, NULLABLENUMBER, DATE } from "../mappers";

export const installationListMapping = [["DIcode", "CODE DI"]];

export const compilationMapping = [
  ["DIcode", "CODE DI"],
  ["place", "SITE"],
  ["building", "BATIMENT"],
  ["stage", "NIVEAU"],
  ["room", "LOCAL"],
  ["designation", "DESIGNATION"],
  ["family", "FAMILLE"],
  ["typeOfTechnology", "TYPE TECHNOLOGIQUE"],
  ["fieldName", "NOM CHAMP"],
  ["value", "VALEUR"],
];

export const importInventoryMapping = [
  ["isNew", () => false],
  ["inventory.idReference", "ID REFERENCE"],
  ["inventory.DCode", () => ""],
  ["inventory.PCode", () => ""],
  ["inventory.DIcode", "CODE DI"],
  ["inventory.FRTcode", () => ""],
  ["inventory.DIcodeAssociation", () => false],
  ["inventory.place", "SITE"],
  ["inventory.building", "BATIMENT"],
  ["inventory.stage", "NIVEAU"],
  ["inventory.room", "LOCAL"],
  ["inventory.GMAO", "GMAO"],
  ["inventory.absentRepository", () => true],
  ["inventory.p3", "P3", OuiNonBOOL],
  ["inventory.designation", "DESIGNATION"],
  ["inventory.status", "STATUT"],
  ["inventory.health", "ETAT SANTE"],
  ["inventory.initialQuantity", "QTE INITIALE", NULLABLENUMBER],
  ["inventory.notedQuantity", () => null],
  ["inventory.elementarySystem", "SYSTEME ELEMENTAIRE"],
  ["inventory.family", "FAMILLE"],
  ["inventory.typeOfTechnology", "TYPE TECHNOLOGIQUE"],
  ["inventory.FIP", () => ""],
  ["inventory.brand", "MARQUE"],
  ["inventory.model", "MODELE"],
  ["inventory.reference", "REFERENCE"],
  ["inventory.serialNumber", "NUM SERIE"],
  ["inventory.IDnumber", "N° IDENTIFICATION"],
  ["inventory.dateOfCommissioning", "DATE MES", DATE],
  ["inventory.photoName", () => ""],
  // [null, "NOM PHOTO SIGNALETIQUE"],
  ["anomalies.security.criticality", () => ""],
  ["anomalies.security.reason", () => ""],
  ["anomalies.security.observation", () => ""],
  ["anomalies.security.PV", () => false],
  ["anomalies.security.photoName", () => ""],
  ["anomalies.maintenability.criticality", () => ""],
  ["anomalies.maintenability.reason", () => ""],
  ["anomalies.maintenability.observation", () => ""],
  ["anomalies.maintenability.PV", () => false],
  ["anomalies.maintenability.photoName", () => ""],
  ["anomalies.another.criticality", () => ""],
  ["anomalies.another.reason", () => ""],
  ["anomalies.another.observation", () => ""],
  ["anomalies.another.PV", () => false],
  ["anomalies.another.photoName", () => ""],
];
