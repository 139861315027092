import createStore from './pure-store';

const store = createStore({
    installationsReady: false,
    installationList: {},
    currentInstallation: null,
    currentInstallationState: 'saved',
    installationSaveState: 'ok',
    installationSaveError: null,
    memoizedInstallations: null,

    regionsReady: false,
    regions: null,
    selectedRegion: null,
});

export default store;
