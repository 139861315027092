import { useMemo } from 'react';

const transformString = inStr => inStr.toLowerCase().replace(
  /([àáâãäå])|([çčć])|([èéêë])|([ìíîï])|([ñ])|([òóôõöø])|([ß])|([ùúûü])|([ÿ])|([æ])/g, 
  (str, a, c, e, i, n, o, s, u, y, ae) => {
    if (a) return 'a';
    if (c) return 'c';
    if (e) return 'e';
    if (i) return 'i';
    if (n) return 'n';
    if (o) return 'o';
    if (s) return 's';
    if (u) return 'u';
    if (y) return 'y';
    if (ae) return 'ae';
  }
);

export type SearchCaseInsensitive = <T>(list: T[], pattern: string, getter: (T) => string[]) => T[];

export const caseInsensitiveSearch: SearchCaseInsensitive = (list, pattern, getter) => {
  const processedPattern = transformString(pattern);

  return list.filter((item) => {
    const strings = getter(item);

    for (const string of strings) {
      if (transformString(string).indexOf(processedPattern) > -1) {
        return true;
      }
    }
    
    return false;
  });
};

export type UseSearchCaseInsensitive = <T, O>(originalIterable: O, iterableTransformer: (originalIterable: O) => T[], pattern: string, getter: (item: T) => string[]) => T[];

export const useCaseInsensitiveSearch: UseSearchCaseInsensitive = (originalIterable, iterableTransformer, pattern, getter) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useMemo(() => caseInsensitiveSearch(iterableTransformer(originalIterable), pattern, getter), [originalIterable, pattern]);
};