export default {
  clientId:
    "48617697390-icoet99kt0jpu45f7raiakd2fhtlh1ql.apps.googleusercontent.com",
  // clientSecret: 'bn3caNBPdtB7Egx_ZqCDG5gK',
  apiKey: "AIzaSyBanQ8WQ12qE9RpEnW3YcHK-YbMsV9v3pE",
  discoveryDocs: [
    "https://sheets.googleapis.com/$discovery/rest?version=v4",
    "https://www.googleapis.com/discovery/v1/apis/drive/v3/rest",
  ],
  scopes: [
    "https://www.googleapis.com/auth/spreadsheets",
    "https://www.googleapis.com/auth/drive",
  ],
  // rootFolderId: '1KbWEabMJw3E4M8Y83dDj8YclyuP5MSIE',
  rootFolderId: "1QPGZ1asDbpZSnskryU-BcZjdQy_tvW5U",
};
