import { produceWithPatches, applyPatches } from "immer";

export class PureStore<S, T> {
  callbacks = [];
  rootState: T;
  getter: (s: S)=>T;
  root: any;
  parent: any;

  constructor(parent, getter, rootState) {
    this.parent = parent;
    this.root = (parent && parent.root) || this;
    this.rootState = !parent ? rootState : null;
    this.getter = s => getter(parent ? parent.getter(s) : s);
    this.callbacks = [];
  }

  getState = () => this.getter(this.root.rootState);

  update = updater => {
    const updaterFn = (updater instanceof Function) ?
      updater : e => Object.assign(e, updater);

    // @ts-ignore
    const [nextState, patches, inversePatches] = produceWithPatches(this.root.rootState, draft => {
      updaterFn(this.getter(draft));
    });

    if (patches.length > 0) {
      this.root.rootState = nextState;

      [...this.root.callbacks].forEach(callback => callback(patches, inversePatches));
    }
  };

  apply = patches => {
    this.root.update(draft => {
      applyPatches(draft, patches);
    });
  };

  subscribe = callback => {
    let callbackFn;

    if (this.root === this) {
      callbackFn = callback;
    }
    else {
      let state = this.getState();
      callbackFn = (...args) => {
        const newState = this.getState();
        if (newState !== state) {
          callback(...args);
          state = newState;
        }
      }
    }

    this.root.callbacks.push(callbackFn);
    return () => this.root.callbacks.splice(this.root.callbacks.indexOf(callbackFn), 1);
  };

  storeFor = getter => new PureStore(this, getter, null);
}

const createSingleMatcher = filter => patch => {
  if (filter.op !== undefined && patch.op !== filter.op) {
    return false;
  }

  if (filter.value !== undefined && patch.value !== filter.value) {
    return false;
  }

  if (filter.path === undefined) {
    return true;
  }

  if (filter.exact && patch.path.length !== filter.path.length) {
    return false;
  }

  if (patch.path.length < filter.path.length) {
    return false;
  }

  const params = {};
  for (let i = 0; i < filter.path.length; ++i) {
    if (filter.path[i].startsWith(':')) {
      params[filter.path[i].substring(1)] = patch.path[i];
    }
    else {
      if (patch.path[i] !== filter.path[i]) {
        return false;
      }
    }
  }

  return params;
};

export const createMatcher = filters => patch => {
  if (!(filters instanceof Array)) {
    filters = [filters];
  }

  const matchers = filters.map(createSingleMatcher);

  for (let k = 0; k < matchers.length; ++k) {
    const match = matchers[k](patch);

    if (match) {
      return match;
    }
  }

  return false;
};

export const withPatchFilter = (filters, callback) => {
  const matcher = createMatcher(filters);

  return (patches, inversePatches) => {
    const filteredPatches: Array<any> = [];
    const filteredInversePatches: Array<any> = [];

    for (let i = 0; i < patches.length; ++i) {
      const match = matcher(patches[i]);

      if (typeof match === 'object') {
        patches[i].params = match;
        inversePatches[i].params = match;
      }

      if (match) {
        filteredPatches.push(patches[i]);
        filteredInversePatches.push(inversePatches[i]);
      }
    }

    if (filteredPatches.length > 0) {
      callback(filteredPatches, filteredInversePatches);
    }
  }
};

export default initialState => new PureStore(null, s => s, initialState);