import React, {useEffect, useState, useMemo} from "react";

const defaultGetter = s => s;

export const useStore = (store, getter=defaultGetter) => {
    const [state, setState] = useState(getter(store.getState()));

    useEffect(() => store.subscribe(() => {
        const newState = getter(store.getState());

        if (newState !== state) {
            setState(newState);
        }
    }), [getter, state, store]);

    return state;
};


const areEqualShallow = (a, b) => {
    for(let key in a) {
        if(!(key in b) || a[key] !== b[key]) {
            return false;
        }
    }
    for(let key in b) {
        if(!(key in a) || a[key] !== b[key]) {
            return false;
        }
    }
    return true;
};

const defaultMapStateToProps = state => ({});
const defaultMapUpdateToProps = state => ({});


export const withStore = (store: any, mapStateToProps: any=null, mapUpdateToProps: any=null) => {

    const storeGetter = (store instanceof Function) ?
      store : props => store;

    if (!mapStateToProps) {
        mapStateToProps = defaultMapStateToProps;
    }

    if (!mapUpdateToProps) {
        mapUpdateToProps = defaultMapUpdateToProps;
    }

    return Component => React.memo(props => {
        const store = storeGetter(props);
        const initialState = useMemo(() => mapStateToProps(store.getState(), props), [store, props]);
        const [state, setState] = useState(initialState);

        useEffect(() => store.subscribe(() => {
            const newState = mapStateToProps(store.getState(), props);
            if (!areEqualShallow(newState, state)) {
                setState(newState);
            }
        }), [store, props, state]);

        const updateProps = useMemo(() => {
            const updateProps = {};

            Object.entries(mapUpdateToProps(props)).forEach(([prop, setter]: [any, any]) => {
                updateProps[prop] = (...args) => {
                    let returnValue = undefined;
                    store.update(draft => {
                        returnValue = setter(...args)(draft);
                    });
                    return returnValue;
                };
            });

            return updateProps;
        }, [store, props]);

        return <Component {...props} {...state} {...updateProps} />;
    });
};