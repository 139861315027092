import iRegionList from "../models/iRegionList.module";

const test: iRegionList = {
  "lkjsd": {
    name: 'SUD OUEST',
    folderId: 'lkjsd',
    manager: 'Manager name',
    email: 'admin@admin.fr',
  }
};

module.exports = test;