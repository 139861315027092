import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { IonPage, IonContent, IonList, IonItem, IonLabel, IonCheckbox, IonGrid, IonRow, IonCol, IonIcon, IonPopover, IonInput, IonListHeader } from '@ionic/react';
import { arrowBack, addCircle, more, checkmarkCircle, close, trash } from 'ionicons/icons';
import { withStore } from "../../store/react-pure-store";
import store from "../../store/store";
import iDocumentList from "../../models/documentList.module";
import { useKeyboardOpenDetectorClass } from '../../helpers/hooks';

const defaultDocument = {
  delivered: false,
  upToDate: false,
  noObject: false,
};

const staticDocuments = [
  "Plan de l'installation",
  "Schéma de l'installation",
  "Plans et schémas électriques",
  "Documents techniques",
  "Notice de conduite",
  "Notices constructeurs",
  "Consignes spécifiques",
  "Livret de maintenance",
  "Certificat de contrôle",
  "DTA",
];

const Documents: any = ({ documentList, setDocumentField, addDocument, removeDocument, installationName }: { documentList: iDocumentList, setDocumentField: (a: string, b: string, c: boolean) => void, addDocument, removeDocument, installationName }) => {

  const history = useHistory();
  const [moreDocuments, setMoreDocuments] = useState(false);
  const [newDocumentValue, setNewDocumentValue] = useState("");
  const [showPopover, setShowPopover] = useState("");
  const popoverClass = useKeyboardOpenDetectorClass();
  

  return (
    <IonPage>
      <IonContent color="secondary">
        <IonItem onClick={() => history.goBack()} color='primary' class="ion-no-padding" lines="none">
          <IonRow style={{ height: "60px" }} class="ion-align-items-center">
            <IonCol size="1">
              <IonIcon icon={arrowBack} style={{ fontSize: "18px" }} />
            </IonCol>
            <IonCol size="11">
              <IonLabel style={{ fontSize: "16px" }}>DOCUMENTS - {installationName}</IonLabel>
            </IonCol>
          </IonRow>
        </IonItem>

        <IonItem color="secondary" class="ion-no-padding" lines="none">
          <IonLabel style={{ marginLeft: "15px" }} color="medium">DOCUMENTS DE BASE</IonLabel>
        </IonItem>

        <IonGrid style={{ backgroundColor: "#FFFFFF", color: "#000000", paddingBottom: "0px" }}>
          <IonRow>
            <IonCol size='7' />
            <IonCol class='ion-no-padding'>
              <IonLabel color="medium" style={{ fontSize: "9px" }} class="ion-float-right">Remis</IonLabel>
            </IonCol>
            <IonCol style={{ maxWidth: "25px", marginLeft: "12px" }} class='ion-no-padding'>
              <IonLabel color="medium" style={{ fontSize: "9px" }} class="ion-float-right">A jour</IonLabel>
            </IonCol>
            <IonCol style={{ maxWidth: "25px", margin: "0px 8px 0px 15px" }} class='ion-no-padding'>
              <IonLabel color="medium" style={{ fontSize: "9px" }} class="ion-float-right">Sans objet</IonLabel>
            </IonCol>
          </IonRow>
        </IonGrid>

        <IonList>
          {
            staticDocuments.map(staticDocument => {
              const document = documentList[staticDocument] !== undefined ? documentList[staticDocument] : defaultDocument;

              return (
                <IonItem lines="none" style={{ height: "36px" }} key={staticDocument}>
                  <IonLabel color="medium">{staticDocument}</IonLabel>
                  {
                    [{
                      field: 'delivered',
                      disable: 'noObject',
                    }, {
                      field: 'upToDate',
                      disable: 'noObject',
                    }, {
                      field: 'noObject',
                    }].map(({ field, disable }) => (
                      <IonCheckbox
                        key={field}
                        color="success"
                        style={{ marginLeft: "20px" }}
                        slot="end"
                        checked={document[field]}
                        disabled={disable && document[disable]}
                        onIonChange={e => setDocumentField(staticDocument, field, e.detail.checked)}
                      />
                    ))
                  }
                </IonItem>
              );
            })
          }
        </IonList>

        <IonItem color="secondary" class="ion-no-padding" lines="none">
          <IonLabel color="medium" style={{ marginLeft: "15px" }}>AUTRES DOCUMENTS</IonLabel>
          <IonIcon color="medium" icon={addCircle} onClick={() => setMoreDocuments(!moreDocuments)} />
          <IonPopover cssClass={popoverClass} isOpen={moreDocuments} onDidDismiss={e => { setMoreDocuments(false); setNewDocumentValue("") }}>
            <IonItem color="light">
              <IonLabel color="medium">Ajouter un document</IonLabel>
              <IonIcon onClick={() => setMoreDocuments(false)} icon={close} />
            </IonItem>
            <IonItem>
              <IonLabel color="medium">Désignation: </IonLabel>
              <IonInput onIonChange={(e) => setNewDocumentValue(e.detail.value || '')} required value={newDocumentValue}></IonInput>
            </IonItem>
            <IonItem color="light" class="ion-justify-content-end">
              <IonGrid>
                <IonRow style={{ display: 'flex' }} class="ion-justify-content-end">
                  <IonIcon onClick={() => { setMoreDocuments(!moreDocuments); addDocument(newDocumentValue) }} 
                  size="large" color="success" icon={checkmarkCircle} />
                </IonRow>
              </IonGrid>
            </IonItem>
          </IonPopover>
        </IonItem>
        <IonList class="ion-no-padding">
          {
            Object.keys(documentList).filter(id => !staticDocuments.includes(id)).map(id => (
              <IonItem key={id} lines="none">
                <IonLabel color="medium">{id}</IonLabel>
                <IonIcon icon={more} onClick={() => setShowPopover(id)} />
                <IonPopover isOpen={showPopover === id} onDidDismiss={e => setShowPopover("")}>
                  <IonList>
                    <IonListHeader color="light">
                      <IonIcon onClick={() => setShowPopover("")} icon={close} style={{marginLeft: "200px"}} />
                    </IonListHeader>
                    <IonItem onClick={() => removeDocument(id)}><IonIcon icon={trash} color="danger" /> &nbsp; Supprimer</IonItem>
                  </IonList>
                </IonPopover>
              </IonItem>
            ))
          }
        </IonList>

      </IonContent>
    </IonPage>
  );
};

export default withStore(() => store.storeFor(state => state.currentInstallation),
  ({ name, documentList }) => ({
    installationName: name,
    documentList,
  }),
  () => ({
    setDocumentField: (id, fieldId, value) => ({ documentList }) => {
      if (documentList[id] === undefined) {
        documentList[id] = { ...defaultDocument };
      }

      documentList[id][fieldId] = value;
    },
    addDocument: id => ({ documentList }) => {
      if (documentList[id] !== undefined) {
        return false;
      }

      documentList[id] = {
        ...defaultDocument,
        delivered: true,
      };

      return true;
    },
    removeDocument: id => ({ documentList }) => delete documentList[id],
  }))(Documents);