import React, { useState, useMemo, useRef } from 'react';
import { useHistory } from 'react-router';
import {
  IonPage, IonContent, IonList, IonItem, IonLabel, IonIcon, IonInput, IonGrid, IonRow, IonCol, IonText,
  IonDatetime, IonFabButton, IonSelect, IonSelectOption, IonCard, IonCheckbox
} from '@ionic/react';
import { addCircle, camera, removeCircle, arrowBack } from 'ionicons/icons';
import { Camera, CameraResultType } from '@capacitor/camera';
import { withStore } from '../../store/react-pure-store';
import store from '../../store/store';
import iInventory from '../../models/inventory.module';
import iAnomalies from '../../models/iAnomalies.module';
import { useLeaveConfirm, useSaveHandler } from '../../helpers/hooks';
import { useCaseInsensitiveSearch } from '../../helpers/search';
import iEquipmentList from '../../models/equipmentList.module';
import iGMAOList from '../../models/GMAOList.module';
import ImageViewer, { getViewerPhotoProps } from '../component/ImageViewer';
import { iInventorySubByAttribute, iInventorySubByEquipment } from '../../models/iInventorySub.module';
import './inventory.css';
// import {getImageLink} from "../../controllers/gapiController";

// const ImageFetcher = ({ link, children }) => {
//   const [blobUrl, setBlobUrl] = useState(null);
//
//   useEffect(() => {
//     if (link) {
//       if (link.startsWith('blob:')) {
//         setBlobUrl(link);
//       }
//       else {
//         getImageLink(link)
//           // @ts-ignore
//           .then(setBlobUrl)
//           .catch(console.error);
//       }
//     }
//   }, [link]);
//
//   return children(blobUrl);
// };

const statuses = {
  'En Service': 'En Service',
  'Hors Service': 'Hors Service',
  'A l\'arrêt': 'A l\'arrêt',
  'Fonctionnement dégradé': 'Fonctionnement dégradé',
  'Non testé à vérifier': 'Non testé à vérifier',
};

const getTechInfo = (GMAO, gmaoId) => {
  if (gmaoId === null) {
    return null;
  }

  return `${GMAO[gmaoId].elementarySystem} - ${GMAO[gmaoId].family}`;
};

const techInfoMapping = {
  'CE / CVC CHAUFFAGE - Chaudière': 'hot',
  'CC / CVC EAU GLACEE - Groupe Froid': 'cold',
  'CC / CVC EAU GLACEE - Pompe à Chaleur': 'cold',
  'CG / CVC TRAITEMENT D\'AIR - Système VRV': 'cold',
  'CG / CVC TRAITEMENT D\'AIR - Split system': 'cold',
};

const getAdditionalFieldsCategoryForTech = (techInfo) => {
  return techInfoMapping[techInfo] || null;
};

const getDesignationFromGMAO = (GMAO, gmaoId) => {
  const [, ...designationSplit] = GMAO[gmaoId].typeOfTechnology.split('/');
  return designationSplit.join('/').trim();
};

const fluidCO2Values = {
  'R410A': 2.09,
  'R407C': 1.77,
  'R134A': 1.43,
  'R404A': 3.92,
  'R32': 0.68,
};

const installationSubInventoryKeys = {
  hot: 'inventoryHot',
  cold: 'inventoryCold',
};

const attributesInfo = {
  53: {
    section: 'Caractéristiques',
    fieldName: 'Puissance unitaire (kw)',
    valueGetter: attrs => attrs[53],
  },
  48: {
    section: 'Caractéristiques',
    fieldName: 'Type de fluide',
    valueGetter: attrs => attrs[48],
  },
  49: {
    section: 'Caractéristiques',
    fieldName: 'Charge totale (kg)',
    valueGetter: attrs => attrs[49],
  },
  230: {
    section: 'Contrôles réglementaires',
    fieldName: 'Tonne equivalent Co2',
    valueGetter: attrs => isNaN(Number(attrs[49])) ? '' : (Number(attrs[49]) * fluidCO2Values[attrs[48]]).toString(),
  },
};

const Inventory: any = ({ isNew, inventory, inventoryCold, inventoryHot, anomalies, locationList, modelList, brandList, GMAO, match: { params: { installationId, equipmentId } }, saveInventory, saveInventorySubs, removeEquipment, setAnomalies, saveWorkToDo, currentInstallation }:
  { isNew, inventory: iInventory, inventoryCold: iInventorySubByEquipment, inventoryHot:iInventorySubByEquipment, anomalies: iAnomalies, locationList, modelList, brandList, GMAO: iGMAOList, match: any, saveInventory, saveInventorySubs, removeEquipment, setAnomalies, saveWorkToDo, currentInstallation }) => {

  const [techSearch, setTechSearch] = useState(inventory.typeOfTechnology !== '' ? inventory.typeOfTechnology : inventory.designation);
  const [designation, setDesignation] = useState<string>(inventory.designation);
  const [gmaoId, setGmaoId] = useState<string | null>(inventory.typeOfTechnology !== '' ? inventory.typeOfTechnology : null);
  const [roomValue, setRoomValue] = useState(inventory.room);
  const [statusValue, setStatusValue] = useState(inventory.status);
  const [brandValue, setBrandValue] = useState(inventory.brand);
  const [modelValue, setModelValue] = useState(inventory.model);
  const [commissioningDateValue, setCommissioningDateValue] = useState(inventory.dateOfCommissioning);
  const history = useHistory();
  const anomalySecuBgColor = anomalies.security === undefined ? "#a6a6a6" : "#FF773E";
  const anomalyMaintBgColor = anomalies.maintenability === undefined ? "#a6a6a6" : "#FF773E";
  const anomalyAnotherBgColor = anomalies.another === undefined ? "#a6a6a6" : "#FF773E";
  const [notedQuantityValue, setNotedQuantityValue] = useState(
    inventory.notedQuantity !== null ?
    inventory.notedQuantity :
    inventory.initialQuantity !== null ?
    inventory.initialQuantity :
    1
  );
  const [listDisplayed, setListDisplayed] = useState<'designation' | 'location' | 'brand' | 'model' | null>(null);
  const [imageUrl, setImageUrl] = useState<string>();
  const [imageOpen, setImageOpen] = useState(false);

  const [powerValue, setPowerValue] = useState(inventoryHot?.[53]?.value || '');
  const [fluidTypeValue, setFluidTypeValue] = useState(inventoryCold?.[48]?.value || '');
  const [totalChargeValue, setTotalChargeValue] = useState(inventoryCold?.[49]?.value || '');
  const techSearchInputRef = useRef<HTMLIonInputElement | null>(null);

  // Not in dependencies on purpose
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const memoizedAnomalies = useMemo(() => anomalies, []);

  const workToDo = anomalies.another !== undefined && 
    anomalies.another.PV &&
    anomalies.another.reason === 'Travaux à prévoir';

  const techInfo = getTechInfo(GMAO, gmaoId);
  const additionalFieldsCategory = getAdditionalFieldsCategoryForTech(techInfo);

  const goToAnomaly = (type: string): void => {
    history.push('/installation/' + installationId + '/equipment/' + equipmentId + '/anomaly/' + type);
  }

  const takePicture = async () => {
    try {
      const image = await Camera.getPhoto({
        quality: 90,
        allowEditing: false,
        resultType: CameraResultType.Uri
      });

      // image.webPath will contain a path that can be set as an image src. 
      // You can access the original file using image.path, which can be 
      // passed to the Filesystem API to read the raw data of the image, 
      // if desired (or pass resultType: CameraResultType.Base64 to getPhoto)
      if (image.webPath !== undefined) {
        setImageUrl(image.webPath);
      }
      
      // imageElement.src = imageUrl;
      // camera logo url: https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTDPYM9KWj50OrzBMgTZNI9CGarOoBrULEMIF_LSBa6eF_BgcfM4A&s
    }
    catch (err) {
      console.error(err);
    }
  };

  const filteredTechList = useCaseInsensitiveSearch(
    GMAO,
    GMAO => techSearch.length >= 3 ? Object.entries(GMAO) : [],
    techSearch,
    ([, { typeOfTechnology }]: any) => [typeOfTechnology]
  );

  const filteredLocationList = useMemo(() => locationList.filter(item => {
    return item.toLowerCase().indexOf(roomValue.toLowerCase()) > -1;
  }), [locationList, roomValue]);

  const filteredBrandList = useMemo(() => brandList.filter(item => {
    return item.toLowerCase().indexOf(brandValue.toLowerCase()) > -1;
  }), [brandList, brandValue]);

  const filteredModelList = useMemo(() => modelList.filter(item => {
    return item.toLowerCase().indexOf(modelValue.toLowerCase()) > -1;
  }), [modelList, modelValue]);

  const isShortDesignationProposed = techSearch !== '' && !(filteredTechList.length === 1 && techSearch.toUpperCase() === filteredTechList[0][1].typeOfTechnology.toUpperCase());
  const isDesignationListDisplayed = listDisplayed === 'designation' && (isShortDesignationProposed || filteredTechList.length > 0);

  const isLocationListDisplayed = listDisplayed === 'location' && filteredLocationList.length > 0;

  const isBrandListDisplayed = listDisplayed === 'brand' && filteredBrandList.length > 0;

  const isModelListDisplayed = listDisplayed === 'model' && filteredModelList.length > 0;

  useLeaveConfirm(
    `/installation/${installationId}/equipment/${equipmentId}`,
    `/installation/${installationId}/equipments`,
    () => (designation === '' && gmaoId === null) || roomValue === '',
    'Vous êtes sur le point de quitter la vue équipement sans la sauvegarder. Confirmer ?'
  );

  useSaveHandler(() => {
    if ((designation === '' && gmaoId === null) || roomValue === '') {
      if (isNew) {
        removeEquipment(equipmentId);
      }
      else {
        setAnomalies(memoizedAnomalies);
      }
    }
    else {
      let gmao = {
        elementarySystem: '',
        family: '',
        typeOfTechnology: '',
        FIP: '',
      };

      if (gmaoId !== null) {
        gmao = GMAO[gmaoId];
      }

      const inventoryUpdates = {
        // idReference: inventory.idReference,
        // DCode: inventory.DCode,
        // PCode: inventory.PCode,
        // DIcode: inventory.DIcode,
        // FRTcode: inventory.FRTcode,
        DIcodeAssociation: !!gmao.typeOfTechnology,
        // place: inventory.place,
        // building: inventory.building,
        // stage: inventory.stage,
        room: roomValue,
        GMAO: "X",
        absentRepository: !inventory.idReference,
        // p3: inventory.p3,
        // Contient uniquement la lettre X, si dans la vue INSTALLATION P3 est sélectionné
        // Sinon, vide
        designation,
        status: statusValue,
        notedQuantity: notedQuantityValue,
        elementarySystem: gmao.elementarySystem,
        family: gmao.family,
        typeOfTechnology: gmao.typeOfTechnology,
        // ◦ Saisie manuelle avec filtre automatique sur la BDD 7 sur le Type technologique
        // ◦ Affiche dans une liste déroulante les choix dispos en fonction du filtre, en affichant
        //     ▪ Le Système élémentaire (en supprimant le code initial)
        //     ▪ Le Type technologique (en supprimant le code initial)
        FIP: gmao.FIP,
        brand: brandValue,
        // ◦ Saisie manuelle avec filtre
        // ◦ Liste déroulante proposant les dernières saisies sur cette installation
        model: modelValue,
        // ◦ Saisie manuelle avec filtre
        // ◦ Liste déroulante proposant les dernières saisies sur cette installation (en fonction de la marque)
        // reference: inventory.reference,
        // serialNumber: inventory.serialNumber,
        // IDnumber: inventory.IDnumber,
        dateOfCommissioning: commissioningDateValue,
        // ◦ Obligatoire si P3 cochée dans la vue INSTALLATION
        // ◦ Sinon facultatif
        // ◦ Proposer uniquement l’année en sélection, dans la BDD inscrire 01/01/aaaa au format DATE
        photoFileId: imageUrl ? null : inventory.photoFileId,
        thumbnailLink: imageUrl ? null : inventory.thumbnailLink,
        photoLocalBlob: imageUrl || inventory.photoLocalBlob,
        photoLocalBlobDirty: imageUrl ? true : inventory.photoLocalBlobDirty,
        // ◦ Ajout d’une ImageView de la photo
        // ◦ En cliquant dessus, on ouvre l’APN et on prend la photo
        // ◦ Le nom du fichier est stockée dans cette valeur
      };

      saveInventory(inventoryUpdates);

      let attributes: { [key: number]: string | null } = {};

      if (additionalFieldsCategory === 'hot') {
        attributes = {
          53: powerValue,
        };
      }
      else if (additionalFieldsCategory === 'cold') {
        attributes = {
          48: fluidTypeValue,
          49: totalChargeValue,
          230: null,
        };
      }

      saveInventorySubs(additionalFieldsCategory, attributes);
    }
  });

  return (
    <IonPage onClick={() => setListDisplayed(null)}>
      <IonItem onClick={history.goBack} color='primary' class="ion-no-padding" lines="none">
        <IonGrid>
          <IonRow style={{ height: "60px" }} class="ion-align-items-center">
            <IonCol size="2">
              <IonIcon icon={arrowBack} style={{ fontSize: "18px" }} />
            </IonCol>
            <IonCol size="10">
              <IonLabel style={{ fontSize: "16px" }}>EQUIPEMENT - {currentInstallation.name}</IonLabel>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonItem>
      <IonContent color="secondary">
        <IonList class="ion-no-padding">
          <IonRow style={{ display: 'flex', alignItems: 'center' }}>
            <IonCol size='8' style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '-20px', marginTop: '10px' }}>
              <div style={{ width: '120px', height: '80px', backgroundColor: '#e5e5e8', display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: '10px' }}>
                <p style={{ color: '#9ba7ab' }}>
                  {imageUrl || inventory.photoLocalBlob || inventory.thumbnailLink ? (
                    <img referrerPolicy="no-referrer" src={imageUrl || inventory.photoLocalBlob || inventory.thumbnailLink || undefined} alt='Equipment' onClick={() => setImageOpen(true)} />
                  ) : (
                    "Photo"
                  )}
                </p>
              </div>
            </IonCol>
            <IonCol size='4'>
              <IonFabButton style={{ width: '40px', height: '40px', marginLeft: 'auto', marginRight: 'auto' }} color="dark">
                <IonIcon icon={camera} onClick={takePicture} />
              </IonFabButton>
            </IonCol>
          </IonRow>

          <IonItem class="ion-justify-content-center">
            <IonInput ref={techSearchInputRef} class="ion-text-uppercase ion-no-padding" placeholder={gmaoId !== null ? getDesignationFromGMAO(GMAO, gmaoId) : "Equipement"} color={techSearch.length === 0 || gmaoId === null ? "warning" : ""}
             onIonChange={(e) => setTechSearch(e.detail.value || '')}
             onClick={(e) => { e.stopPropagation(); setListDisplayed('designation'); }}
              style={{ textAlign: 'center' }} value={techSearch} />
          </IonItem>
          {
            isDesignationListDisplayed &&
            <IonList class="ion-no-padding ion-justify-content-center overlay-list">
              {
                isShortDesignationProposed && (
                  <IonItem style={{ textAlign: "center" }} class="ion-no-margin"
                    onClick={(e) => { setGmaoId(null); setDesignation(techSearch.toUpperCase()); }}>
                    <IonLabel class="ion-no-margin"><em>Utiliser "</em>{techSearch.toUpperCase()}<em>"</em></IonLabel>
                  </IonItem>
                )
              }
              {
                techSearch.length > 0 && (
                  <IonItem style={{ textAlign: "center" }} class="ion-no-margin"
                    onClick={(e) => { e.stopPropagation(); setTechSearch(''); techSearchInputRef.current?.setFocus(); }}>
                    <IonLabel class="ion-no-margin"><em>Vider la saisie</em></IonLabel>
                  </IonItem>
                )
              }
              {
                filteredTechList.map(([id, item]) => (
                  <IonItem style={{ textAlign: "center" }} class="ion-no-margin" key={id}
                    onClick={(e) => {
                      setGmaoId(id);

                      // if designation is an automatic value, we edit it
                      if (designation === '' || (gmaoId !== null && designation === getDesignationFromGMAO(GMAO, gmaoId))) {
                        setDesignation(getDesignationFromGMAO(GMAO, id));
                      }
                      
                      setTechSearch(item.typeOfTechnology);
                    }}>
                    <IonLabel style={{ fontSize: "0.6em", maxWidth: "40%" }}>{item.elementarySystem.substring(5, item.elementarySystem.length)}</IonLabel>
                    <IonLabel class="ion-no-margin">{item.typeOfTechnology.substring(7, item.typeOfTechnology.length)}</IonLabel>
                  </IonItem>
                ))
              }
            </IonList>
          }

          <IonItem class="ion-justify-content-center">
            <IonInput value={roomValue}
              onIonChange={(e) => setRoomValue(e.detail.value || '')}
              onClick={(e) => { e.stopPropagation(); setListDisplayed('location'); }}
              placeholder="Localisation" color={roomValue.length === 0 ? "warning" : ""} style={{ textAlign: 'center' }} class="ion-no-padding" />
          </IonItem>
          {
            isLocationListDisplayed &&
            <IonList class="ion-no-padding ion-justify-content-center overlay-list">
              {
                filteredLocationList.map(item => (
                  <IonItem style={{ textAlign: "center" }} class="ion-no-margin" key={item}
                    onClick={(e) => { setRoomValue(item); }}>
                    <IonLabel class="ion-no-margin">{item}</IonLabel>
                  </IonItem>
                )) 
              }
            </IonList>
          }

        </IonList>

        <IonList style={{ margin: "10px 0px 10px 0px" }} class="ion-no-padding">
          <IonItem style={{ color: 'gray', fontSize: '14px' }}>
            <IonLabel>QUANTITE : </IonLabel>
            <IonIcon onClick={() => notedQuantityValue > 1 && setNotedQuantityValue(notedQuantityValue - 1)} icon={removeCircle} color="dark" class='ion-padding' style={{ marginRight: '20px' }} />
            <IonLabel style={{ maxWidth: "20px" }} >{notedQuantityValue}</IonLabel>
            <IonIcon onClick={() => setNotedQuantityValue(notedQuantityValue + 1)} icon={addCircle} color="dark" class='ion-padding' style={{ marginLeft: '20px' }} />
          </IonItem>

          <IonItem>
            <IonLabel style={{ color: 'gray', fontSize: '14px' }}>STATUT : </IonLabel>
            <IonSelect onIonChange={(e) => { setStatusValue(e.detail.value || '') }} value={statusValue} cancel-text="Annuler" interface="action-sheet" style={{ minWidth: '250px', textAlign: "right" }}>
              {
                Object.entries(statuses).map(([key, value]) => (
                  <IonSelectOption value={key} key={key}>{value}</IonSelectOption>
                ))
              }
            </IonSelect>
          </IonItem>

          <IonRow style={{ backgroundColor: '#eeffff', height: '10px' }} />

          <IonItem class="ion-justify-content-center">
            <IonLabel style={{ color: 'gray', fontSize: '14px' }}>MARQUE : </IonLabel>
            <IonInput value={brandValue}
              onIonChange={(e) => setBrandValue(e.detail.value || '')}
              onClick={(e) => { e.stopPropagation(); setListDisplayed('brand'); }}
              placeholder="Marque" style={{ textAlign: 'right' }} class="ion-no-padding" />
          </IonItem>
          {
            isBrandListDisplayed &&
            <IonList class="ion-no-padding ion-justify-content-center overlay-list">
              {
                filteredBrandList.map(item => (
                  <IonItem style={{ textAlign: "right" }} class="ion-no-margin" key={item}
                    onClick={(e) => { setBrandValue(item); }}>
                    <IonLabel class="ion-no-margin">{item}</IonLabel>
                  </IonItem>
                ))
              }
            </IonList>
          }

          <IonItem class="ion-justify-content-center">
            <IonLabel style={{ color: 'gray', fontSize: '14px' }}>MODELE : </IonLabel>
            <IonInput value={modelValue}
              onIonChange={(e) => setModelValue(e.detail.value || '')}
              onClick={(e) => { e.stopPropagation(); setListDisplayed('model'); }}
              placeholder="Modèle" style={{ textAlign: 'right' }} class="ion-no-padding" />
          </IonItem>
          {
            isModelListDisplayed &&
            <IonList class="ion-no-padding ion-justify-content-center overlay-list">
              {
                filteredModelList.map(item => (
                  <IonItem style={{ textAlign: "right" }} class="ion-no-margin" key={item}
                    onClick={(e) => { setModelValue(item); }}>
                    <IonLabel class="ion-no-margin">{item}</IonLabel>
                  </IonItem>
                ))
              }
            </IonList>
          }

          {
            gmaoId !== null && (
              <IonItem class="ion-justify-content-center">
                <IonLabel style={{ color: 'gray', fontSize: '14px' }}>NOM PERSONNALISÉ : </IonLabel>
                <IonInput value={designation}
                  onIonChange={(e) => setDesignation(e.detail.value || '')}
                  style={{ textAlign: 'right' }} class="ion-no-padding" />
              </IonItem>
            )
          }

          <IonItem>
            <IonLabel style={{ color: 'gray', fontSize: '14px' }}>DATE MISE EN SERVICE : </IonLabel>
            <IonDatetime onIonChange={(e) => setCommissioningDateValue(e.detail.value ? new Date(e.detail.value) : null)} value={commissioningDateValue?.toISOString()} dayValues="01" monthValues="01" displayFormat="DD/MM/YYYY" min="1900" cancel-text="Annuler" done-text="Valider"></IonDatetime>
          </IonItem>

          {
            additionalFieldsCategory === 'hot' && (
              <IonItem class="ion-justify-content-center">
                <IonLabel style={{ color: 'gray', fontSize: '14px' }}>PUISSANCE UNITAIRE (KW) : </IonLabel>
                <IonInput value={powerValue}
                  onIonChange={(e) => setPowerValue(e.detail.value || '')}
                  placeholder="Puissance" style={{ textAlign: 'right' }} class="ion-no-padding" />
              </IonItem>
            )
          }
          {
            additionalFieldsCategory === 'cold' && <>
              <IonItem>
                <IonLabel style={{ color: 'gray', fontSize: '14px' }}>TYPE DE FLUIDE : </IonLabel>
                <IonSelect onIonChange={(e) => { setFluidTypeValue(e.detail.value || '') }} value={fluidTypeValue} cancel-text="Annuler" interface="action-sheet" style={{ minWidth: '150px', textAlign: "right" }}>
                  <IonSelectOption value="R410A">R410A</IonSelectOption>
                  <IonSelectOption value="R407C">R407C</IonSelectOption>
                  <IonSelectOption value="R134A">R134A</IonSelectOption>
                  <IonSelectOption value="R404A">R404A</IonSelectOption>
                  <IonSelectOption value="R32">R32</IonSelectOption>
                </IonSelect>
              </IonItem>
              <IonItem class="ion-justify-content-center">
                <IonLabel style={{ color: 'gray', fontSize: '14px' }}>CHARGE TOTALE : </IonLabel>
                <IonInput value={totalChargeValue}
                  onIonChange={(e) => setTotalChargeValue(e.detail.value || '')}
                  placeholder="kg" style={{ textAlign: 'right' }} class="ion-no-padding" />
              </IonItem>
            </>
          }

          <IonRow style={{ backgroundColor: '#eeffff', height: '10px' }} />

          <IonItem class="ion-justify-content-center">
            <IonLabel style={{ color: 'gray', fontSize: '14px' }}>TRAVAUX A PREVOIR : </IonLabel>
            <IonCheckbox slot="end" checked={workToDo} onIonChange={event => saveWorkToDo(event.detail.checked)} />
          </IonItem>
        </IonList>

        <IonGrid class="ion-no-padding">

          <IonItem lines="none" style={{ height: "38px" }}>
            <IonLabel class="ion-no-margin" style={{ color: 'gray', fontSize: '16px', textAlign: "center", marginTop: "-10px" }}>
              ANOMALIES
            </IonLabel>
          </IonItem>

          <IonRow style={{ display: 'flex', justifyContent: 'space-between' }}>
            <IonCard class="ion-no-margin" onClick={(e: any) => { e.preventDefault(); goToAnomaly('security'); }} style={{ backgroundColor: anomalySecuBgColor, height: '60px', width: "33%", borderRadius: 0 }}>
              <IonRow class="ion-justify-content-center">
                <IonText style={{ fontSize: '0.8em', padding: "8px", color: "#FFFFFF" }}>
                  SECURITE
                </IonText>
              </IonRow>
              <IonRow class="ion-justify-content-center">
                <IonIcon style={{ fontSize: '24px', marginTop: "-5px", color: "#FFFFFF" }} icon={addCircle} />
              </IonRow>
            </IonCard>

            <IonCard class="ion-no-margin" onClick={(e: any) => { e.preventDefault(); goToAnomaly('maintenability'); }} style={{ backgroundColor: anomalyMaintBgColor, height: '60px', width: "33%", borderRadius: 0 }}>
              <IonRow class="ion-justify-content-center">
                <IonText style={{ fontSize: '0.8em', padding: "8px", color: "#FFFFFF" }}>
                  MAINTENABILITE
                </IonText>
              </IonRow>
              <IonRow class="ion-justify-content-center">
                <IonIcon style={{ fontSize: '24px', marginTop: "-5px", color: "#FFFFFF" }} icon={addCircle} />
              </IonRow>
            </IonCard>

            <IonCard class="ion-no-margin" onClick={(e: any) => { e.preventDefault(); goToAnomaly('another'); }} style={{ backgroundColor: anomalyAnotherBgColor, height: '60px', width: "33%", borderRadius: 0 }}>
              <IonRow class="ion-justify-content-center">
                <IonText style={{ fontSize: '0.8em', padding: "8px", color: "#FFFFFF" }}>
                  AUTRE
                </IonText>
              </IonRow>
              <IonRow class="ion-justify-content-center">
                <IonIcon style={{ fontSize: '24px', marginTop: "-5px", color: "#FFFFFF" }} icon={addCircle} />
              </IonRow>
            </IonCard>
          </IonRow>

        </IonGrid>

        <ImageViewer
          title={`EQUIPEMENT - ${currentInstallation.name}`}
          {...getViewerPhotoProps({
            imageUrl,
            photoLocalBlob: inventory.photoLocalBlob,
            photoFileId: inventory.photoFileId,
          })}
          isOpen={imageOpen}
          setOpen={setImageOpen}
        />

      </IonContent>
    </IonPage>
  );
};

const existsAndUnique = (value, index, array) => value && array.indexOf(value) === index;

export default withStore(() => store.storeFor(state => state.currentInstallation),
  (currentInstallation, { match: { params: { equipmentId } } }) => {
    const equipments = currentInstallation.equipments;
    const equipmentList = Object.values(equipments);

    return {
      currentInstallation,
      isNew: equipments[equipmentId].isNew,
      inventory: equipments[equipmentId].inventory,
      inventoryCold: currentInstallation.inventoryCold[equipmentId],
      inventoryHot: currentInstallation.inventoryHot[equipmentId],
      anomalies: equipments[equipmentId].anomalies,
      locationList: equipmentList.map((equipment: any) => equipment.inventory.room).filter(existsAndUnique),
      modelList: equipmentList.map((equipment: any) => equipment.inventory.model).filter(existsAndUnique),
      brandList: equipmentList.map((equipment: any) => equipment.inventory.brand).filter(existsAndUnique),
      GMAO: currentInstallation.GMAO,
    };
  },
  ({ match: { params: { equipmentId } } }) => ({
    saveInventory: inventoryUpdates => ({ equipments }) => {
      Object.assign(equipments[equipmentId].inventory, inventoryUpdates);
    },
    saveInventorySubs: (additionalFieldsCategory: 'hot' |'cold' | null, attributes: { [key: number]: string | null }) => (installation) => {
      const getKeys = () => {
        if (additionalFieldsCategory !== null) {
          const { [additionalFieldsCategory]: selectedKey, ...restOfKeys } = installationSubInventoryKeys;
          return { selectedKey, restOfKeys: Object.values(restOfKeys) };
        }

        return {
          selectedKey: null,
          restOfKeys: Object.values(installationSubInventoryKeys),
        }
      };
      
      const { selectedKey, restOfKeys } = getKeys();

      if (selectedKey !== null) {
        const inventorySubByEquipment = (installation[selectedKey][equipmentId] || {}) as iInventorySubByEquipment;

        for (const attributeId of Object.keys(attributes)) {
          const inventorySubByAttribute = (inventorySubByEquipment[attributeId] || {}) as iInventorySubByAttribute;

          inventorySubByAttribute.section = attributesInfo[attributeId].section;
          inventorySubByAttribute.fieldName = attributesInfo[attributeId].fieldName;
          inventorySubByAttribute.value = attributesInfo[attributeId].valueGetter(attributes);

          inventorySubByEquipment[attributeId] = inventorySubByAttribute;
        }

        installation[selectedKey][equipmentId] = inventorySubByEquipment;
      }

      for (const key of restOfKeys) {
        delete installation[key][equipmentId];
      }
    },
    removeEquipment: id => ({ equipments }) => delete equipments[id],
    setAnomalies: anomalies => ({ equipments: { [equipmentId]: equipment } }: { equipments: iEquipmentList }) => {
      equipment.anomalies = anomalies;
    },
    saveWorkToDo: workToDo => ({ equipments: { [equipmentId]: { anomalies } } }: { equipments: iEquipmentList }) => {
      if (!workToDo) {
        anomalies.another = undefined;
      }
      else {
        const anomalyUpdates = {
          PV: true,
          criticality: 'Défaut mineur',
          reason: "Travaux à prévoir",
        };

        if (anomalyUpdates && anomalies.another) {
          Object.assign(anomalies.another, anomalyUpdates);
        }
        else {
          anomalies.another = {
            observation: '',
            photoName: '',
            photoFileId: null,
            thumbnailLink: null,
            photoLocalBlob: null,
            photoLocalBlobDirty: false,
            ...anomalyUpdates,
          };
        }
      }
    },
  }))(Inventory);