import {
  BOOL,
  STRICTBOOL,
  SBOOL,
  XBOOL,
  NUMBER,
  NULLABLENUMBER,
  DATE,
} from "./mappers";

export const regionMapping = [
  ["name", "REGION"],
  ["folderId", "DOSSIER GOOGLE DRIVE"],
  ["manager", "RESPONSABLE"],
  ["email", "EMAIL"],
];

export const installationMapping = [
  ["createDate", () => ""],
  ["name", "NOM"],
  ["Dcode", "CODE D"],
  ["DIcode", "CODE DI"],
  ["zone", "SECTEUR"],
  ["unit", "CENTRE"],
  ["lastUpdate", "DATE MAJ", DATE],
  ["technicianNameLastUpdate", "NOM TECH MAJ"],
  ["technicianNameCreate", "NOM TECH CREATION"],
  ["address", "ADRESSE"],
  ["customerName", "CLIENT"],
  ["P1", "P1", BOOL],
  ["P2", "P2", BOOL],
  ["P3", "P3", BOOL],
  ["contractNumber", "N° CONTRAT"],
  ["endorsementNumber", "N° AVENANT"],
  ["effectiveDate", "DATE PRISE EFFET", DATE],
  ["urlLogo", "URL LOGO"],
  ["equipments", null],
  ["documentList", null],
  ["meterList", null],
  ["accessList", null],
  ["GMAO", null],
];

export const inventoryMapping = [
  ["$index", "ID EQUIPEMENT", NUMBER],
  ["isNew", () => false],
  ["inventory.idReference", "ID REFERENCE"],
  ["inventory.DCode", "CODE D"],
  ["inventory.PCode", "CODE P"],
  ["inventory.DIcode", "CODE DI"],
  ["inventory.FRTcode", "CODE FRT"],
  ["inventory.DIcodeAssociation", "ASSOCIATION CODE DI", SBOOL],
  ["inventory.place", "SITE"],
  ["inventory.building", "BATIMENT"],
  ["inventory.stage", "NIVEAU"],
  ["inventory.room", "LOCAL"],
  ["inventory.GMAO", "GMAO"],
  ["inventory.absentRepository", "ABSENT REFERENTIEL", XBOOL],
  ["inventory.p3", "P3", XBOOL],
  ["inventory.designation", "DESIGNATION"],
  ["inventory.status", "STATUT"],
  ["inventory.health", "ETAT SANTE"],
  ["inventory.initialQuantity", "QTE INITIALE", NULLABLENUMBER],
  ["inventory.notedQuantity", "QTE RELEVEE", NULLABLENUMBER],
  ["inventory.elementarySystem", "SYSTEME ELEMENTAIRE"],
  ["inventory.family", "FAMILLE"],
  ["inventory.typeOfTechnology", "TYPE TECHNOLOGIQUE"],
  ["inventory.FIP", "FIP"],
  ["inventory.brand", "MARQUE"],
  ["inventory.model", "MODELE"],
  ["inventory.reference", "REFERENCE"],
  ["inventory.serialNumber", "NUM SERIE"],
  ["inventory.IDnumber", "N° IDENTIFICATION"],
  ["inventory.dateOfCommissioning", "DATE MES", DATE],
  ["inventory.photoName", "NOM PHOTO"],
  [null, "NOM PHOTO SIGNALETIQUE"],
  ["anomalies.security.criticality", "CRITICITE SECURITE"],
  ["anomalies.security.reason", "MOTIF SECURITE"],
  ["anomalies.security.observation", "OBSERVATION SECURITE"],
  ["anomalies.security.PV", "PV SECURITE", XBOOL],
  ["anomalies.security.photoName", "NOM PHOTO SECURITE"],
  ["anomalies.maintenability.criticality", "CRITICITE MAINTENABILITE"],
  ["anomalies.maintenability.reason", "MOTIF MAINTENABILITE"],
  ["anomalies.maintenability.observation", "OBSERVATION MAINTENABILITE"],
  ["anomalies.maintenability.PV", "PV MAINTENABILITE", XBOOL],
  ["anomalies.maintenability.photoName", "NOM PHOTO MAINTENABILITE"],
  ["anomalies.another.criticality", "CRITICITE AUTRE"],
  ["anomalies.another.reason", "MOTIF AUTRE"],
  ["anomalies.another.observation", "OBSERVATION AUTRE"],
  ["anomalies.another.PV", "PV AUTRE", XBOOL],
  ["anomalies.another.photoName", "NOM PHOTO AUTRE"],
];

export const inventoryPostProcess = (equipments) => {
  const inventoryPostProcessItem = (inventory) => {
    const anomalies = inventory.anomalies;
    const anomalyIds = ["security", "maintenability", "another"];

    anomalyIds.forEach((anomalyId) => {
      const anomaly = anomalies[anomalyId];

      if (
        anomaly.criticality === "" &&
        anomaly.reason === "" &&
        anomaly.observation === "" &&
        !anomaly.PV &&
        anomaly.photoName === ""
      ) {
        delete anomalies[anomalyId];
      }
    });

    return inventory;
  };

  Object.keys(equipments).forEach((id) => {
    equipments[id] = inventoryPostProcessItem(equipments[id]);
  });

  return equipments;
};

export const inventorySubMapping = [
  ["equipmentId", "ID EQUIPEMENT"],
  ["attributeId", "ID ATTRIBUT"],
  ["section", "SECTION"],
  [null, "SOUS SECTION"],
  ["fieldName", "NOM CHAMP"],
  ["value", "VALEUR"],
];

export const inventorySubPreProcess = (inventorySub) => {
  return Object.entries(inventorySub).reduce(
    (lines, [equipmentId, fieldsByEquipment]) => {
      return Object.entries(fieldsByEquipment).reduce(
        (lines, [attributeId, fields]) => {
          lines[`${equipmentId}.${attributeId}`] = {
            equipmentId,
            attributeId,
            ...fields,
          };

          return lines;
        },
        lines
      );
    },
    {}
  );
};

export const inventorySubPostProcess = (inventorySub) => {
  return Object.values(inventorySub).reduce((byEquipment, item) => {
    const { equipmentId, attributeId, ...rest } = item;

    if (byEquipment[equipmentId] === undefined) {
      byEquipment[equipmentId] = {};
    }

    byEquipment[equipmentId][attributeId] = rest;

    return byEquipment;
  }, {});
};

export const documentMapping = [
  ["$index", "NOM DU DOCUMENT"],
  ["delivered", "REMIS", STRICTBOOL],
  ["upToDate", "A JOUR", STRICTBOOL],
  ["noObject", "SANS OBJET", STRICTBOOL],
];

export const meterMapping = [
  ["type", "TYPE DE COMPTEUR"],
  ["value", "RELEVE", NUMBER],
  ["name", "DESIGNATION"],
];

export const accessMapping = [
  ["type", "TYPE"],
  ["name", "DESIGNATION"],
  ["number", "NUMERO", NUMBER],
  ["quantity", "QUANTITE", NUMBER],
  ["identifier", "IDENTIFIANT"],
  ["password", "MDP"],
];

export const GMAOMapping = [
  ["elementarySystem", "SYSTEME ELEMENTAIRE"],
  ["family", "FAMILLE"],
  ["typeOfTechnology", "TYPE TECHNOLOGIQUE"],
  ["FIP", "FIP"],
];
