import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { IonPage, IonContent, IonItem, IonLabel, IonIcon, IonInput, IonRow, IonGrid, IonCol, IonPopover, IonList, IonListHeader, IonTextarea } from '@ionic/react';
import { removeCircle, addCircle, more, checkmarkCircle, trash, close, arrowBack } from 'ionicons/icons';
import { withStore } from "../../store/react-pure-store";
import store from "../../store/store";
import getNextId from '../../helpers/getNextId';
import iAccessList from '../../models/accessList.module';
import { useKeyboardOpenDetectorClass } from '../../helpers/hooks';

const Access: any = ({ accessList, addAccess, removeAccess, installationName }: { accessList: iAccessList, setAccess, addAccess, removeAccess, installationName }) => {

  const [moreKeysIsOpen, setMoreKeysIsOpen] = useState(false);
  const [showPopoverKey, setShowPopoverKey] = useState(false);
  const [newKeyNameValue, setNewKeyNameValue] = useState("");
  const [newKeyNumberValue, setNewKeyNumberValue] = useState("");

  const [showPopoverBadge, setShowPopoverBadge] = useState(false);
  const [moreBadgesIsOpen, setMoreBadgesIsOpen] = useState(false);
  const [newBadgeNameValue, setNewBadgeNameValue] = useState("");
  const [newBadgeNumberValue, setNewBadgeNumberValue] = useState("");

  const [showPopoverCode, setShowPopoverCode] = useState(false);
  const [moreCodesIsOpen, setMoreCodesIsOpen] = useState(false);
  const [newCodeNameValue, setNewCodeNameValue] = useState("");
  const [newCodeNumberValue, setNewCodeNumberValue] = useState("");
  const [newCodePasswordValue, setNewCodePasswordValue] = useState("");

  const [moreIsOpen, setMoreIsOpen] = useState(false);
  let [keyCounter, setKeyCounter] = useState(0);
  let [badgeCounter, setBadgeCounter] = useState(0);
  const [newOtherAccess, setNewOtherAccess] = useState("");
  const [showPopover, setShowPopover] = useState(false);
  const history = useHistory();
  const popoverClass = useKeyboardOpenDetectorClass();

  return (
    <IonPage>

      {/* <-> */}
      {/* KEY */}
      {/* <-> */}
      <IonItem onClick={() => history.goBack()} color='primary' class="ion-no-padding" lines="none">
        <IonRow style={{ height: "60px" }} class="ion-align-items-center">
          <IonCol size="1">
            <IonIcon icon={arrowBack} style={{ fontSize: "18px" }} />
          </IonCol>
          <IonCol size="11">
            <IonLabel style={{ fontSize: "16px" }}>MOYEN D'ACCES - {installationName}</IonLabel>
          </IonCol>
        </IonRow>
      </IonItem>

      <IonContent color='secondary'>

        <IonItem color='secondary' lines="none" class="ion-no-padding">
          <IonGrid>
            <IonRow class="ion-justify-content-between">
              <IonLabel color="medium" style={{ padding: '5px' }}><strong>Clé</strong></IonLabel>
              <IonIcon style={{ fontSize: "28px" }} onClick={() => setMoreKeysIsOpen(!moreKeysIsOpen)} color="medium" icon={addCircle} />
              <IonPopover cssClass={popoverClass} isOpen={moreKeysIsOpen} onDidDismiss={e => { setMoreKeysIsOpen(false); setNewKeyNameValue(""); setNewKeyNumberValue("") }}>
                <IonItem color="light">
                  <IonLabel color="medium">Ajouter une clé</IonLabel>
                  <IonIcon onClick={() => setMoreKeysIsOpen(false)} icon={close} />
                </IonItem>
                <IonItem>
                  <IonLabel color="medium">Désignation: </IonLabel>
                  <IonInput required onIonChange={(e) => setNewKeyNameValue(e.detail.value || '')} value={newKeyNameValue} />
                </IonItem>
                <IonItem>
                  <IonLabel color="medium">Numéro: </IonLabel>
                  <IonInput required type="number"
                    onIonChange={(e) => setNewKeyNumberValue(e.detail.value || '')} value={newKeyNumberValue} />
                </IonItem>
                <IonItem>
                  <IonLabel color="medium">Quantité :</IonLabel>
                  <IonIcon onClick={() => setKeyCounter(keyCounter - 1)} icon={removeCircle} class='ion-padding' />
                  {keyCounter < 1 ?
                    (
                      <IonLabel style={{ maxWidth: "15px" }} >{keyCounter = 1}</IonLabel>
                    ) : (
                      <IonLabel style={{ maxWidth: "15px" }} >{keyCounter}</IonLabel>
                    )
                  }
                  <IonIcon onClick={() => setKeyCounter(keyCounter + 1)} icon={addCircle} class='ion-padding' />
                </IonItem>
                <IonItem color="light" class="ion-justify-content-end">
                  <IonGrid>
                    <IonRow style={{ display: 'flex' }} class="ion-justify-content-end">
                      <IonIcon onClick={() => {
                        setMoreKeysIsOpen(!moreKeysIsOpen);
                        addAccess("CLE", { name: newKeyNameValue, number: newKeyNumberValue, quantity: keyCounter })
                      }}
                        size="large" color="success" icon={checkmarkCircle} />
                    </IonRow>
                  </IonGrid>
                </IonItem>
              </IonPopover>
            </IonRow>
            <IonRow>
              <IonCol size='5'>
                <IonLabel color="medium">Désignation</IonLabel>
              </IonCol>
              <IonCol size='4'>
                <IonLabel color="medium">Numéro</IonLabel>
              </IonCol>
              <IonCol size='2'>
                <IonLabel color="medium">Qté</IonLabel>
              </IonCol>
              <IonCol size='1'>
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonItem>
        <IonItem class="ion-no-padding">
          <IonGrid>
            {
              Object.entries(accessList).map(([id, eachAccess]) => (
                eachAccess.type === "CLE" && (
                  <IonRow key={id}>
                    <IonCol size='5'>
                      <IonLabel color="medium">{eachAccess.name}</IonLabel>
                    </IonCol>
                    <IonCol size='4'>
                      <IonLabel color="medium">{eachAccess.number}</IonLabel>
                    </IonCol>
                    <IonCol class="ion-justify-content-center" size='2'>
                      <IonLabel color="medium">{eachAccess.quantity}</IonLabel>
                    </IonCol>
                    <IonCol size='1' class="ion-no-padding" style={{ maxHeight: "20px" }}>
                      <IonIcon onClick={() => setShowPopoverKey(true)} style={{ fontSize: "23px" }} icon={more} color="medium" />
                      <IonPopover cssClass={popoverClass} isOpen={showPopoverKey} onDidDismiss={e => setShowPopoverKey(false)}>
                        <IonList>
                          <IonListHeader color="light" >
                            <IonIcon onClick={() => setShowPopoverKey(false)} icon={close} style={{marginLeft: "200px"}} />
                          </IonListHeader>
                          <IonItem onClick={() => removeAccess(id)}><IonIcon icon={trash} color="danger" /> &nbsp; Supprimer</IonItem>
                        </IonList>
                      </IonPopover>
                    </IonCol>
                  </IonRow>
                ))
              )
            }
          </IonGrid>
        </IonItem>

        {/* <---> */}
        {/* BADGE */}
        {/* <---> */}

        <IonItem color='secondary' lines="none" class="ion-no-padding">
          <IonGrid>
            <IonRow class="ion-justify-content-between">
              <IonLabel color="medium" style={{ padding: '5px' }}><strong>Badge</strong></IonLabel>
              <IonIcon style={{ fontSize: "28px" }} onClick={() => setMoreBadgesIsOpen(!moreKeysIsOpen)} color="medium" icon={addCircle} />
              <IonPopover cssClass={popoverClass} isOpen={moreBadgesIsOpen} onDidDismiss={e => { setMoreBadgesIsOpen(false); setNewBadgeNameValue(""); setNewBadgeNumberValue("") }}>
                <IonItem color="light">
                  <IonLabel color="medium">Ajouter un badge</IonLabel>
                  <IonIcon onClick={() => setMoreBadgesIsOpen(false)} icon={close} />
                </IonItem>
                <IonItem>
                  <IonLabel color="medium">Désignation: </IonLabel>
                  <IonInput onIonChange={(e) => setNewBadgeNameValue(e.detail.value || '')} required value={newBadgeNameValue} />
                </IonItem>
                <IonItem>
                  <IonLabel color="medium">Numéro: </IonLabel>
                  <IonInput type="number" required
                    onIonChange={(e) => setNewBadgeNumberValue(e.detail.value || '')}
                    value={newBadgeNumberValue} />
                </IonItem>
                <IonItem>
                  <IonLabel color="medium">Quantité :</IonLabel>
                  <IonIcon onClick={() => setBadgeCounter(badgeCounter - 1)} icon={removeCircle} class='ion-padding' />
                  {badgeCounter < 1 ?
                    (
                      <IonLabel style={{ maxWidth: "15px" }} >{badgeCounter = 1}</IonLabel>
                    ) : (
                      <IonLabel style={{ maxWidth: "15px" }} >{badgeCounter}</IonLabel>
                    )
                  }
                  <IonIcon onClick={() => setBadgeCounter(badgeCounter + 1)} icon={addCircle} class='ion-padding' />
                </IonItem>
                <IonItem color="light" class="ion-justify-content-end">
                  <IonGrid>
                    <IonRow style={{ display: 'flex' }} class="ion-justify-content-end">
                      <IonIcon onClick={() => {
                        setMoreBadgesIsOpen(!moreBadgesIsOpen);
                        addAccess("BADGE", { name: newBadgeNameValue, number: newBadgeNumberValue, quantity: badgeCounter })
                      }}
                        size="large" color="success" icon={checkmarkCircle} />
                    </IonRow>
                  </IonGrid>
                </IonItem>
              </IonPopover>
            </IonRow>
            <IonRow>
              <IonCol size='5'>
                <IonLabel color="medium">Désignation</IonLabel>
              </IonCol>
              <IonCol size='4'>
                <IonLabel color="medium">Numéro</IonLabel>
              </IonCol>
              <IonCol size='2'>
                <IonLabel color="medium">Qté</IonLabel>
              </IonCol>
              <IonCol size='1'>
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonItem>
        <IonItem class="ion-no-padding">
          <IonGrid>
            {
              Object.entries(accessList).map(([id, eachAccess]) => (
                eachAccess.type === "BADGE" && (
                  <IonRow key={id}>
                    <IonCol size='5'>
                      <IonLabel color="medium">{eachAccess.name}</IonLabel>
                    </IonCol>
                    <IonCol size='4'>
                      <IonLabel color="medium">{eachAccess.number}</IonLabel>
                    </IonCol>
                    <IonCol class="ion-justify-content-center" size='2'>
                      <IonLabel color="medium">{eachAccess.quantity}</IonLabel>
                    </IonCol>
                    {(
                      <IonCol size='1' class="ion-no-padding" style={{ maxHeight: "20px" }}>
                        <IonIcon onClick={() => setShowPopoverBadge(true)} style={{ fontSize: "23px" }} icon={more} color="medium" />
                        <IonPopover cssClass={popoverClass} isOpen={showPopoverBadge} onDidDismiss={e => setShowPopoverBadge(false)}>
                          <IonList>
                            <IonListHeader color="light" >
                              <IonIcon onClick={() => setShowPopoverBadge(false)} icon={close} style={{marginLeft: "200px"}} />
                            </IonListHeader>
                            <IonItem onClick={() => removeAccess(id)}><IonIcon icon={trash} color="danger" /> &nbsp; Supprimer</IonItem>
                          </IonList>
                        </IonPopover>
                      </IonCol>
                    )}
                  </IonRow>
                ))
              )
            }
          </IonGrid>
        </IonItem>

        {/* <---> */}
        {/* CODE */}
        {/* <---> */}

        <IonItem color='secondary' lines="none" class="ion-no-padding">
          <IonGrid>
            <IonRow class="ion-justify-content-between">
              <IonLabel color="medium" style={{ padding: '5px' }}><strong>Code</strong></IonLabel>
              <IonIcon style={{ fontSize: "28px" }} onClick={() => setMoreCodesIsOpen(!moreCodesIsOpen)} color="medium" icon={addCircle} />
              <IonPopover cssClass={popoverClass} isOpen={moreCodesIsOpen}
                onDidDismiss={e => { setMoreCodesIsOpen(false); setNewCodeNameValue(""); setNewCodeNumberValue(""); setNewCodePasswordValue("") }}>
                <IonItem color="light">
                  <IonLabel color="medium">Ajouter un code</IonLabel>
                  <IonIcon onClick={() => setMoreCodesIsOpen(false)} icon={close} />
                </IonItem>
                <IonItem>
                  <IonLabel color="medium">Désignation: </IonLabel>
                  <IonInput onIonChange={(e) => setNewCodeNameValue(e.detail.value || '')} required value={newCodeNameValue} />
                </IonItem>
                <IonItem>
                  <IonLabel color="medium">Identifiant: </IonLabel>
                  <IonInput onIonChange={(e) => setNewCodeNumberValue(e.detail.value || '')} required value={newCodeNumberValue} />
                </IonItem>
                <IonItem>
                  <IonLabel color="medium">Mot de passe :</IonLabel>
                  <IonInput onIonChange={(e) => setNewCodePasswordValue(e.detail.value || '')} required value={newCodePasswordValue} />
                </IonItem>
                <IonItem color="light" class="ion-justify-content-end">
                  <IonGrid>
                    <IonRow style={{ display: 'flex' }} class="ion-justify-content-end">
                      <IonIcon onClick={() => {
                        setMoreCodesIsOpen(!moreCodesIsOpen);
                        addAccess("CODE", { name: newCodeNameValue, identifier: newCodeNumberValue, password: newCodePasswordValue })
                      }}
                        size="large" color="success" icon={checkmarkCircle} />
                    </IonRow>
                  </IonGrid>
                </IonItem>
              </IonPopover>
            </IonRow>
            <IonRow>
              <IonCol size='4'>
                <IonLabel color="medium">Désignation</IonLabel>
              </IonCol>
              <IonCol size='4'>
                <IonLabel color="medium">Identifiant</IonLabel>
              </IonCol>
              <IonCol size='3'>
                <IonLabel color="medium">MDP</IonLabel>
              </IonCol>
              <IonCol size='1'>
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonItem>
        <IonItem class="ion-no-padding">
          <IonGrid>
            {
              Object.entries(accessList).map(([id, eachAccess]) => (
                eachAccess.type === "CODE" && (
                  <IonRow key={id}>
                    <IonCol size='4'>
                      <IonLabel color="medium">{eachAccess.name}</IonLabel>
                    </IonCol>
                    <IonCol size='4'>
                      <IonLabel color="medium">{eachAccess.identifier}</IonLabel>
                    </IonCol>
                    <IonCol class="ion-justify-content-center" size='3'>
                      <IonLabel color="medium">{eachAccess.password}</IonLabel>
                    </IonCol>
                    {(
                      <IonCol size='1' class="ion-no-padding" style={{ maxHeight: "20px" }}>
                        <IonIcon onClick={() => setShowPopoverCode(true)} style={{ fontSize: "23px" }} icon={more} color="medium" />
                        <IonPopover cssClass={popoverClass} isOpen={showPopoverCode} onDidDismiss={e => setShowPopoverCode(false)}>
                          <IonList>
                            <IonListHeader color="light">
                              <IonIcon onClick={() => setShowPopoverCode(false)} icon={close} style={{marginLeft: "200px"}} />
                            </IonListHeader>
                            <IonItem onClick={() => removeAccess(id)}><IonIcon icon={trash} color="danger" /> &nbsp; Supprimer</IonItem>
                          </IonList>
                        </IonPopover>
                      </IonCol>
                    )}
                  </IonRow>
                ))
              )
            }
          </IonGrid>
        </IonItem>

        {/* <---> */}
        {/* ELSE */}
        {/* <---> */}

        <IonItem color='secondary' lines="none" class="ion-no-padding">
          <IonGrid>
            <IonRow class="ion-justify-content-between">
              <IonLabel color="medium" style={{ padding: '5px' }}><strong>Autre</strong></IonLabel>
              <IonIcon style={{ fontSize: "28px" }} onClick={() => setMoreIsOpen(!moreIsOpen)} color="medium" icon={addCircle} />
            </IonRow>
          </IonGrid>
        </IonItem>
        <IonList class="ion-no-padding">
          {
            Object.entries(accessList).map(([id, eachAccess]) => (
              eachAccess.type === "AUTRE" && (
                <IonGrid class="ion-no-padding" key={id}>
                  <IonItem class="ion-no-padding">
                    <IonLabel style={{ paddingLeft: "10px" }} color="medium">{eachAccess.name}</IonLabel>
                    <IonIcon style={{ paddingRight: "8px", fontSize: "23px" }}
                      color="medium" onClick={() => setShowPopover(true)} icon={more} />
                  </IonItem>
                  {(
                    <IonPopover cssClass={popoverClass} isOpen={showPopover} onDidDismiss={e => setShowPopover(false)}>
                      <IonList>
                        <IonListHeader color="light" >
                          <IonIcon onClick={() => setShowPopover(false)} icon={close} style={{marginLeft: "200px"}} />
                        </IonListHeader>
                        <IonItem onClick={() => { removeAccess(id); setShowPopover(false) }}><IonIcon icon={trash} color="danger" />
                          &nbsp; Supprimer
                        </IonItem>
                      </IonList>
                    </IonPopover>
                  )}
                </IonGrid>
              ))
            )
          }

          <IonPopover cssClass={popoverClass} isOpen={moreIsOpen} onDidDismiss={e => { setMoreIsOpen(false); setNewOtherAccess("") }}>
            <IonItem color="light">
              <IonLabel color="medium">Autre</IonLabel>
              <IonIcon onClick={() => setMoreIsOpen(false)} icon={close} />
            </IonItem>
            <IonItem>
              <IonLabel>Désignation: </IonLabel>
              <IonTextarea rows={6} cols={20}
                onIonChange={(e) => setNewOtherAccess(e.detail.value || '')} required value={newOtherAccess} />
            </IonItem>
            <IonItem color="light" class="ion-justify-content-end">
              <IonGrid>
                <IonRow style={{ display: 'flex' }} class="ion-justify-content-end">
                  <IonIcon onClick={() => {
                    setMoreIsOpen(!moreIsOpen);
                    addAccess("AUTRE", { name: newOtherAccess })
                  }}
                    size="large" color="success" icon={checkmarkCircle} />
                </IonRow>
              </IonGrid>
            </IonItem>
          </IonPopover>
        </IonList>

      </IonContent>
    </IonPage>
  );
};

export default withStore(() => store.storeFor(state => state.currentInstallation),
  ({ name, accessList }) => ({
    installationName: name,
    accessList,
  }),
  () => ({
    addAccess: (type, data) => ({ accessList }) => {
      const newAccessId = getNextId(Object.keys(accessList));
      accessList[newAccessId] = { type, ...data };
    },
    removeAccess: id => ({ accessList }) => delete accessList[id],
  }))(Access);