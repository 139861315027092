import { IonButton, IonButtons, IonContent, IonHeader, IonIcon, IonModal, IonSpinner, IonTitle, IonToolbar } from '@ionic/react';
import React, { useEffect, useState } from 'react';
import { arrowBack } from 'ionicons/icons';
import { fetchFile } from '../../controllers/gapi';

interface ImageViewerProps {
  title: string;
  url?: string;
  fileId?: string;
  isOpen: boolean;
  setOpen: (open: boolean) => void;
}

export const getViewerPhotoProps = ({ imageUrl, photoLocalBlob, photoFileId }) => {
  if (imageUrl) {
    return {
      url: imageUrl,
    };
  }

  if (photoLocalBlob) {
    return {
      url: photoLocalBlob,
    };
  }

  if (photoFileId) {
    return {
      fileId: photoFileId,
    };
  }

  return {};
};

const ImageViewer: React.FC<ImageViewerProps> = ({ title, url, fileId, isOpen, setOpen }) => {
  const [uri, setURI] = useState<undefined | string>(undefined);
  const [fetching, setFetching] = useState(false);

  useEffect(() => {
    if (isOpen && !fetching && uri === undefined && fileId && !url) {
      fetchFile(fileId).then(fetchedUri => {
        setURI(fetchedUri);
        setFetching(false);
      });
      setFetching(true);
    }
    
  }, [url, uri, setURI, fileId, isOpen, fetching]);

  return (
    <IonModal isOpen={isOpen}>
      <IonHeader translucent>
        <IonToolbar color="primary">
          <IonButtons slot="start">
            <IonButton color="secondary" onClick={() => setOpen(false)}>
              <IonIcon icon={arrowBack} style={{ fontSize: "18px" }} />
            </IonButton>
          </IonButtons>
          <IonTitle style={{ fontSize: "16px" }}>
            {title}
          </IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <div style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          height: '100%',
        }}>{
          fetching ?
            <IonSpinner name="crescent" /> :
            <img referrerPolicy="no-referrer" src={url || uri} alt="Détail" />
        }
          </div>
      </IonContent>
    </IonModal>
  );
};

export default ImageViewer;