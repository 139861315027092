const getHashChunk = base => Math.random().toString(base).substring(2);

const getHash = (base, length) => {
  let hash = '';

  do {
    hash += getHashChunk(base);
  } while(hash.length < length);

  return hash.substring(0, length);
};

const getValidatedHash = (base, length, transform, validator) => {
  let id;

  do {
    id = transform(getHash(base, length));
  } while(!validator(id));

  return id;
};

export default function ({
  base = 36,
  length = 24,
  transform = hash => hash,
  validator = () => true,
}) {
  return {
    generate: () => getValidatedHash(base, length, transform, validator),
  };
};