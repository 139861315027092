import React, { useState } from 'react';
import { IonContent, IonPage, IonGrid, IonRow, IonImg, IonSelect, IonSelectOption, IonFooter, IonButton, IonItem, IonLabel, IonCol, IonFab, IonFabButton, IonIcon } from '@ionic/react';
import { withStore } from '../../store/react-pure-store';
import store from '../../store/store';
import { getUserInfo } from '../../controllers/gapiController';
import iRegionList from '../../models/iRegionList.module';
import { useHistory } from 'react-router';
import { returnLeft } from 'ionicons/icons';
import { versionName } from '../../config/versionName.json';

type ProfileProps = {
  regions: iRegionList;
  selectedRegion: string | null;
  saveProfile: (regionId: string) => void;
}

const Profile: React.FC<ProfileProps> = ({ saveProfile, regions, selectedRegion }) => {
  const userInfo = getUserInfo() || null;
  const history = useHistory();
  const [regionId, setRegionId] = useState(selectedRegion);

  const saveLocalProfile = () => {
    if (regionId !== null) {
      saveProfile(regionId);
      history.push('/home');
    }
  }

  return (
    <IonPage>
      <IonContent color="secondary">
        {
          selectedRegion !== null && (
            <IonFab vertical="top" horizontal="end" slot="fixed">
              <IonFabButton routerLink="/home">
                <IonIcon icon={returnLeft} />
              </IonFabButton>
            </IonFab>
          )
        }

        <IonGrid class="ion-no-padding">
          <IonRow class="ion-justify-content-center">
            <IonImg src="/assets/starterlight.png" alt="Starterlight Logo"/>
          </IonRow>
          <IonRow class="ion-justify-content-center" style={{
            color: '#555',
            fontSize: '0.75em',
            marginTop: '-3em',
            marginBottom: '2em',
          }}>
            Version: {versionName}
          </IonRow>

          <IonRow class="ion-justify-content-center" style={{ color: '#444444', margin: '20px 0' }}>
            <IonLabel>Bienvenue</IonLabel>
          </IonRow>
          <IonRow class="ion-justify-content-center">
            <img src={userInfo?.imageUrl} alt="Profil" style={{ borderRadius: '50%' }} />
          </IonRow>
          <IonRow class="ion-justify-content-center" style={{ color: '#444444', margin: '20px 0' }}>
            <IonLabel>{userInfo?.email}</IonLabel>
          </IonRow>
          <IonRow class="ion-justify-content-center" style={{ color: '#444444', margin: '20px 0' }}>
            <IonLabel>{userInfo?.name}</IonLabel>
          </IonRow>
            <IonRow>
              <IonCol size="12" class="ion-no-padding">
                <IonItem style={{ margin: '20px 0' }}>
                  <IonSelect
                    onIonChange={(e) => { setRegionId(e.detail.value) }}
                    value={regionId}
                    cancel-text="Annuler"
                    interface="action-sheet"
                    placeholder="Sélectionner votre région"
                    style={{ minWidth: '280px' }}
                  >
                    {
                      Object.entries(regions).map(([id, region]) => (
                        <IonSelectOption key={id} value={id}>
                          {region.name}
                        </IonSelectOption>
                      ))
                    }
                  </IonSelect>
                </IonItem>
                
              </IonCol>
            </IonRow>
        </IonGrid>

        
      </IonContent>

      <IonFooter>
        <IonButton class="ion-no-margin" color="success" expand="full" onClick={saveLocalProfile} disabled={regionId === null}>
          VALIDER VOTRE PROFIL
        </IonButton>
      </IonFooter>
    </IonPage>
  );
};

export default withStore(
  store,
  state => ({
    regions: state.regions,
    selectedRegion: state.selectedRegion,
  }),
  () => ({
    saveProfile: regionId => state => {
      state.selectedRegion = regionId;
    },
  }))(Profile);
