import {
  createFolder,
  createSpreadsheet,
  indexArray,
  loadSpreadsheet,
  writeSpreadsheet,
} from "../gapi";
import {
  mapSheetToStoreCreator,
  mapStoreToSheetCreator,
  sheetColumnsCreator,
} from "../mappers";
import {
  installationListMapping,
  compilationMapping,
} from "./sheetDataMappings";
import { inventoryMapping, inventorySubMapping } from "../sheetDataMappings";
import {
  Types,
  getFile,
  getExportsFolderId,
  getRegionFolderId,
  getInstallationsFolderId,
  getInstallationFolderId,
  checkDestinationFolder,
} from "../util";

const loadInstallationList = async (regionFolderId) => {
  const spreadsheetId = await getFile(
    regionFolderId,
    "Installations",
    Types.Spreadsheet
  );

  const spreadsheetData = await loadSpreadsheet(
    spreadsheetId,
    mapSheetToStoreCreator(installationListMapping)
  );

  return spreadsheetData.map(({ DIcode }) => DIcode);
};

const getInstallationInventorySpreadsheetId = async (
  installationsFolderId,
  installationId
) => {
  const installationFolderId = await getInstallationFolderId(
    installationsFolderId,
    installationId
  );

  const inventorySpreadsheetId = await getFile(
    installationFolderId,
    "Inventaire",
    Types.Spreadsheet
  );

  return inventorySpreadsheetId;
};

const createExportFiles = async (exportFolderId, spreadsheetName) => {
  const childExportFolderId = await createFolder(
    exportFolderId,
    spreadsheetName
  );

  const hotSpreadsheetId = await createSpreadsheet(
    childExportFolderId,
    "Chaud",
    ["Feuille 1"]
  );

  const coldSpreadsheetId = await createSpreadsheet(
    childExportFolderId,
    "Froid",
    ["Feuille 1"]
  );

  return {
    hotSpreadsheetId,
    coldSpreadsheetId,
  };
};

const compileInventoryLine = (line, inventory) => {
  return {
    DIcode: inventory.DIcode,
    place: inventory.place,
    building: inventory.building,
    stage: inventory.stage,
    room: inventory.room,
    designation: inventory.designation,
    family: inventory.family,
    typeOfTechnology: inventory.typeOfTechnology,
    fieldName: line.fieldName,
    value: line.value,
  };
};

const getInventoryLines = async (inventorySpreadsheetId) => {
  const inventoryData = indexArray(
    await loadSpreadsheet(
      inventorySpreadsheetId,
      mapSheetToStoreCreator(inventoryMapping),
      "Feuille 1"
    ),
    (equipment) => equipment.$index
  );

  const compileInventoryLines = (lines) => {
    return lines.map((line) => {
      const inventory = inventoryData[line.equipmentId].inventory;
      return compileInventoryLine(line, inventory);
    });
  };

  const inventoryHotData = await loadSpreadsheet(
    inventorySpreadsheetId,
    mapSheetToStoreCreator(inventorySubMapping),
    "Chaud"
  );

  const inventoryColdData = await loadSpreadsheet(
    inventorySpreadsheetId,
    mapSheetToStoreCreator(inventorySubMapping),
    "Froid"
  );

  return {
    inventoryHotLines: compileInventoryLines(inventoryHotData),
    inventoryColdLines: compileInventoryLines(inventoryColdData),
  };
};

const writeCompiledLines = async (spreadsheetId, inventoryLines) => {
  await writeSpreadsheet(
    spreadsheetId,
    inventoryLines.map((line, index) => [index, line]),
    inventoryLines.length > 0
      ? mapStoreToSheetCreator(compilationMapping)
      : sheetColumnsCreator(compilationMapping),
    "Feuille 1"
  );
};

export const compileInventories = async (exportFolderName) => {
  const regionFolderId = getRegionFolderId();

  const exportFolderId = await getExportsFolderId(regionFolderId);

  const installationList = await loadInstallationList(regionFolderId);

  await checkDestinationFolder(exportFolderId, exportFolderName);

  const { hotSpreadsheetId, coldSpreadsheetId } = await createExportFiles(
    exportFolderId,
    exportFolderName
  );

  const installationsFolderId = await getInstallationsFolderId(regionFolderId);

  const inventoryHotLines = [];
  const inventoryColdLines = [];

  await Promise.all(
    installationList.map(async (installationId) => {
      const inventorySpreadsheetId = await getInstallationInventorySpreadsheetId(
        installationsFolderId,
        installationId
      );

      const {
        inventoryHotLines: installationInventoryHotLines,
        inventoryColdLines: installationInventoryColdLines,
      } = await getInventoryLines(inventorySpreadsheetId);

      inventoryHotLines.push(...installationInventoryHotLines);
      inventoryColdLines.push(...installationInventoryColdLines);
    })
  );

  await writeCompiledLines(hotSpreadsheetId, inventoryHotLines);
  await writeCompiledLines(coldSpreadsheetId, inventoryColdLines);
};
