import React, { useState, useRef } from 'react';
import { IonSpinner, IonContent, IonPage, IonToolbar, IonGrid, IonCol, IonRow, IonCardTitle, IonCardSubtitle, IonIcon, IonFooter, IonButton, IonModal, IonInput, IonList, IonItem, IonLabel, IonDatetime, IonSearchbar, IonToggle, IonBackButton, IonFabButton, IonFab, IonFabList, IonImg } from '@ionic/react';
import { business, person, pin, build, document, attach, calendar, image, arrowBack, informationCircle, checkmarkCircle, search, add, close, menu, download } from 'ionicons/icons';
import { useHistory } from 'react-router';
import iInstallation from '../../models/installation.module';
import { withStore } from '../../store/react-pure-store';
import store from '../../store/store';
import iInstallationList from '../../models/installationList.module';
// eslint-disable-next-line
import { populateInstallation } from '../../controllers/installation.service';
import { createInstallation } from "../../controllers/gapiController";
import { getUserInfo } from '../../controllers/gapiController';
import { useCaseInsensitiveSearch } from '../../helpers/search';
import iRegionList from "../../models/iRegionList.module";
import { versionName } from '../../config/versionName.json';

// change state
// const useTrigger: () => Array<any> = () => {
//   const [state, setState] = useState(false);
//   return [() => setState(!state), state];
// };

type HomeProps = {
  installationList: iInstallationList,
  memoizedInstallations,
  addInstallation,
  forkInstallationForEdition,
  addMemoizedInstallation,
  removeMemoizedInstallation,
  regions: iRegionList,
  selectedRegion: string,
}

const Home: React.FC<HomeProps> = ({ installationList, memoizedInstallations, addInstallation, forkInstallationForEdition, addMemoizedInstallation, removeMemoizedInstallation, regions, selectedRegion }) => {
  // const [trigger] = useTrigger();
  const userInfo = getUserInfo() || null;
  const history = useHistory();
  const [showModalAdd, setShowModalAdd] = useState(false);
  const [showModalSearch, setShowModalSearch] = useState(false);
  const formRef: any = useRef();
  const [p1, setP1] = useState(false);
  const [p2, setP2] = useState(false);
  const [p3, setP3] = useState(false);
  const [effectiveDate, setEffectiveDate] = useState<Date | null>(null);
  const [searchText, setSearchText] = useState("");
  const [loading, setLoading] = useState('');

  const oneYearAgo = new Date();
  oneYearAgo.setFullYear(oneYearAgo.getFullYear() - 1);

  const reinitForm = () => {
    // formRef.current.reset();
    setP1(false);
    setP2(false);
    setP3(false);
    setEffectiveDate(null);
  };

  const changeVueOnclick = async (e: any, DIcode: string, newInstallation: any = null): Promise<void> => {
    if (e) {
      e.preventDefault();
    }

    if (!loading) {
      setLoading(DIcode);
      
      try {
        if (newInstallation) {
          await createInstallation(newInstallation);
        }
        await populateInstallation(DIcode);
      }
      catch (err) {
        console.error(err);
      }
      
      forkInstallationForEdition(DIcode);
      setLoading('');
      history.push('/installation/' + DIcode);
    }
  };

  const filteredList = useCaseInsensitiveSearch(
    installationList,
    installationList => Object.entries(installationList),
    searchText,
    ([, { DIcode, name, address }]) => [DIcode, name, address]
  );

  const handleOnSave = async (form) => {
    const id = form.DIcode.value;

    if (installationList[id] !== undefined) {
      alert("L'installation existe déjà.");
    }
    else {
      const dateNow = new Date();
      const newInstallation: iInstallation = {
        createDate: dateNow.toISOString(),
        name: form.installationName.value,
        Dcode: '',
        // Dcode: form.DCode.value,
        DIcode: form.DIcode.value,
        zone: '',
        unit: '',
        lastUpdate: dateNow,
        technicianNameLastUpdate: getUserInfo().name,
        technicianNameCreate: getUserInfo().name,
        address: form.address.value,
        customerName: form.customerName.value,
        P1: p1,
        P2: p2,
        P3: p3,
        contractNumber: form.contractNumber.value,
        endorsementNumber: form.amendmentNumber.value,
        effectiveDate,
        urlLogo: form.urlLogo.value,
        GMAO: {},
        equipments: {},
        inventoryCold: {},
        inventoryHot: {},
        documentList: {},
        meterList: {},
        accessList: {}
      };
  
      addInstallation(id, newInstallation);
      addMemoizedInstallation(id);
      formRef.current.reset();
      setShowModalAdd(false);
  
      await changeVueOnclick(null, id, newInstallation);
    }
  };

  const isAdmin = () => {
    return userInfo?.email === regions[selectedRegion].email;
  }

  return (
    <IonPage>

      <IonContent color="secondary">
        <IonFab vertical="top" horizontal="end" slot="fixed">
          {
            isAdmin() ?
              (
                <>
                  <IonFabButton>
                    <IonIcon icon={menu} />
                  </IonFabButton>
                  <IonFabList side="start">
                    <IonFabButton routerLink="/profile">
                      <IonIcon icon={person} />
                    </IonFabButton>
                    <IonFabButton routerLink="/procedures">
                      <IonIcon icon={download} />
                    </IonFabButton>
                  </IonFabList>
                </>
              ): (
                <IonFabButton routerLink="/profile">
                  <IonIcon icon={person} />
                </IonFabButton>
              )
          }

        </IonFab>

        <IonGrid>
          <IonRow class="ion-justify-content-center">
            <IonImg src="/assets/starterlight.png" alt="Starterlight Logo"/>
          </IonRow>
          <IonRow class="ion-justify-content-center" style={{
            color: '#555',
            fontSize: '0.75em',
            marginTop: '-3em',
            marginBottom: '2em',
          }}>
            Version: {versionName}
          </IonRow>
        </IonGrid>

        <IonToolbar color="secondary">
          <IonGrid class="ion-no-padding">
            <IonRow style={{ marginBottom: "30px" }}>
              <IonCol class="ion-no-padding">
                <IonButton style={{ height: "77px" }} expand="full" onClick={() => {
                  reinitForm();
                  setShowModalAdd(true);
                }} class="ion-no-padding">
                  <IonGrid class="ion-no-padding">
                    <IonRow class="ion-justify-content-center" >
                      <IonIcon class="ion-float-right" size="large" icon={add} />
                    </IonRow>
                    <IonRow class="ion-justify-content-center" style={{ fontSize: "12px", marginTop: "10px", letterSpacing: "-0.2px" }}>
                      NOUVELLE INSTALLATION
                  </IonRow>
                  </IonGrid>
                </IonButton>
              </IonCol>
              <IonCol class="ion-no-padding">
                <IonButton style={{ height: "77px" }} expand="full" onClick={() => {
                  setSearchText('');
                  setShowModalSearch(true)
                }} class="ion-no-padding">
                  <IonGrid class="ion-no-padding">
                    <IonRow class="ion-justify-content-center" >
                      <IonIcon class="ion-float-right" size="large" icon={search} />
                    </IonRow>
                    <IonRow class="ion-justify-content-center" style={{ fontSize: "12px", marginTop: "10px", letterSpacing: "-0.2px" }}>
                      RECHERCHER INSTALLATION
                  </IonRow>
                  </IonGrid>
                </IonButton>
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonToolbar>
        <IonItem lines="none" color="secondary">
          <IonLabel color="medium" >Installations</IonLabel>
        </IonItem>
        {
          memoizedInstallations.map(key => [key, installationList[key]]).map(([key, installation]: [string, iInstallation]) => (
            installation &&
            (
              <IonItem key={installation.DIcode} class="ion-no-padding remove-margin">
                <IonGrid style={{ height: "72px" }}>
                  <IonRow onClick={(e) => { changeVueOnclick(e, key) }}>
                    <IonCol size="2">
                      {
                        loading === key ?
                        <IonSpinner name="crescent" style={{ margin: '0.6rem' }} /> :
                        installation.urlLogo ? (
                          <img src={installation.urlLogo} alt="logo" style={{ maxWidth: "50px", maxHeight: "50px", borderRadius: "50%" }}/>
                        ) : (
                          <IonFab>
                          <IonFabButton style={{ maxWidth: "50px", maxHeight: "50px" }} color="medium">Logo</IonFabButton>
                        </IonFab>
                        )
                      }
                    </IonCol>
                    <IonCol size="9">
                      <IonCardTitle style={{ fontSize: "14px" }}>{installation.name} - {installation.DIcode}</IonCardTitle>
                      <IonCardSubtitle style={{ fontSize: "10px" }}><IonIcon icon={pin} /> {installation.address}</IonCardSubtitle>
                    </IonCol>
                    <IonCol size="1">
                      <IonIcon icon={close} onClick={(event) => {
                        event.preventDefault();
                        event.stopPropagation();
                        removeMemoizedInstallation(key);
                        // trigger();
                      }} style={{ fontSize: "25px" }} color="medium" />
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </IonItem>
            )
          ))
        }
      </IonContent>

      <IonModal isOpen={showModalAdd} onDidDismiss={() => setShowModalAdd(false)}>
        <IonContent>
          <form ref={formRef} onSubmit={event => { event.preventDefault(); handleOnSave(formRef.current); }}>
            <IonItem color='primary' onClick={() => setShowModalAdd(false)} style={{ fontSize: '0.8em' }} lines="none">
              <IonCol size="2">
                <IonIcon icon={arrowBack} style={{ fontSize: "18px" }} />
                <IonBackButton />
              </IonCol>
              <IonCol size="10">
                <IonLabel style={{ fontSize: "16px" }}>CREER UNE INSTALLATION</IonLabel>
              </IonCol>
            </IonItem>
            <IonList style={{ backgroundColor: "#C1F7FF" }}>
              {/*<IonItem style={{ 'display': 'none' }}>*/}
              {/*  <IonLabel>Date de création</IonLabel>*/}
              {/*  <IonDatetime displayFormat='DD/MM/YYYY' value={new Date().toISOString()} name='createDate' disabled />*/}
              {/*</IonItem>*/}
              {/*<IonItem style={{ 'display': 'none' }}>*/}
              {/*  <IonLabel>Code D</IonLabel>*/}
              {/*  <IonInput name="DCode" />*/}
              {/*</IonItem>*/}
              <IonItem>
                <IonIcon icon={business} size="small" />
                <IonLabel style={{ fontSize: "13px" }} color="warning">&nbsp; NOM DE L'INSTALLATION :</IonLabel>
                <IonInput name="installationName" required />
              </IonItem>
              <IonItem>
                <IonIcon icon={informationCircle} size="small" />
                <IonLabel style={{ fontSize: "13px" }} color="warning">&nbsp; CODE DI :</IonLabel>
                <IonInput name="DIcode" required />
              </IonItem>
              <IonItem>
                <IonIcon icon={person} size="small" />
                <IonLabel style={{ fontSize: "13px" }} color="warning">&nbsp; NOM DU CLIENT :</IonLabel>
                <IonInput name="customerName" required />
              </IonItem>
              <IonItem>
                <IonIcon icon={pin} size="small" />
                <IonLabel style={{ fontSize: "13px" }} color="warning">&nbsp; ADRESSE :</IonLabel>
                <IonInput name="address" required />
              </IonItem>
              <IonItem>
                <IonIcon icon={build} size="small" />
                <IonLabel style={{ fontSize: "13px" }}>&nbsp; P1 :</IonLabel>
                <IonToggle color="primary" checked={p1} onIonChange={(e) => setP1(e.detail.checked)} />
              </IonItem>
              <IonItem>
                <IonIcon icon={build} size="small" />
                <IonLabel style={{ fontSize: "13px" }}>&nbsp; P2 :</IonLabel>
                <IonToggle color="primary" checked={p2} onIonChange={(e) => setP2(e.detail.checked)} />
              </IonItem>
              <IonItem>
                <IonIcon icon={build} size="small" />
                <IonLabel style={{ fontSize: "13px" }}>&nbsp; P3 :</IonLabel>
                <IonToggle color="primary" checked={p3} onIonChange={(e) => setP3(e.detail.checked)} />
              </IonItem>
              <IonItem>
                <IonIcon icon={document} size="small" />
                <IonLabel style={{ fontSize: "13px" }}>&nbsp; N° CONTRAT :</IonLabel>
                <IonInput name="contractNumber" />
              </IonItem>
              <IonItem>
                <IonIcon icon={attach} size="small" />
                <IonLabel style={{ fontSize: "13px" }}>&nbsp; N° AVENANT :</IonLabel>
                <IonInput name="amendmentNumber" />
              </IonItem>
              <IonItem>
                <IonIcon icon={calendar} size="small" />
                <IonLabel style={{ fontSize: "13px" }}>&nbsp; DATE DE PRISE D'EFFET :</IonLabel>
                <IonDatetime displayFormat="DD/MM/YYYY" value={effectiveDate?.toISOString()} onIonChange={(e: any) => setEffectiveDate(e.detail.value ? new Date(e.detail.value) : null)} cancel-text="Annuler" done-text="Valider" />
              </IonItem>
              <IonItem>
                <IonIcon icon={image} size="small" />
                <IonLabel style={{ fontSize: "13px" }}>&nbsp; URL LOGO :</IonLabel>
                <IonInput name="urlLogo" />
              </IonItem>
            </IonList>
            <IonFooter>
              <IonButton class="ion-no-margin" color="success" type='submit' expand="full">
                <IonIcon icon={checkmarkCircle} />
                &nbsp; CREER L'INSTALLATION
              </IonButton>
            </IonFooter>
          </form>
        </IonContent>
      </IonModal>

      <IonModal isOpen={showModalSearch} onDidDismiss={() => setShowModalSearch(false)}>
        <IonList class="ion-align-items-start" style={{ overflowY: 'scroll' }}>
          <IonSearchbar value={searchText} placeholder="Rechercher..."
            onIonChange={(e) => { setSearchText(e.detail.value || '') }} />

          <IonList class="ion-no-padding">
            {
              filteredList.map(([id, item]) => (
                <IonItem key={id} onClick={(e) => {
                  changeVueOnclick(e, id); setShowModalSearch(false); addMemoizedInstallation(id)
                }}>
                  <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                    <div style={{ flex: '0 0 auto', paddingRight: '0.5rem' }}>
                      {/* eslint-disable-next-line jsx-a11y/alt-text */}
                      <img src={`/assets/checkbox-${(item.lastUpdate && item.lastUpdate >= oneYearAgo) ? 'checked' : 'unchecked'}.png`} style={{ width: '1rem', height: '1rem' }} />
                    </div>
                    <div style={{ flex: '1 1 auto', overflow: 'hidden' }}>
                      <div style={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap', fontSize: "15px" }}>
                        {item.name}
                      </div>
                      <div style={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap', fontSize: "12px" }}>
                        {item.address}
                      </div>
                    </div>
                    <div style={{ flex: '0 0 auto', paddingLeft: '0.5rem' }}>
                      <span style={{ fontSize: '0.8em' }}>
                        {id}
                      </span>
                    </div>
                  </div>
                </IonItem>
              ))
            }
          </IonList>
        </IonList>
        <IonButton expand="full" onClick={() => setShowModalSearch(false)}>RETOUR</IonButton>
      </IonModal>
    </IonPage>
  );
};

export default withStore(
  store,
  state => ({
    installationList: state.installationList,
    memoizedInstallations: state.memoizedInstallations,
    regions: state.regions,
    selectedRegion: state.selectedRegion,
  }),
  () => ({
    addInstallation: (id, newInstallation) => state => state.installationList[id] = newInstallation,
    forkInstallationForEdition: id => state => {
      state.currentInstallation = state.installationList[id];
      state.currentInstallationState = 'saved';
    },
    addMemoizedInstallation: id => state => {
      if (!state.memoizedInstallations.includes(id)) {
        state.memoizedInstallations.push(id);
      }
    },
    removeMemoizedInstallation: id => state => {
      const index = state.memoizedInstallations.indexOf(id);
      if (index !== -1) {
        state.memoizedInstallations.splice(index, 1);
      }
    },
  }))(Home);
