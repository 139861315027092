import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { IonPage, IonContent, IonGrid, IonRow, IonCol, IonIcon, IonFabButton, IonFab, IonItem, IonLabel, IonSelect, IonSelectOption, IonAlert } from '@ionic/react';
import { funnel, radioButtonOff, camera, add, arrowBack, close } from 'ionicons/icons';
import { withStore } from '../../store/react-pure-store';
import store from '../../store/store';
import iEquipment from '../../models/equipment.module';
import getNextId from '../../helpers/getNextId';
import iInstallation from '../../models/installation.module';

const statuses = {
  'En Service': 'En Service',
  'Hors Service': 'Hors Service',
  'A l\'arrêt': 'A l\'arrêt',
  'Fonctionnement dégradé': 'Fonctionnement dégradé',
  'Non testé à vérifier': 'Non testé à vérifier',
};

const filters = {
  localisation: equipment => equipment.inventory.room,
  tech: equipment => equipment.inventory.typeOfTechnology,
  reference: equipment => equipment.inventory.reference,
};

const Equipments: any = ({ equipmentList, addEquipment, removeEquipment, match: { params: { installationId } }, currentInstallation }: { equipmentList: iEquipment, addEquipment: (a: string, b: iEquipment) => void, removeEquipment: (a: string) => void, match: any, currentInstallation: iInstallation }) => {

  const [filter, setFilter] = useState('localisation'); // ou tech, reference
  const [keyToDelete, setKeyToDelete] = useState(null);

  const history = useHistory();

  const createEquipment = () => {
    const newEquipmentId = getNextId(Object.keys(equipmentList));

    addEquipment(newEquipmentId, {
      isNew: true,
      inventory:
      {
        idReference: '',
        DCode: currentInstallation.DIcode,
        PCode: "",
        DIcode: currentInstallation.DIcode,
        FRTcode: "",
        DIcodeAssociation: true,
        place: currentInstallation.name,
        building: currentInstallation.name,
        stage: currentInstallation.name,
        room: "",
        GMAO: "",
        absentRepository: true,
        p3: currentInstallation.P3,
        designation: "",
        status: "En Service",
        health: "",
        initialQuantity: null,
        notedQuantity: 1,
        elementarySystem: "",
        family: "",
        typeOfTechnology: "",
        FIP: "",
        brand: "",
        model: "",
        reference: "",
        serialNumber: "",
        IDnumber: "",
        dateOfCommissioning: null,
        photoName: "",
        photoLocalBlob: null,
        photoLocalBlobDirty: false,
        photoFileId: null,
        thumbnailLink: null,
      },
      anomalies: {}
    });

    history.push(`/installation/${installationId}/equipment/${newEquipmentId}`);
  };

  return (
    <IonPage>
      <IonContent color="secondary">

        <IonItem onClick={() => history.goBack()} color='primary' class="ion-no-padding" lines="none">
          <IonGrid>
            <IonRow style={{ height: "60px" }} class="ion-align-items-center">
              <IonCol size="2">
                <IonIcon icon={arrowBack} style={{ fontSize: "18px" }} />
              </IonCol>
              <IonCol size="10">
                <IonLabel style={{ fontSize: "16px" }}>INVENTAIRE - {currentInstallation.name}</IonLabel>
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonItem>

        <IonItem color="secondary" lines="none">
          <IonIcon color="primary" icon={funnel} />
          <IonSelect interface="popover" value={filter} style={{ color: 'grey', marginTop: "5px" }} onIonChange={({ detail: { value } }) => setFilter(value)}>
            <IonSelectOption value="localisation">Localisation</IonSelectOption>
            <IonSelectOption value="tech">Technologie</IonSelectOption>
            <IonSelectOption value="reference">Référence</IonSelectOption>
          </IonSelect>
        </IonItem>

        {
          Object.entries(equipmentList).sort(([keyA, equipmentA], [keyB, equipmentB]) => {
            if (equipmentA.inventory.DIcodeAssociation && !equipmentB.inventory.DIcodeAssociation) { // if a===true && b ===false : a sera trié en premier dans la liste
              return -1;
            }
            if (equipmentB.inventory.DIcodeAssociation && !equipmentA.inventory.DIcodeAssociation) {
              return 1;
            }
            return filters[filter](equipmentA).localeCompare(filters[filter](equipmentB));
          }).map(([id, equipment]: [string, iEquipment]) => {
            const textColor = equipment.inventory.DIcodeAssociation ? "#676767" : "#FF773E";

            return (
              <IonItem routerLink={`/installation/${installationId}/equipment/${id}`} key={id} class="ion-no-padding remove-margin">
                <IonGrid>
                  <IonRow class="ion-justify-content-between ion-align-items-center" style={{ height: '72px' }}>
                    <IonCol size='3' class="ion-justify-content-center ion-align-items-center" style={{ display: 'flex', marginLeft: '5px' }}>
                      {
                        equipment.inventory.photoLocalBlob || equipment.inventory.thumbnailLink ?
                          <img src={equipment.inventory.photoLocalBlob || equipment.inventory.thumbnailLink || undefined} style={{ border: 'solid lightGrey 1px', height: '64px' }} alt='Equipment' /> :
                          <IonIcon size='large' style={{ border: 'solid lightGrey 1px' }} class='ion-padding' icon={camera} />
                      }
                    </IonCol>
                    <IonCol size='7' class="ion-no-padding">
                      <IonGrid class="ion-no-padding">
                        <IonRow class="ion-no-padding">
                          <IonCol size="11" class="ion-no-padding">
                            <IonLabel style={{ color: textColor }}>{equipment.inventory.designation}</IonLabel>
                            <IonLabel style={{ color: textColor, fontSize: "0.8em" }}>{equipment.inventory.room}</IonLabel>
                            <IonLabel style={{ color: textColor, fontSize: "0.8em" }}>{statuses[equipment.inventory.status]}</IonLabel>
                          </IonCol>
                          {Object.values(equipment.anomalies).filter(anomaly => anomaly !== undefined).length > 0 ? (
                            <IonCol size='1' class="ion-no-padding">
                              <IonIcon color='warning' style={{ fontSize: '16px', backgroundColor: '#FF773E', borderRadius: '50%', margin: "20px 0px 0px 20px" }} icon={radioButtonOff} />
                            </IonCol>
                          ) : (
                              <IonCol size='1' class="ion-no-padding">
                                <IonIcon color='light' style={{ fontSize: '16px', backgroundColor: '#e5e5e5', borderRadius: '50%', margin: "20px 0px 0px 20px" }} icon={radioButtonOff} />
                              </IonCol>
                            )
                          }
                        </IonRow>
                      </IonGrid>
                    </IonCol>
                    <IonCol size='1' class="ion-no-padding">
                      <IonIcon icon={close} style={{ padding: "0px 0px 50px 10px" }}
                        onClick={(event) => {
                          // @ts-ignore
                          setKeyToDelete(id);
                          event.preventDefault();
                          event.stopPropagation();
                        }} />
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </IonItem>
            );
          })
        }

        <IonAlert
          isOpen={keyToDelete !== null}
          onDidDismiss={() => setKeyToDelete(null)}
          header={'SUPPRESSION'}
          message={'Souhaitez-vous supprimer cet équipement?'}
          buttons={[
            {
              text: 'NON',
              handler: () => {

              }
            },
            {
              text: "OUI",
              handler: () => {
                //@ts-ignore
                removeEquipment(keyToDelete);
              }
            }
          ]}
        />

      </IonContent>

      <IonFab horizontal="end" vertical="bottom" onClick={createEquipment}>
        <IonFabButton color="success" >
          <IonIcon size="large" icon={add} />
        </IonFabButton>
      </IonFab>

    </IonPage>
  );
};

export default withStore(() => store.storeFor(state => state.currentInstallation),
  (currentInstallation: iInstallation) => ({
    currentInstallation,
    equipmentList: currentInstallation.equipments,
  }),
  () => ({
    addEquipment: (id, equipment) => ({ equipments }) => equipments[id] = equipment,
    removeEquipment: id => ({ equipments }) => delete equipments[id],
  }))(Equipments);