import React, { useRef, useState } from 'react';
import { useHistory } from 'react-router';
import { IonPage, IonContent, IonIcon, IonGrid, IonRow, IonCol, IonInput, IonText, IonItem, IonLabel, IonPopover, IonList, IonListHeader } from '@ionic/react';
import { flash, water, arrowBack, addCircleOutline, thermometer, bonfire, colorFill, checkmarkCircle, close, more, trash} from 'ionicons/icons';
import { withStore } from "../../store/react-pure-store";
import store from "../../store/store";
import iMeterList from '../../models/meterList.module';
import getNextId from "../../helpers/getNextId";
import { useKeyboardOpenDetectorClass } from '../../helpers/hooks';

const staticMeters = {
  "GAZ": bonfire,
  "ELECTRICITE": flash,
  "EAU": water,
  "EAU CHAUDE SANITAIRE": thermometer,
  "FIOUL": colorFill,
};

// eslint-disable-next-line
const staticMeterTypes = Object.keys(staticMeters);

const Meter: any = ({ meterList, setTypedMeterValue, addOtherMeter, editOtherMeter, removeMeter, installationName }: { meterList: iMeterList, setTypedMeterValue, addOtherMeter, editOtherMeter, removeMeter, installationName }) => {

  const history = useHistory();
  const [moreMeters, setMoreMeters] = useState(false);
  const [newMeterName, setNewMeterName] = useState("");
  const [newReadingValue, setNewReadingValue] = useState("");
  const [showPopover, setShowPopover] = useState("");
  const popoverClass = useKeyboardOpenDetectorClass();
  const inputRefs = useRef({});

  const setInputRef = (id) => (ref) => {
    inputRefs.current[id] = ref;
  };

  const focusInput = (id) => () => {
    inputRefs.current[id].setFocus();
  };

  return (
    <IonPage>
      <IonItem onClick={() => history.goBack()} color='primary' class="ion-no-padding" lines="none">
        <IonRow style={{ height: "60px" }} class="ion-align-items-center">
          <IonCol size="1">
            <IonIcon icon={arrowBack} style={{ fontSize: "18px" }} />
          </IonCol>
          <IonCol size="11">
            <IonLabel style={{ fontSize: "16px" }}>COMPTEURS - {installationName}</IonLabel>
          </IonCol>
        </IonRow>
      </IonItem>

      <IonContent color="secondary">
        <IonItem color="secondary" class="ion-no-padding" lines="none">
          <IonLabel style={{ marginLeft: "15px" }} color="medium">COMPTEURS GENERAUX</IonLabel>
        </IonItem>

        <IonGrid class="ion-no-padding" style={{ backgroundColor: "#FFFFFF", color: "#444444" }}>
          <IonRow style={{ margin: "-2px" }}>
            {
              Object.entries(staticMeters).map(([meterType, icon]) => {
                const filteredMeters = Object.entries(meterList).filter(([id, meter]) => meter.type === meterType);
                const [id, value] = filteredMeters.length > 0 ? [filteredMeters[0][0], filteredMeters[0][1].value] : [undefined, ''];
                return (
                  <IonCol onClick={focusInput(meterType)} key={meterType} size="6" class="ion-no-padding" style={{ height: "145px", border: "1px #e5e5e5 solid", paddingTop: "8px" }}>
                    <IonRow class="ion-justify-content-center ion-align-items-center">
                      <IonIcon size="large" icon={icon} style={{ padding: "10px" }} />
                    </IonRow>
                    <IonRow class="ion-justify-content-center ion-align-items-center">
                      <IonText style={{ color: "#9DA6A9", textAlign: "center" }} > {meterType} </IonText>
                    </IonRow>
                    <IonRow class="ion-justify-content-center ion-align-items-center" style={{ margin: "8px" }}>
                      <IonInput ref={setInputRef(meterType)} class="ion-no-padding" style={{ fontSize: "0.9em", textAlign: "center", marginTop: "10px" }} type="number"
                        value={value}
                        onIonChange={(e => e.detail.value ? setTypedMeterValue(id, meterType, e.detail.value) : removeMeter(id))}
                      />
                    </IonRow>
                  </IonCol>
                )
              })
            }
            <IonCol onClick={() => setMoreMeters(true)} size="6" class="ion-no-padding" style={{ height: "145px", border: "1px #e5e5e5 solid", paddingTop: "8px" }}>
              <IonRow class="ion-justify-content-center ion-align-items-center">
                <IonIcon size="large" icon={addCircleOutline} style={{ padding: "10px" }} />
              </IonRow>
              <IonRow class="ion-justify-content-center ion-align-items-center">
                <IonText style={{ color: "#9DA6A9", textAlign: "center" }} > AUTRE </IonText>
              </IonRow>
              <IonRow class="ion-justify-content-center ion-align-items-center" style={{ margin: "8px" }}>
                <IonInput class="ion-no-padding" style={{ fontSize: "0.9em", textAlign: "center", marginTop: "10px" }} type="number" />
              </IonRow>
            </IonCol>
          </IonRow>
        </IonGrid>

        <IonPopover cssClass={popoverClass} isOpen={moreMeters} onDidDismiss={e => {setMoreMeters(false); setNewMeterName(""); setNewReadingValue("")}}>
          <IonItem color="light">
            <IonLabel color="medium">Ajouter un compteur</IonLabel>
            <IonIcon onClick={() => setMoreMeters(false)} icon={close} />
          </IonItem>
          <IonItem>
            <IonLabel color="medium">Désignation: </IonLabel>
            <IonInput onIonChange={(e) => setNewMeterName(e.detail.value || '')} value={newMeterName} required />
          </IonItem>
          <IonItem>
            <IonLabel color="medium">Relevé: </IonLabel>
            <IonInput onIonChange={(e) => setNewReadingValue(e.detail.value || '')} value={newReadingValue} required />
          </IonItem>
          <IonItem color="light" class="ion-justify-content-end">
            <IonGrid>
              <IonRow style={{ display: 'flex' }} class="ion-justify-content-end">
                <IonIcon onClick={() => { addOtherMeter(newReadingValue, newMeterName); setMoreMeters(!moreMeters) }} size="large" color="success" icon={checkmarkCircle} />
              </IonRow>
            </IonGrid>
          </IonItem>
        </IonPopover>

        <IonList class="ion-no-padding" style={{ marginTop: '1rem' }}>
          {
            Object.entries(meterList).filter(([id, { type }]) => type === 'AUTRE').map( ([id, meter]) => (
              <IonItem lines="none" key={id}>
                <IonLabel color="medium">{meter.name}</IonLabel>
                <IonInput class="ion-no-padding" style={{ marginLeft: '0.5rem' }} type="number"
                          value={meter.value}
                          onIonChange={(e => editOtherMeter(id, e.detail.value || ''))}
                />
                <IonIcon icon={more} onClick={() => setShowPopover(id)} />
                <IonPopover isOpen={showPopover === id} onDidDismiss={e => setShowPopover('')}>
                  <IonList class="ion-no-padding">
                    <IonListHeader color="light">
                      <IonIcon onClick={() => setShowPopover('')} icon={close} style={{marginLeft: "200px"}} />
                    </IonListHeader>
                    <IonItem onClick={() => removeMeter(id)}><IonIcon icon={trash} color="danger" /> &nbsp; Supprimer</IonItem>
                  </IonList>
                </IonPopover>
              </IonItem>
            ))
          }
        </IonList>

      </IonContent>
    </IonPage >
  );
};

export default withStore(() => store.storeFor(state => state.currentInstallation),
  ({ name, meterList }) => ({
    installationName: name,
    meterList,
  }),
  () => ({
    setTypedMeterValue: (id, type, value) => ({ meterList }) => {
      if (id === undefined) {
        id = getNextId(Object.keys(meterList));
        meterList[id] = {
          type,
          value,
          name: '',
        };
      }
      else {
        meterList[id].value = value;
      }
    },
    addOtherMeter: (value, name) => ({ meterList }) => {
      meterList[getNextId(Object.keys(meterList))] = {
        type: 'AUTRE',
        value,
        name,
      };
    },
    editOtherMeter: (id, value) => ({ meterList }) => {
      meterList[id].value = value;
    },
    removeMeter: id => ({ meterList }) => delete meterList[id],
  }))(Meter);