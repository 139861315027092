import {
  indexArray,
  loadSpreadsheet,
  listDriveFiles,
  getIndexedDriveFileIds,
  createSpreadsheet,
  writeSpreadsheet,
  createFolder,
  copyFile,
  uploadImage,
  deleteFile,
  findSpreadsheetLineId,
} from "./gapi";

import {
  installationMapping,
  inventoryMapping,
  inventorySubMapping,
  inventoryPostProcess,
  inventorySubPreProcess,
  inventorySubPostProcess,
  documentMapping,
  meterMapping,
  accessMapping,
  GMAOMapping,
  regionMapping,
} from "./sheetDataMappings";

import {
  mapSheetToStoreCreator,
  mapStoreToSheetCreator,
  sheetColumnsCreator,
} from "./mappers";
import Hash from "../helpers/Hash";

import config from '../config/google';
export { init, getUserInfo } from "./gapi";


const installationsSpreadsheets = [
  {
    key: "equipments",
    name: "Inventaire",
    indexGetter: (equipment) => equipment.$index,
    mapper: inventoryMapping,
    sheetName: "Feuille 1",
    postProcess: inventoryPostProcess,
  },
  {
    key: "inventoryCold",
    name: "Inventaire",
    indexGetter: (inventorySub, index) => index,
    mapper: inventorySubMapping,
    sheetName: "Froid",
    preProcess: inventorySubPreProcess,
    postProcess: inventorySubPostProcess,
  },
  {
    key: "inventoryHot",
    name: "Inventaire",
    indexGetter: (inventorySub, index) => index,
    mapper: inventorySubMapping,
    sheetName: "Chaud",
    preProcess: inventorySubPreProcess,
    postProcess: inventorySubPostProcess,
  },
  {
    key: "documentList",
    name: "Documents",
    indexGetter: (document) => document.$index,
    mapper: documentMapping,
    sheetName: "Feuille 1",
  },
  {
    key: "meterList",
    name: "Compteurs",
    indexGetter: (meter, index) => index,
    mapper: meterMapping,
    sheetName: "Feuille 1",
  },
  {
    key: "accessList",
    name: "Acces",
    indexGetter: (access, index) => index,
    mapper: accessMapping,
    sheetName: "Feuille 1",
  },
  {
    key: "GMAO",
    name: "GMAO",
    indexGetter: (gmaoEntry) => gmaoEntry.typeOfTechnology,
    mapper: GMAOMapping,
    sheetName: "Feuille 1",
    readonly: true,
  },
];

const photoTypes = {
  inventory: equipment => equipment.inventory,
  security: equipment => equipment.anomalies.security,
  maintenability: equipment => equipment.anomalies.maintenability,
  another: equipment => equipment.anomalies.another,
};

const installationsPhotosFolder = 'Photos';


let installationsSpreadsheetId = null;
let installationsFolderId = null;

let modelIds = null;

let installationsFolderIds = null;
let installationsPhotoFolderIds = {};


const removeDuplicates = array => {
  return Object.values(array.reduce((index, item) => {
    index[item] = item;
    return index;
  }, {}));
};

const loadRegions = async spreadsheetId => indexArray(
  await loadSpreadsheet(spreadsheetId, mapSheetToStoreCreator(regionMapping)),
  installation => installation.folderId,
);

export const loadInstallations = async () =>
  indexArray(
    await loadSpreadsheet(
      installationsSpreadsheetId,
      mapSheetToStoreCreator(installationMapping)
    ),
    (installation) => installation.DIcode
  );

const getInstallationsFolderIds = (installationsFolderId, DIcodes) => {
  return getIndexedDriveFileIds(installationsFolderId, file => file.name, DIcodes, [
    `mimeType = 'application/vnd.google-apps.folder'`,
  ]);
};

const getModelIds = async modelsFolderId => {
  const modelNames = installationsSpreadsheets
    .map(({ name }) => name);

  return await getIndexedDriveFileIds(modelsFolderId, file => file.name, modelNames, [
    `mimeType = 'application/vnd.google-apps.spreadsheet'`,
  ]);
};

const loadDriveStructure = async () => {
  let regionsSpreadsheetId = null;

  const fileList = await listDriveFiles(config.rootFolderId);

  fileList.forEach((file) => {
    if (
      file.name === "Regions" &&
      file.mimeType === "application/vnd.google-apps.spreadsheet"
    ) {
      regionsSpreadsheetId = file.id;
    }
  });

  return {
    regionsSpreadsheetId,
  };
};

export const loadStructureData = async (rootFolderId) => {
  const { regionsSpreadsheetId } = await loadDriveStructure(rootFolderId);

  if (
    regionsSpreadsheetId === null
  ) {
    return null;
  }

  const regions = await loadRegions(regionsSpreadsheetId);

  return regions;
};

const loadInstallationsDriveStructure = async (rootFolderId) => {
  let modelsFolderId = null;

  const fileList = await listDriveFiles(rootFolderId);

  fileList.forEach((file) => {
    if (
      file.name === "Installations" &&
      file.mimeType === "application/vnd.google-apps.spreadsheet"
    ) {
      installationsSpreadsheetId = file.id;
    } else if (
      file.name === "Installations" &&
      file.mimeType === "application/vnd.google-apps.folder"
    ) {
      installationsFolderId = file.id;
    } else if (
      file.name === "Modeles" &&
      file.mimeType === "application/vnd.google-apps.folder"
    ) {
      modelsFolderId = file.id;
    }
  });

  return {
    modelsFolderId,
  };
};

export const loadInstallationsStructureData = async (rootFolderId) => {
  const { modelsFolderId } = await loadInstallationsDriveStructure(rootFolderId);

  if (
    installationsSpreadsheetId === null ||
    installationsFolderId === null ||
    modelsFolderId === null
  ) {
    return null;
  }

  const installations = await loadInstallations();

  installationsFolderIds = await getInstallationsFolderIds(
    installationsFolderId,
    Object.keys(installations)
  );
  modelIds = await getModelIds(modelsFolderId);

  return installations;
};

const getInstallationSpreadsheetIds = DIcode => {
  if (installationsFolderIds[DIcode] === undefined) {
    return null;
  }

  return getIndexedDriveFileIds(
    installationsFolderIds[DIcode],
    file => file.name,
    removeDuplicates(installationsSpreadsheets.map(({ name }) => name)),
    [
      `mimeType = 'application/vnd.google-apps.spreadsheet'`,
    ]
  );
};

const getInstallationPhotoFolderId = async DIcode => {
  if (installationsFolderIds[DIcode] === undefined) {
    return null;
  }

  if (installationsPhotoFolderIds[DIcode] === undefined) {
    const indexedFiles = await getIndexedDriveFileIds(
      installationsFolderIds[DIcode],
      file => file.name,
      [installationsPhotosFolder],
      [
        `'application/vnd.google-apps.folder'`,
      ]
    );

    installationsPhotoFolderIds[DIcode] = indexedFiles[installationsPhotosFolder];
  }

  return installationsPhotoFolderIds[DIcode];
};

const getInstallationIndexedPhotoFiles = async DIcode => {
  const photosFolderId = await getInstallationPhotoFolderId(DIcode);

  const fileList = await listDriveFiles(photosFolderId);

  return indexArray(fileList, file => file.name);
};

const uploadInstallationImages = async (DIcode, photos) => {
  const photosFolderId = await getInstallationPhotoFolderId(DIcode);
  const indexedFiles = await getInstallationIndexedPhotoFiles(DIcode);

  const toRemove = [];

  const usedNames = {...indexedFiles};

  photos.forEach(({photoName}) => {
    if (photoName && indexedFiles[photoName] !== undefined) {
      toRemove.push(indexedFiles[photoName].id);
      delete usedNames[photoName];
    }
  });

  await Promise.all(toRemove.map(deleteFile));

  const hashGenerator = new Hash({
    transform: hash => `${hash}.png`,
    validator: id => usedNames[id] === undefined,
  });

  return await Promise.all(
    photos.map(async ({ photoLocalBlob, ...rest }) => {
      const blob = await fetch(photoLocalBlob).then((r) => r.blob());

      const photoName = hashGenerator.generate();
      usedNames[photoName] = true;

      await uploadImage(photosFolderId, photoName, blob);

      return {  photoLocalBlob, ...rest, photoName  };
    })
  );
};

const getInstallationInventoryPhotosLinks = async (DIcode, installation) => {
  const indexedFiles = await getInstallationIndexedPhotoFiles(DIcode);

  const getLinksForName = (photoName) => {
    if (!photoName || !indexedFiles[photoName]) {
      return {
        thumbnailLink: null,
        photoFileId: null,
      };
    }

    const file = indexedFiles[photoName];

    return {
      thumbnailLink: file.thumbnailLink,
      photoFileId: file.id,
    };
  };

  return Object.entries(installation.equipments)
    .reduce((index, [id, equipment]) => {
      index[id] = {
        inventory: getLinksForName(equipment.inventory.photoName),
        security: getLinksForName(equipment.anomalies.security?.photoName),
        maintenability: getLinksForName(
          equipment.anomalies.maintenability?.photoName
        ),
        another: getLinksForName(equipment.anomalies.another?.photoName),
      };
      return index;
    }, {});
};


export const createInstallation = async (installation, inputData = {}) => {
  const folderId = await createFolder(
    installationsFolderId,
    installation.DIcode
  );
  await createFolder(folderId, "Photos");

  const filesToCreate = installationsSpreadsheets.reduce(
    (filesToCreate, { name, readonly, sheetName }) => {
      if (filesToCreate[name] === undefined) {
        filesToCreate[name] = {
          readonly,
          sheetNames: [],
        };
      }

      filesToCreate[name].sheetNames.push(sheetName);

      return filesToCreate;
    },
    {}
  );

  const spreadsheetIdList = await Promise.all(
    Object.entries(filesToCreate).map(
      async ([name, { readonly, sheetNames }]) => {
        if (readonly) {
          const spreadsheetId = await copyFile(modelIds[name], folderId, name);
          return [name, spreadsheetId];
        } else {
          const spreadsheetId = await createSpreadsheet(
            folderId,
            name,
            sheetNames
          );
          return [name, spreadsheetId];
        }
      }
    )
  );

  const spreadsheetIds = spreadsheetIdList.reduce(
    (spreadsheetIds, [name, spreadsheetId]) => {
      spreadsheetIds[name] = spreadsheetId;
      return spreadsheetIds;
    },
    {}
  );

  await Promise.all(
    installationsSpreadsheets.map(
      async ({ key, name, mapper, readonly, sheetName }) => {
        if (!readonly) {
          const spreadsheetId = spreadsheetIds[name];

          const inputEntries = inputData[key] || [];
  
          const data = await loadSpreadsheet(modelIds[name], mapSheetToStoreCreator(mapper), sheetName);
  
          const dataEntries = data.map(line => Object.entries(line));
  
          const entries = [...inputEntries, ...dataEntries];
  
          await writeSpreadsheet(
            spreadsheetId,
            entries,
            entries.length > 0
              ? mapStoreToSheetCreator(mapper)
              : sheetColumnsCreator(mapper),
            sheetName
          );
        }
      }
    )
  );

  const { updater } = await findSpreadsheetLineId(
    installationsSpreadsheetId,
    "DIcode",
    installation.DIcode,
    mapSheetToStoreCreator(installationMapping)
  );

  await updater(installation, mapStoreToSheetCreator(installationMapping));

  installationsFolderIds[installation.DIcode] = folderId;
};

export const loadInstallationData = async DIcode => {
  const installationSpreadsheetIds = await getInstallationSpreadsheetIds(DIcode);

  if (Object.values(installationSpreadsheetIds).filter(id => !id).length > 0) {
    return null;
  }

  const loadedSpreadsheets = await Promise.all(installationsSpreadsheets.map(async (spreadsheetParams) => [
    spreadsheetParams,
    await loadSpreadsheet(
      installationSpreadsheetIds[spreadsheetParams.name],
      mapSheetToStoreCreator(spreadsheetParams.mapper),
      spreadsheetParams.sheetName,
    ),
  ]));

  const installationData = loadedSpreadsheets.reduce((installationData, [spreadsheetParams, lines]) => {

    installationData[spreadsheetParams.key] = spreadsheetParams.indexGetter ? indexArray(lines, spreadsheetParams.indexGetter, ['$index']) : lines;

    if (spreadsheetParams.postProcess) {
      installationData[spreadsheetParams.key] = spreadsheetParams.postProcess(
        installationData[spreadsheetParams.key]
      );
    }

    return installationData;
  }, {});

  const equipmentsPhotos = await getInstallationInventoryPhotosLinks(DIcode, installationData);

  Object.keys(installationData.equipments).forEach(id => {
    const equipment = installationData.equipments[id];
    const equipmentPhotos = equipmentsPhotos[id];

    equipment.inventory.photoFileId = equipmentPhotos.inventory.photoFileId;
    equipment.inventory.thumbnailLink = equipmentPhotos.inventory.thumbnailLink;
    equipment.inventory.photoLocalBlob = null;
    equipment.inventory.photoLocalBlobDirty = false;

    if (equipment.anomalies.security !== undefined) {
      equipment.anomalies.security.photoFileId =
        equipmentPhotos.security.photoFileId;
      equipment.anomalies.security.thumbnailLink =
        equipmentPhotos.security.thumbnailLink;
        equipment.anomalies.security.photoLocalBlob = null;
        equipment.anomalies.security.photoLocalBlobDirty = false;
    }

    if (equipment.anomalies.maintenability !== undefined) {
      equipment.anomalies.maintenability.photoFileId =
        equipmentPhotos.maintenability.photoFileId;
      equipment.anomalies.maintenability.thumbnailLink =
        equipmentPhotos.maintenability.thumbnailLink;
        equipment.anomalies.maintenability.photoLocalBlob = null;
        equipment.anomalies.maintenability.photoLocalBlobDirty = false;
    }

    if (equipment.anomalies.another !== undefined) {
      equipment.anomalies.another.photoFileId = equipmentPhotos.another.photoFileId;
      equipment.anomalies.another.thumbnailLink =
        equipmentPhotos.another.thumbnailLink;
        equipment.anomalies.another.photoLocalBlob = null;
        equipment.anomalies.another.photoLocalBlobDirty = false;
    }
  });

  return installationData;
};

const getInstallationPhotos = (installation) => {

  const photoTypeEntries = Object.entries(photoTypes);

  return Object.entries(installation.equipments)
  .reduce((photoList, [id, equipment]) => {

    photoTypeEntries.forEach(([type, getter]) => {

      const subObj = getter(equipment);

      if (subObj) {
        photoList.push({
          equipmentId: id,
          type,
          getter,
          photoName: subObj.photoName,
          photoLocalBlob: subObj.photoLocalBlob,
          photoLocalBlobDirty: subObj.photoLocalBlobDirty,
        });
      }
    });
    
    return photoList;
  }, []);
};

export const saveInstallationData = async installation => {
  const installationSpreadsheetIds = await getInstallationSpreadsheetIds(installation.DIcode);

  if (Object.values(installationSpreadsheetIds).filter(id => !id).length > 0) {
    return null;
  }

  // Uploading photos

  let equipmentPhotoUpdates = getInstallationPhotos(installation).filter(
    ({ photoLocalBlob, photoLocalBlobDirty }) =>
      photoLocalBlob && photoLocalBlobDirty &&
      (photoLocalBlob.startsWith("blob:") ||
        photoLocalBlob.startsWith("http://localhost/_capacitor_file_/"))
  );

  if (equipmentPhotoUpdates.length > 0) {
    equipmentPhotoUpdates = await uploadInstallationImages(installation.DIcode, equipmentPhotoUpdates);

    equipmentPhotoUpdates.forEach(({equipmentId, getter, photoName}) => {
      getter(installation.equipments[equipmentId]).photoName = photoName;
      getter(installation.equipments[equipmentId]).photoLocalBlobDirty = false;
    });
  }

  // Writing spreadsheets

  await Promise.all(
    installationsSpreadsheets
      .filter(({ readonly }) => !readonly) // Discard writing readonly spreadsheets like GMAO
      .map(async ({ name, key, mapper, sheetName, preProcess }) => {
        let data = installation[key];

        if (preProcess) {
          data = preProcess(data);
        }

        data = Object.entries(data);

        await writeSpreadsheet(
          installationSpreadsheetIds[name],
          data,
          data.length > 0
            ? mapStoreToSheetCreator(mapper)
            : sheetColumnsCreator(mapper),
          sheetName
        );

        // console.log(Object.entries(installation[key]).map(mapStoreToSheetCreator(mapper)));
      })
  );

  const { updater } = await findSpreadsheetLineId(
    installationsSpreadsheetId,
    "DIcode",
    installation.DIcode,
    mapSheetToStoreCreator(installationMapping)
  );

  await updater(installation, mapStoreToSheetCreator(installationMapping));

  return equipmentPhotoUpdates;
};

// export const getImageLink = async thumbnailLink => {
//   console.log(thumbnailLink);
//
//   const blob = await fetchFile(thumbnailLink);
//
//   console.log(blob);
//
//   return URL.createObjectURL(blob);
// };