import iEquipmentList from "../models/equipmentList.module";
import iListInstallation from "../models/installationList.module";

const test: iListInstallation = {
  "D123ABC-01": {
    createDate: '10/12/2019',
    name: 'Ecole élémentaire Le BouscatEcole élémentaire Le BouscatEcole élémentaire Le Bouscat',
    Dcode: '12345',
    DIcode: "D123ABC-01",
    zone: 'zone',
    unit: 'abcdef',
    lastUpdate: new Date(),
    technicianNameLastUpdate: 'Henri',
    technicianNameCreate: 'Paul',
    address: 'Rue Colonel Grandier Vazeille, Bordeaux, 33',
    customerName: 'Moulin',
    P1: true,
    P2: false,
    P3: true,
    contractNumber: '154684',
    endorsementNumber: '456987',
    effectiveDate: new Date(),
    urlLogo: "https://lycee-condorcet-arcachon.fr/wp-content/uploads/2017/05/acad%C3%A9mie-de-bdx-1.jpg",
    GMAO: 
    {
      "APMA / appareil de mesures" : {
        elementarySystem : "CA / CVC REFROIDISSEMENT GRP FROID",
        family : "Appareil de mesure",
        typeOfTechnology : "APMA / appareil de mesures",
        FIP : "IDF"
      },
      "AELA / Armoire electrique" : {
        elementarySystem : "CA / CVC REFROIDISSEMENT GRP FROID",
        family : "Armoire electrique",
        typeOfTechnology : "AELA / Armoire electrique",
        FIP : "IDF"
      },
      "CLGA / Calorifuge" : {
        elementarySystem : "CA / CVC REFROIDISSEMENT GRP FROID",
        family : "Calorifuge",
        typeOfTechnology : "CLGA / Calorifuge",
        FIP : "IDF"
      },
    },
    inventoryCold: {},
    inventoryHot: {},
    equipments: (() => Array.from(Array(35).keys()).reduce((obj, key) => {
      obj[key] = {
        isNew: false,
        inventory:
          {
            idReference: '',
            DCode: '12345',
            PCode: '',
            DIcode: "D123ABC-01",
            FRTcode: "745896",
            DIcodeAssociation: false,
            place: "Local Chaufferie",
            building: "white",
            stage: "2",
            room: "Chaufferie",
            GMAO: "uy78",
            absentRepository: true,
            p3: true,
            designation: "Armoire electrique",
            status: "HORS SERVICE",
            health: "",
            initialQuantity: 1,
            notedQuantity: 2,
            elementarySystem: "CA / CVC REFROIDISSEMENT GRP FROID",
            family: "Armoire electrique",
            typeOfTechnology: "APMA / appareil de mesures",
            FIP: "IDF",
            brand: "Atlantic",
            model: "M008745",
            reference: "dfjhdl",
            serialNumber: "18456987456",
            IDnumber: "85",
            dateOfCommissioning: new Date(),
            photoName: '',
            photoLocalBlob: null,
            photoLocalBlobDirty: false,
            photoFileId: null,
            thumbnailLink: null,
          },
        anomalies:
          {
            maintenability: {
              criticality: 'Défaut mineur',
              reason: 'Accès difficile',
              observation: 'Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.',
              PV: true,
              photoName: '',
              photoLocalBlob: null,
              photoLocalBlobDirty: false,
              photoFileId: null,
              thumbnailLink: null,
            }
          }
      }
      return obj;
    }, {} as iEquipmentList))(),
      /*{
        45: {
          inventory:
            {
              idReference: '',
              DCode: '12345',
              PCode: '',
              DIcode: "D123ABC-01",
              FRTcode: "745896",
              DIcodeAssociation: false,
              place: "Local Chaufferie",
              building: "white",
              stage: "2",
              room: "Chaufferie",
              GMAO: "uy78",
              absentRepository: "56489687",
              p3: true,
              designation: "chaudière",
              status: "HORS SERVICE",
              initialQuantity: 1,
              notedQuantity: 2,
              elementarySystem: "hdqhgmo",
              family: "fhgqh",
              typeOfTechnology: "COMPTEUR",
              FIP: "ghhbdfbfb",
              brand: "Atlantic",
              model: "M008745",
              reference: "dfjhdl",
              serialNumber: "18456987456",
              IDnumber: "85",
              dateOfCommissioning: "2020",
              photoName: "IMG_874596"
            },
          anomalies:
            {

            }
        },
        56: {
          inventory:
            {
              idReference: '',
              DCode: '12345',
              PCode: '852147',
              DIcode: "D123ABC-01",
              FRTcode: "745896",
              DIcodeAssociation: false,
              place: "Local Chaufferie",
              building: "white",
              stage: "2",
              room: "Local électrique",
              GMAO: "uy78",
              absentRepository: "56489687",
              p3: true,
              designation: "armoire electrique",
              status: "NON TESTE",
              initialQuantity: 1,
              notedQuantity: 1,
              elementarySystem: "hdqhgmo",
              family: "fhgqh",
              typeOfTechnology: "INTERRUPTEUR",
              FIP: "ghhbdfbfb",
              brand: "Schneider Electric",
              model: "093056BZ",
              reference: "dfjhdl",
              serialNumber: "18456987456",
              IDnumber: "85",
              dateOfCommissioning: "2015",
              photoName: "IMG_874596"
            },
          anomalies:
            {

            }
        },
        65: {
          inventory:
            {
              idReference: '',
              DCode: '12345',
              PCode: '852147',
              DIcode: "D123ABC-01",
              FRTcode: "745896",
              DIcodeAssociation: false,
              place: "Local Chaufferie",
              building: "white",
              stage: "2",
              room: "Local électrique",
              GMAO: "uy78",
              absentRepository: "56489687",
              p3: true,
              designation: "compteur electrique",
              status: "ARRET",
              initialQuantity: 1,
              notedQuantity: 3,
              elementarySystem: "hdqhgmo",
              family: "fhgqh",
              typeOfTechnology: "ARMOIRE DE MESURE",
              FIP: "ghhbdfbfb",
              brand: "Legrand",
              model: "z0125rt",
              reference: "dfjhdl",
              serialNumber: "18456987456",
              IDnumber: "85",
              dateOfCommissioning: "2014",
              photoName: "IMG_874596"
            },
          anomalies:
            {
              security: {
                criticality: 'Défaut mineur',
                reason: 'Voir commentaire ci-dessous',
                observation: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. ',
                PV: 'string',
                photoName: 'string'
              },
            }
        },
        75: {
          inventory:
            {
              idReference: '',
              DCode: '12345',
              PCode: '852147',
              DIcode: "D123ABC-01",
              FRTcode: "745896",
              DIcodeAssociation: false,
              place: "Local Chaufferie",
              building: "white",
              stage: "2",
              room: "Local technique",
              GMAO: "uy78",
              absentRepository: "56489687",
              p3: true,
              designation: "chauffe-eau",
              status: "ARRET",
              initialQuantity: 1,
              notedQuantity: 4,
              elementarySystem: "hdqhgmo",
              family: "fhgqh",
              typeOfTechnology: "ARMOIRE DE MESURE",
              FIP: "ghhbdfbfb",
              brand: "Cumulus",
              model: "AB147258",
              reference: "dfjhdl",
              serialNumber: "18456987456",
              IDnumber: "85",
              dateOfCommissioning: "2017",
              photoName: "IMG_874596"
            },
          anomalies:
            {
              another: {
                criticality: 'Défaut majeur',
                reason: 'Duis aute irure dolor in reprehenderit',
                observation: 'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
                PV: 'string',
                photoName: 'string'
              }
            }
        },
      },*/
    documentList:
      {
        "Plan de l'installation": {
          delivered: true,
          upToDate: false,
          noObject: false
        },
        "Notice de conduite": {
          delivered: true,
          upToDate: true,
          noObject: false
        },
        "Livret de maintenance": {
          delivered: false,
          upToDate: false,
          noObject: true
        }
      },
    meterList: {
      0: {
        type: 'GAZ',
        value: 4321,
        name: '',
      },
      1: {
        type: 'AUTRE',
        value: 5,
        name: 'compteur d\'histoires',
      },
    },
    accessList: {
      1: {
        type: 'key',
        name: 'Chaufferie',
        number: 1325,
        quantity: 2,
      },
      2: {
        type: 'key',
        name: 'Portail',
        number: 4474,
        quantity: 1,
      },
      3: {
        type: 'badge',
        name: 'Maintenance',
        number: 74321170,
        quantity: 1,
      },
      4: {
        type: 'code',
        name: 'Parking',
        identifier: 'user',
        password: '14*7A',
      },
      5: {
        type: 'other',
        name: 'Nacelle déployable au garage',
      },
    },
  },
  "D123ABC-03": {
    createDate: '26/12/2019',
    name: 'Collège Cheverus',
    Dcode: '78945',
    zone: 'zone',
    unit: 'abcdef',
    DIcode: "D123ABC-03",
    lastUpdate: new Date(),
    technicianNameLastUpdate: 'Henri',
    technicianNameCreate: 'Paul',
    address: 'Place de la Comédie, Bordeaux, 33',
    customerName: 'Durand',
    P1: false,
    P2: false,
    P3: true,
    contractNumber: '987456',
    endorsementNumber: '456987',
    effectiveDate: new Date(),
    urlLogo: "https://lycee-condorcet-arcachon.fr/wp-content/uploads/2017/05/acad%C3%A9mie-de-bdx-1.jpg",
    GMAO: 
    {
      "015" : {
        elementarySystem : "CA / CVC REFROIDISSEMENT GRP FROID",
        family : "Appareil de mesure",
        typeOfTechnology : "APMA / appareil de mesures",
        FIP : "IDF"
      },
      "13254" : {
        elementarySystem : "CA / CVC REFROIDISSEMENT GRP FROID",
        family : "Armoire electrique",
        typeOfTechnology : "AELA / Armoire electrique",
        FIP : "IDF"
      },
      "2456768" : {
        elementarySystem : "CA / CVC REFROIDISSEMENT GRP FROID",
        family : "Calorifuge",
        typeOfTechnology : "CLGA / Calorifuge",
        FIP : "IDF"
      },
    },
    inventoryCold: {},
    inventoryHot: {},
    equipments:
      {
        55: {
          isNew: false,
          inventory:
            {
              idReference: '',
              DCode: '12345',
              PCode: '852147',
              DIcode: "D123ABC-01",
              FRTcode: "745896",
              DIcodeAssociation: false,
              place: "Local Chaufferie",
              building: "white",
              stage: "2",
              room: "Borne gaz à l'entrée",
              GMAO: "uy78",
              absentRepository: true,
              p3: true,
              designation: "compteur gaz",
              status: "ARRET",
              health: "",
              initialQuantity: 1,
              notedQuantity: 1,
              elementarySystem: "hdqhgmo",
              family: "fhgqh",
              typeOfTechnology: "CIRCULATEUR",
              FIP: "ghhbdfbfb",
              brand: "Actaris",
              model: "GALIS7964",
              reference: "dfjhdl",
              serialNumber: "18456987456",
              IDnumber: "85",
              dateOfCommissioning: new Date(),
              photoName: '',
              photoLocalBlob: null,
              photoLocalBlobDirty: false,
              photoFileId: null,
              thumbnailLink: null,
            },
          anomalies: {
            maintenability: {
              criticality: 'Défaut mineur',
              reason: 'Accès difficile',
              observation: 'Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.',
              PV: true,
              photoName: '',
              photoLocalBlob: null,
              photoLocalBlobDirty: false,
              photoFileId: null,
              thumbnailLink: null,
            }
          }
        }
      },
    documentList:
      {
        "Schéma de l'installation": {
          delivered: false,
          upToDate: false,
          noObject: true
        }
      },
    meterList: {
      0: {
        type: 'GAZ',
        value: 4321,
        name: '',
      },
      1: {
        type: 'AUTRE',
        value: 5,
        name: 'compteur d\'histoires',
      },
    },
    accessList: {},
  },
  "D95148Z-06": {
    createDate: '01/12/2019',
    name: 'Ecole maternelle Paul Bert',
    Dcode: '852147',
    zone: 'zone',
    unit: 'abcdef',
    DIcode: "D95148Z-06",
    lastUpdate: new Date(),
    technicianNameLastUpdate: 'Henri',
    technicianNameCreate: 'Paul',
    address: 'Place de la Bourse, Bordeaux, 33',
    customerName: 'Martin',
    P1: true,
    P2: true,
    P3: true,
    contractNumber: '369852',
    endorsementNumber: '456987',
    effectiveDate: new Date(),
    urlLogo: "https://lycee-condorcet-arcachon.fr/wp-content/uploads/2017/05/acad%C3%A9mie-de-bdx-1.jpg",
    GMAO: 
    {
      "015" : {
        elementarySystem : "CA / CVC REFROIDISSEMENT GRP FROID",
        family : "Appareil de mesure",
        typeOfTechnology : "APMA / appareil de mesures",
        FIP : "IDF"
      },
      "13254" : {
        elementarySystem : "CA / CVC REFROIDISSEMENT GRP FROID",
        family : "Armoire electrique",
        typeOfTechnology : "AELA / Armoire electrique",
        FIP : "IDF"
      },
      "2456768" : {
        elementarySystem : "CA / CVC REFROIDISSEMENT GRP FROID",
        family : "Calorifuge",
        typeOfTechnology : "CLGA / Calorifuge",
        FIP : "IDF"
      },
    },
    inventoryCold: {},
    inventoryHot: {},
    equipments:
      {
        11: {
          isNew: false,
          inventory:
            {
              idReference: '',
              DCode: '12345',
              PCode: '852147',
              DIcode: "D123ABC-01",
              FRTcode: "745896",
              DIcodeAssociation: false,
              place: "Local Chaufferie",
              building: "white",
              stage: "2",
              room: "Arrière cours",
              GMAO: "uy78",
              absentRepository: true,
              p3: true,
              designation: "Compteur fioul",
              status: "En Service",
              health: "",
              initialQuantity: 1,
              notedQuantity: 1,
              elementarySystem: "hdqhgmo",
              family: "fhgqh",
              typeOfTechnology: "DEMARREUR",
              FIP: "ghhbdfbfb",
              brand: "Braun",
              model: "TT8520",
              reference: "dfjhdl",
              serialNumber: "18456987456",
              IDnumber: "85",
              dateOfCommissioning: new Date(),
              photoName: '',
              photoLocalBlob: null,
              photoLocalBlobDirty: false,
              photoFileId: null,
              thumbnailLink: null,
            },
          anomalies: {
            security: {
              criticality: 'Défaut mineur',
              reason: 'Voir commentaire ci-dessous',
              observation: 'Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
              PV: true,
              photoName: '',
              photoLocalBlob: null,
              photoLocalBlobDirty: false,
              photoFileId: null,
              thumbnailLink: null,
            },
            maintenability: {
              criticality: 'Défaut moyen',
              reason: 'Accès difficile',
              observation: 'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.',
              PV: true,
              photoName: '',
              photoLocalBlob: null,
              photoLocalBlobDirty: false,
              photoFileId: null,
              thumbnailLink: null,
            },
            another: {
              criticality: 'Défaut majeur',
              reason: 'Ut enim ad minima veniam',
              observation: ' Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur',
              PV: true,
              photoName: '',
              photoLocalBlob: null,
              photoLocalBlobDirty: false,
              photoFileId: null,
              thumbnailLink: null,
            }
          }
        },
        12: {
          isNew: false,
          inventory:
            {
              idReference: '',
              DCode: '12345',
              PCode: '852147',
              DIcode: "D123ABC-01",
              FRTcode: "745896",
              DIcodeAssociation: false,
              place: "Local Chaufferie",
              building: "white",
              stage: "2",
              room: "Chaufferie",
              GMAO: "uy78",
              absentRepository: true,
              p3: true,
              designation: "Poêle à pétrole",
              status: "ARRET",
              health: "",
              initialQuantity: 1,
              notedQuantity: 5,
              elementarySystem: "hdqhgmo",
              family: "fhgqh",
              typeOfTechnology: "COMPTEUR",
              FIP: "ghhbdfbfb",
              brand: "Tectro",
              model: "RC78651",
              reference: "dfjhdl",
              serialNumber: "18456987456",
              IDnumber: "85",
              dateOfCommissioning: new Date(),
              photoName: '',
              photoLocalBlob: null,
              photoLocalBlobDirty: false,
              photoFileId: null,
              thumbnailLink: null,
            },
          anomalies:
            {
              security: {
                criticality: 'Défaut mineur',
                reason: 'Voir commentaire ci-dessous',
                observation: 'At vero eos et accusamus et iusto odio dignissimos ducimus',
                PV: true,
                photoName: '',
                photoLocalBlob: null,
                photoLocalBlobDirty: false,
                photoFileId: null,
                thumbnailLink: null,
              }
            }
        },
        13: {
          isNew: false,
          inventory:
            {
              idReference: '',
              DCode: '12345',
              PCode: '852147',
              DIcode: "D123ABC-01",
              FRTcode: "745896",
              DIcodeAssociation: false,
              place: "Local Chaufferie",
              building: "white",
              stage: "2",
              room: "Salle de classe",
              GMAO: "uy78",
              absentRepository: true,
              p3: true,
              designation: "Radiateur",
              status: "ARRET",
              health: "",
              initialQuantity: 1,
              notedQuantity: 6,
              elementarySystem: "hdqhgmo",
              family: "fhgqh",
              typeOfTechnology: "FILTRE",
              FIP: "ghhbdfbfb",
              brand: "Thermor",
              model: "P874",
              reference: "dfjhdl",
              serialNumber: "18456987456",
              IDnumber: "85",
              dateOfCommissioning: new Date(),
              photoName: '',
              photoLocalBlob: null,
              photoLocalBlobDirty: false,
              photoFileId: null,
              thumbnailLink: null,
            },
          anomalies:
            {

            }
        }
      },
    documentList:
      {
        "Plans et schémas électriques": {
          delivered: false,
          upToDate: true,
          noObject: true
        },
        "Certificat de contrôle": {
          delivered: false,
          upToDate: true,
          noObject: false
        },
      },
    meterList: {
      0: {
        type: 'GAZ',
        value: 4321,
        name: '',
      },
      1: {
        type: 'AUTRE',
        value: 5,
        name: 'compteur d\'histoires',
      },
    },
    accessList: {},
  }
};

module.exports = test;