export const BOOL = "bool";
export const STRICTBOOL = "strictBool";
export const SBOOL = "sbool";
export const XBOOL = "xbool";
export const OuiNonBOOL = "OuiNonbool";
export const NUMBER = "number";
export const NULLABLENUMBER = "nullableNumber";
export const DATE = "date";

const dateRegex = /^(?<day>\d{2})\/(?<month>\d{2})\/(?<year>\d{4})$/;

const sheetToStoreTransforms = {
  [NUMBER]: (value) => Number(value),
  [NULLABLENUMBER]: (value) => (value === "" ? null : Number(value)),
  [BOOL]: (value) => value !== "",
  [STRICTBOOL]: (value) => value === "OUI",
  [SBOOL]: (value) => value !== "",
  [XBOOL]: (value) => value !== "",
  [OuiNonBOOL]: (value) => value === "Oui",
  [DATE]: (value) => {
    if (!value) {
      return null;
    }

    const match = value.match(dateRegex);

    if (!match) {
      return null;
    }

    return new Date(
      Number(match.groups.year),
      Number(match.groups.month) - 1,
      Number(match.groups.day)
    );
  },
};

const storeToSheetTransforms = {
  [NUMBER]: (value) => value,
  [NULLABLENUMBER]: (value) => (value === null ? "" : value),
  [BOOL]: (value) => (value ? "OUI" : ""),
  [STRICTBOOL]: (value) => (value ? "OUI" : "NON"),
  [SBOOL]: (value) => (value ? "S" : ""),
  [XBOOL]: (value) => (value ? "X" : ""),
  [OuiNonBOOL]: (value) => value !== "Oui",
  [DATE]: (date) => {
    if (!date) {
      return "";
    }

    const year = date.getFullYear();
    const month = (1 + date.getMonth()).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");

    return day + "/" + month + "/" + year;
  },
};

export const mapSheetToStoreCreator = (mapping) => (obj) => {
  if (mapping === null) {
    return obj;
  }

  const result = {};

  mapping.forEach(([storeField, sheetField, transform = null]) => {
    if (storeField !== null) {
      let value;

      if (sheetField === null) {
        value = null;
      } else if (typeof sheetField === "function") {
        value = sheetField(obj);
      } else {
        value = obj[sheetField];
      }

      const splittedStoreField = storeField.split(".");

      let ref = result;

      splittedStoreField.forEach((field, index) => {
        if (index === splittedStoreField.length - 1) {
          ref[field] = transform
            ? sheetToStoreTransforms[transform](value)
            : value;
        } else if (ref[field] === undefined) {
          ref[field] = {};
        }
        ref = ref[field];
      });
    }
  });

  return result;
};

export const mapStoreToSheetCreator = (mapping) => ([key, obj]) => {
  if (mapping === null) {
    return obj;
  }

  const result = {};

  mapping.forEach(([storeField, sheetField, transform = null]) => {
    if (sheetField !== null && typeof sheetField !== "function") {
      let value;

      if (storeField === null) {
        value = null;
      } else if (storeField === "$index") {
        value = key;
      } else {
        const splittedStoreField = storeField.split(".");

        let ref = obj;

        // console.log(splittedStoreField);
        splittedStoreField.forEach((field) => {
          // console.log(ref);
          if (ref !== undefined) {
            ref = ref[field];
          }
        });
        // console.log(ref);

        value =
          ref === undefined
            ? ""
            : transform
            ? storeToSheetTransforms[transform](ref)
            : ref;
      }

      result[sheetField] = value;
    }
  });

  return result;
};

export const sheetColumnsCreator = mapping => {
  if (mapping === null) {
    return [];
  }

  return mapping
    .map(([storeField, sheetField]) => sheetField)
    .filter(sheetField => sheetField !== null && (typeof sheetField !== 'function'));
};