import store from '../store/store';
import { withPatchFilter, createMatcher } from '../store/pure-store';
import * as gapiController from './gapiController';
import { cloneDeep } from 'lodash';
import iRegionList from '../models/iRegionList.module';

const USEFAKEDATA = false;

let gapiInitializationPromise: Promise<any> | null = null;
const ensureGapiInitialized = async () => {
  if (gapiInitializationPromise === null) {
    gapiInitializationPromise = gapiController.init();
  }

  await gapiInitializationPromise;
};

const getMemoizedInstallations = () => {
  const memoizedInstallations = localStorage.getItem('dalkia.memoizedInstallations');

  if (memoizedInstallations) {
    return JSON.parse(memoizedInstallations);
  }

  return [];
};

const setMemoizedInstallations = memoizedInstallations => {
  localStorage.setItem('dalkia.memoizedInstallations', JSON.stringify(memoizedInstallations));
};

const getSelectedRegion = () => {
  return localStorage.getItem('dalkia.selectedRegion');
};

const setSelectedRegion = (regionId) => {
  if (regionId !== null) {
    localStorage.setItem('dalkia.selectedRegion', regionId);
  }
  else {
    localStorage.removeItem('dalkia.selectedRegion');
  }
};

const installationService = (rootFolderId) => {
  const subscriptions = [
    withPatchFilter({
      path: ['currentInstallation']
    }, patches => {
      if (patches.filter(({ path }) => path.length > 1).length > 0) {
        store.update(state => state.currentInstallationState = 'dirty');
      }
    }),
    withPatchFilter({
      path: ['memoizedInstallations']
    }, () => {
      // @ts-ignore
      setMemoizedInstallations(store.getState().memoizedInstallations);
    }),
    /*withPatchFilter({
      path: ['currentInstallationState'],
    }, patches => {
      // @ts-ignore
      console.log('CATCH', patches);
    }),*/
    async patches => {

      const installationSaved = patches.filter(createMatcher({
        path: ['currentInstallationState'],
        value: 'saving',
      })).length === 1;

      if (installationSaved) {
        // @ts-ignore
        const installations = store.getState().installationList;
        // @ts-ignore
        const installationId = store.getState().currentInstallation.DIcode;

        console.log('Saving installation', installations[installationId]);

        try {
          const newPhotos = await gapiController.saveInstallationData(cloneDeep(installations[installationId]));

          store.update(state => {
            // @ts-ignore
            newPhotos.forEach(({equipmentId, getter, photoName}) => {
              getter(state.currentInstallation.equipments[equipmentId]).photoName = photoName;
              getter(state.installationList[installationId].equipments[equipmentId]).photoName = photoName;
              
              getter(state.currentInstallation.equipments[equipmentId]).photoLocalBlobDirty = false;
              getter(state.installationList[installationId].equipments[equipmentId]).photoLocalBlobDirty = false;
            });
          });

          store.update(state => {
            state.currentInstallationState = 'saved';
          });
        }
        catch  (err) {
          console.error(err);

          store.update(state => {
            state.currentInstallationState = 'dirty';
            state.installationSaveState = 'error';
            state.installationSaveError = err.message || err.body;
          });
        }
      }
    },
  ];

  // eslint-disable-next-line
  const loadData = async () => {
    await ensureGapiInitialized();

    const data = await gapiController.loadInstallationsStructureData(rootFolderId);

    return data;
  };

  // eslint-disable-next-line
  const loadFakeData = () => new Promise(async (resolve, reject) => {
    setTimeout(() => {
      resolve(require('./fakeData'));
    }, 1);
  });

  const init = async () => {
    try {
      const data = await (USEFAKEDATA ? loadFakeData : loadData)();

      store.update(state => {
        state.installationList = data;
        state.memoizedInstallations = getMemoizedInstallations();
        state.installationsReady = true;
      });

      const unsubscribers = subscriptions.map(store.subscribe);

      return () => {
        unsubscribers.forEach(unsubscribe => unsubscribe());
      }
    }
    catch (err) {
      console.error(JSON.stringify(err));
      console.error(err.message);
      console.error(err);
      
      return () => {};
    }
  };


  return init();
};

const initService = () => {
  store.subscribe((patches, inversePatches) => {
    console.log('State diff detected', {
      state: store.getState(),
      patches,
      inversePatches,
    });
  });

  store.subscribe(
    withPatchFilter({
      path: ['selectedRegion']
    }, (patches, inversePatches) => {
      const state = store.getState();
      // @ts-ignore
      const regions = state.regions;
      // @ts-ignore
      const selectedRegion = state.selectedRegion;
      setSelectedRegion(selectedRegion);

      store.update(state => {
        state.installationsReady = false;
        state.installationList = {};
        state.currentInstallation = null;
        state.currentInstallationState = 'saved';
        state.installationSaveState = 'ok';
        state.installationSaveError = null;
        state.memoizedInstallations = null;
      });

      // if not initialization but we come from another region
      if (inversePatches[0].value !== null) {
        setMemoizedInstallations([]);
      }

      if (regions[selectedRegion] !== undefined) {
        installationService(regions[selectedRegion].folderId);
      }
      else if (selectedRegion !== null) {
        store.update(state => {
          state.selectedRegion = null;
        });
      }
    })
  );

  // eslint-disable-next-line
  const loadData = async () => {
    await ensureGapiInitialized();

    const regions = await gapiController.loadStructureData();
    const selectedRegion = getSelectedRegion();

    return {
      regions,
      selectedRegion,
    };
  };

  // eslint-disable-next-line
  const loadFakeData = () => new Promise<{ regions: iRegionList, selectedRegion: string|null }>(async (resolve, reject) => {
    setTimeout(() => {
      const regions = require('./fakeRegionsData');
      const selectedRegion = Object.keys(regions)[0];

      resolve({
        regions,
        selectedRegion,
      });
    }, 1);
  });

  const init = async () => {

    try {
      const data = await (USEFAKEDATA ? loadFakeData : loadData)();

      store.update(state => {
        state.regionsReady = true;
        state.regions = data.regions;
        state.selectedRegion = data.selectedRegion;
      });
    }
    catch (err) {
      console.error(JSON.stringify(err));
      console.error(err.message);
      console.error(err);
    }
  };

  init();
};

export default initService;


export const populateInstallation = async id => {
  if (!USEFAKEDATA) {
    const installationData = await gapiController.loadInstallationData(id);

    store.update(state => {
      const installation = state.installationList[id];
      state.installationList[id] = {...installation, ...installationData};
    });
  }
};