import config from '../config/google';
import { Capacitor } from '@capacitor/core';
import { GoogleAuth } from '@codetrix-studio/capacitor-google-auth';

let userInfo = null;
let refreshAuth = null;

const resolveAfter = (ms) => new Promise((ok) => setTimeout(ok, ms));

const coolDownOnFailure = async (fn) => {
  try {
    const result = await fn();
    return result;
  }
  catch (err) {
    if (err.status === 429 || err.status === 403) {
      console.log("Gapi cooldown");
      await resolveAfter(60 * 1000);
      return coolDownOnFailure(fn);
    } else if (err.status === 401) {
      console.log("Gapi refresh");
      await refreshAuth();
      return coolDownOnFailure(fn);
    } else {
      throw err;
    }
  }
};

export const indexArray = (array, getter, removeFields=[]) => array.reduce((mapped, element, index) => {
  const key = getter(element, index);
  removeFields.forEach(key => delete element[key]);
  mapped[key] = element;
  return mapped;
}, {});

export const createFolder = async (folderId, filename) => {
  const response = await coolDownOnFailure(() => window.gapi.client.drive.files.create({
    fields: 'id',
    resource: {
      name: filename,
      parents: [folderId],
      mimeType: 'application/vnd.google-apps.folder',
    },
  }));

  return response.result.id;
};

export const copyFile = async (fileId, folderId, newName=null) => {
  const response = await coolDownOnFailure(() => window.gapi.client.drive.files.copy({
    fields: 'id',
    fileId,
    resource: {
      ...(newName ? { name: newName } : {}),
      parents: [folderId],
    },
  }));

  return response.result.id;
};

export const createSpreadsheet = async (folderId, filename, sheetNames=[]) => {
  const response = await coolDownOnFailure(() => window.gapi.client.drive.files.create({
    fields: "id",
    resource: {
      name: filename,
      parents: [folderId],
      mimeType: "application/vnd.google-apps.spreadsheet",
    },
  }));

  const spreadsheetId = response.result.id;

  await Promise.all(
    
    sheetNames.filter(sheetName => sheetName !== 'Feuille 1').map(async (sheetName) => {
        await coolDownOnFailure(() => window.gapi.client.sheets.spreadsheets.batchUpdate({
          spreadsheetId,
          requests: [
            {
              addSheet: {
                properties: {
                  title: sheetName,
                },
              },
            },
          ],
        }));
      })
  
  );

  return spreadsheetId;
};

export const deleteFile = async fileId => {
  await coolDownOnFailure(() => window.gapi.client.drive.files.delete({
    'fileId': fileId
  }));
};

export const uploadImage = async (folderId, filename, blob) => {
  const metadata = {
    name: filename,
    mimeType: 'image/png',
    parents: [folderId],
  };

  const form = new FormData();

  form.append('metadata', new Blob([JSON.stringify(metadata)], {type: 'application/json'}));

  form.append('file', blob);

  const response = await coolDownOnFailure(() => fetch('https://www.googleapis.com/upload/drive/v3/files?uploadType=multipart', {
    method: 'POST',
    headers: new Headers({'Authorization': 'Bearer ' + window.gapi.auth.getToken().access_token}),
    body: form,
  })).then((res) => {
    return res.json();
  });

  return response.id;
};

export const fetchFile = async (fileId) => {
  const blob = await coolDownOnFailure(() => fetch(
    `https://www.googleapis.com/drive/v2/files/${fileId}?alt=media&source=downloadUrl`,
    {
      method: "GET",
      headers: new Headers({
        Authorization: "Bearer " + window.gapi.auth.getToken().access_token,
      }),
    }
  )).then((res) => {
    return res.blob();
  });

  const dataURI = await new Promise((resolve) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = () => {
      const dataURI = reader.result;
      resolve(dataURI);
    };
  });

  return dataURI;
};

export const loadSpreadsheet = async (spreadsheetId, mapper=o => o, sheetName='Feuille 1') => {
  const response = await coolDownOnFailure(() => window.gapi.client.sheets.spreadsheets.values.get({
    spreadsheetId,
    range: `'${sheetName}'`,
  }));

  const [columns, ...lines] = response.result.values;

  return lines
    .map((line) => {
      const mapped = {};

      columns.forEach((column, index) => {
        mapped[column] = line.length > index ? line[index] : "";
      });

      return mapped;
    })
    .map(mapper);
};

const rangeRegex =
  /^'(?<sheetName>[^']+)'!(?<startColumnId>[A-Z]+)(?<startLineId>[0-9]+):(?<endColumnId>[A-Z]+)(?<endLineId>[0-9]+)$/;

const parseRange = (range) => {
  const groups = range.match(rangeRegex).groups;

  return {
    sheetName: groups.sheetName,
    startColumnId: groups.startColumnId,
    startLineId: Number(groups.startLineId),
    endColumnId: groups.endColumnId,
    endLineId: Number(groups.endLineId),
  };
};

const serializeRange = ({
  sheetName,
  startColumnId,
  startLineId,
  endColumnId,
  endLineId,
}) => {
  return `'${sheetName}'!${startColumnId}${startLineId}:${endColumnId}${endLineId}`;
};

export const findSpreadsheetLineId = async (
  spreadsheetId,
  indexField,
  searchValue,
  mapper = (o) => o,
  sheetName = "Feuille 1"
) => {
  const response = await coolDownOnFailure(() =>
    window.gapi.client.sheets.spreadsheets.values.get({
      spreadsheetId,
      range: `'${sheetName}'`,
    })
  );

  const rangeInfo = parseRange(response.result.range);

  const [columns, ...lines] = response.result.values;

  const values = lines
    .map((line) => {
      const mapped = {};

      columns.forEach((column, index) => {
        mapped[column] = line.length > index ? line[index] : "";
      });

      return mapped;
    })
    .map(mapper);

  let foundIndex = values.findIndex((obj) => {
    return obj[indexField] === searchValue;
  });

  const itemFound = foundIndex !== -1;

  if (foundIndex === -1) {
    foundIndex = values.length;
  }

  const rangeLine = rangeInfo.startLineId + 1 + foundIndex;

  const lineRangeInfo = {
    ...rangeInfo,
    startLineId: rangeLine,
    endLineId: rangeLine,
  };

  return {
    itemFound,
    itemLine: rangeLine,
    rangeInfo: lineRangeInfo,
    updater: createSpreadsheetLineWriter(
      spreadsheetId,
      columns,
      lineRangeInfo,
      indexField
    ),
  };
};

export const createSpreadsheetLineWriter =
  (spreadsheetId, columns, rangeInfo, indexField) =>
  async (item, mapper = ([key, o]) => o) => {
    const mappedItem = mapper([item[indexField], item]);
    const line = columns.map((column) => mappedItem[column]);

    await coolDownOnFailure(() =>
      window.gapi.client.sheets.spreadsheets.values.update({
        spreadsheetId,
        range: serializeRange(rangeInfo),
        valueInputOption: "RAW",
        resource: {
          values: [line],
        },
      })
    );
  };

export const writeSpreadsheet = async (spreadsheetId, data, mapper=([key, o]) => o, sheetName='Feuille 1') => {
  let columns = null;
  let lines = null;

  if (data.length === 0) {
    columns = mapper;
    lines = [];
  } else {
    const mappedData = data.map(mapper);
    columns = mappedData.length > 0 ? Object.keys(mappedData[0]) : [];
    lines = mappedData.map((line) => columns.map((column) => line[column]));
  }

  await coolDownOnFailure(() =>
    window.gapi.client.sheets.spreadsheets.values.update({
      spreadsheetId,
      range: `'${sheetName}'`,
      valueInputOption: "RAW",
      resource: {
        values: [columns, ...lines],
      },
    })
  );

  await coolDownOnFailure(() => window.gapi.client.sheets.spreadsheets.values.clear({
    spreadsheetId,
    range: serializeRange({
      sheetName,
      startColumnId: 'A',
      endColumnId: 'ZZ',
      startLineId: lines.length + 2,
      endLineId: 9999,
    }),
  }));
};

const listDriveFilesChunk = async (folderId, conditions, nextPageToken = undefined) => {
  const response = await coolDownOnFailure(() => window.gapi.client.drive.files.list({
    pageToken: nextPageToken,
    q: [
      `'${folderId}' in parents`,
      'trashed = false',
      ...conditions
    ].join(' and '),
    fields: 'nextPageToken, files(contentHints/thumbnail,fileExtension,iconLink,id,name,size,thumbnailLink,webContentLink,webViewLink,mimeType,parents)',
  }));

  return response;
};

export const listDriveFiles = async (folderId, conditions=[]) => {
  let response = await listDriveFilesChunk(folderId, conditions);
  const data = response.result.files;

  while (response.result.nextPageToken !== undefined) {
    response = await listDriveFilesChunk(folderId, conditions, response.result.nextPageToken);
    data.push(...response.result.files);
  }

  return data;
};

export const getIndexedDriveFileIds = async (folderId, indexGetter, indexes, conditions=[]) => {
  const fileList = await listDriveFiles(folderId, []);

  const indexedFiles = indexArray(fileList, indexGetter);

  const fileIds = {};

  indexes.forEach(index => {
    fileIds[index] = indexedFiles[index] !== undefined ? indexedFiles[index].id : null;
  });

  return fileIds;
};

export const getUserInfo = () => userInfo;


const loadApis = apiIds => Promise.all(apiIds.map(apiId => new Promise(resolve => window.gapi.load(apiId, resolve))));

// eslint-disable-next-line
const initApiKey = async () => {
  try {
    await loadApis(['client']);

    await window.gapi.client.init({
      apiKey: config.apiKey,
      discoveryDocs: config.discoveryDocs,
    });
  }
  catch (err) {
    console.log(err);
    throw err;
  }
};

const injectGapi = () => new Promise(resolve => {
  const head = document.getElementsByTagName('head')[0];
  const script = document.createElement('script');
  script.type = 'text/javascript';
  script.defer = true;
  script.async = true;
  script.onload = resolve;
  script.src = 'https://apis.google.com/js/api.js';
  head.appendChild(script);
});

const initOAuth = async () => {
  try {
    if (Capacitor.getPlatform() === 'web') {
      const getUserFrom = googleUser => {
        const user = {};
        const profile = googleUser.getBasicProfile();
        user.email = profile.getEmail();
        user.familyName = profile.getFamilyName();
        user.givenName = profile.getGivenName();
        user.id = profile.getId();
        user.imageUrl = profile.getImageUrl();
        user.name = profile.getName();
        return user;
    }
      
      await injectGapi();

      await loadApis(['client', 'auth2']);

      const auth = await window.gapi.auth2.init({
        client_id: config.clientId,
        fetch_basic_profile: true,
        scope: config.scopes.join(' '),
      });

      await auth.signIn();

      await window.gapi.client.init({
        auth,
        discoveryDocs: config.discoveryDocs,
      });

      const user = getUserFrom(await auth.currentUser.get());

      userInfo = user;

      refreshAuth = () => {
        throw new Error(
          "Session has expired, unable to refresh auth in web mode."
        );
      };
    }
    else {
      const BACKEND_URL = document.getElementsByName('BACKEND_URL').length > 0 ?
      document.getElementsByName('BACKEND_URL')[0].content :
      'http://192.168.1.25:8080';

      console.log('BACKEND_URL');
      console.log(BACKEND_URL);

      const signIn = async () => {
        GoogleAuth.initialize();
        const user = await GoogleAuth.signIn()
        console.log('AUTH FINISH');
  
        console.log('Return of SignIn');
        console.log(JSON.stringify(user));
  
        const serverAuth = await fetch(`${BACKEND_URL}/auth-offline`, {
          method: "post",
          mode: "cors",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            serverAuthCode: user.serverAuthCode,
            all: user,
          }),
        });
  
        const serverResponse = await serverAuth.json();
  
        if (serverResponse.status !== 'ok') {
          throw new Error('Server auth not ok');
        }
  
        console.log('SERVER AUTH');
        console.log(JSON.stringify(serverResponse));

        userInfo = user;

        return serverResponse.accessToken;
      };

      const setToken = (token) => {
        window.gapi.client.setToken({ access_token: token });
      };

      const token = await signIn();

      await injectGapi();

      await loadApis(["client"]);

      setToken(token);

      await window.gapi.client.init({
        discoveryDocs: config.discoveryDocs,
      });

      refreshAuth = async () => {
        const token = await signIn();
        setToken(token);
      };
    }

    console.log('Initialized');
  }
  catch (err) {
    console.error(JSON.stringify(err));
    console.log(err.message || err.body);
    console.log(err);
    alert("Erreur d'authentification: " + err.message || err.body);
    throw err;
  }
};

export const init = initOAuth;