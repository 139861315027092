import { listDriveFiles } from "./gapi";
import store from "../store/store";

export const Types = {
  Folder: {
    name: "Dossier",
    mimeType: "application/vnd.google-apps.folder",
  },
  Spreadsheet: {
    name: "Spreadsheet",
    mimeType: "application/vnd.google-apps.spreadsheet",
  },
};

export const getFiles = async (folderId, files) => {
  const fileList = await listDriveFiles(folderId);

  const result = {};

  const fileEntries = Object.entries(files);

  fileList.forEach((file) => {
    fileEntries.forEach(([fileName, type]) => {
      if (file.name === fileName && file.mimeType === type.mimeType) {
        result[fileName] = file.id;
      }
    });
  });

  return result;
};

export const getFile = async (folderId, fileName, type, errorMessage="Introuvable") => {
  const result = await getFiles(folderId, { [fileName]: type });

  if (result[fileName] === undefined) {
    if (errorMessage) {
      throw new Error(`${type.name} ${fileName}: ${errorMessage}`);
    }
    else {
      return null;
    }
  }

  return result[fileName];
};

export const getRegionFolderId = () => {
  const state = store.getState();

  const selectedRegion = state.selectedRegion;

  if (selectedRegion === null) {
    throw new Error("Aucune région sélectionnée");
  }

  const region = state.regions[selectedRegion];

  if (!region) {
    throw new Error("La région sélectionnée est introuvable");
  }

  return region.folderId;
};

export const getExportsFolderId = async (regionFolderId) => {
  const exportFolderId = await getFile(regionFolderId, "Exports", Types.Folder);

  return exportFolderId;
};

export const getInstallationsFolderId = async (regionFolderId) => {
  const exportFolderId = await getFile(
    regionFolderId,
    "Installations",
    Types.Folder
  );

  return exportFolderId;
};

export const getInstallationFolderId = async (
  installationsFolderId,
  installationId
) => {
  const installationFolderId = await getFile(
    installationsFolderId,
    installationId,
    Types.Folder
  );

  return installationFolderId;
};

export const checkDestinationFolder = async (exportFolderId, folderName) => {
  const destinationFolderId = await getFile(
    exportFolderId,
    folderName,
    Types.Folder,
    null
  );

  if (destinationFolderId !== null) {
    throw new Error("Le dossier de destination existe déjà");
  }
};