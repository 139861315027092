import { useEffect, useRef, useState } from "react";
import { Capacitor } from '@capacitor/core';
import { Keyboard } from '@capacitor/keyboard';
import { useHistory, useLocation } from "react-router";

export const useSaveHandler = (handler: () => void) => {
  const saveHandlerRef = useRef(handler);
  saveHandlerRef.current = handler;

  useEffect(() => () => {
    saveHandlerRef.current();
  }, []);
};

export const useKeyboardOpenDetector = () => {
  const [keyboardOpen, setKeyboardOpen] = useState(false);

  useEffect(() => {
    if (Capacitor.getPlatform() !== 'web') {
      const openListenerHandler = Keyboard.addListener('keyboardDidShow', (info) => {
        // Maybe find some trick to use keyboard height
        // console.log('info.keyboardHeight');
        // console.log(info.keyboardHeight);
        setKeyboardOpen(true);
      });
  
      const closedListenerHandler = Keyboard.addListener('keyboardDidHide', () => {
        setKeyboardOpen(false);
      });
  
      return () => {
        openListenerHandler.remove();
        closedListenerHandler.remove();
      };
    }
  }, []);

  return keyboardOpen;
};

export const useKeyboardOpenDetectorClass = () => {
  const keyboardOpen = useKeyboardOpenDetector();

  return `popover-shiftable${keyboardOpen ? ' shifted' : ''}`;
};

export const useLeaveConfirm = (from: string, to: string, block: () => boolean, message: string) => {
  const history = useHistory();
  const historyLocation = useLocation();

  useEffect(() => {
    if (historyLocation.pathname === from) {
      const unblock = history.block(location => {
        if (
          location.pathname === to &&
          block()) {
          return message;
        }
      });
  
      return unblock;
    }
  }, [history, historyLocation.pathname, from, to, block, message]);
};