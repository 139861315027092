import { indexArray, loadSpreadsheet } from "../gapi";
import { mapSheetToStoreCreator } from "../mappers";
import { importInventoryMapping } from "./sheetDataMappings";
import { installationMapping } from "../sheetDataMappings";
import {
  Types,
  getFile,
  getRegionFolderId,
} from "../util";
import { createInstallation, loadInstallations } from "../gapiController";

const getInstallations = async (regionFolderId) => {
  const installationsSpreadsheetId = await getFile(
    regionFolderId,
    "Installations",
    Types.Spreadsheet
  );

  const spreadsheetData = await loadSpreadsheet(
    installationsSpreadsheetId,
    mapSheetToStoreCreator(installationMapping)
  );

  return spreadsheetData;
};

const getImportInstallations = async (
  regionFolderId,
  installationsSpreadsheetName
) => {
  const installationsSpreadsheetId = await getFile(
    regionFolderId,
    installationsSpreadsheetName,
    Types.Spreadsheet
  );

  const spreadsheetData = await loadSpreadsheet(
    installationsSpreadsheetId,
    mapSheetToStoreCreator(installationMapping)
  );

  return spreadsheetData;
};

const getImportEquipments = async (
  regionFolderId,
  equipmentsSpreadsheetName
) => {
  const equipmentsSpreadsheetId = await getFile(
    regionFolderId,
    equipmentsSpreadsheetName,
    Types.Spreadsheet
  );

  const spreadsheetData = await loadSpreadsheet(
    equipmentsSpreadsheetId,
    mapSheetToStoreCreator(importInventoryMapping)
  );

  return spreadsheetData;
};

const groupEquipments = (equipments) => {
  return equipments.reduce((groupedEquipments, equipment) => {
    const index = equipment.inventory.DIcode;

    if (groupedEquipments[index] === undefined) {
      groupedEquipments[index] = [];
    }

    groupedEquipments[index].push(equipment);

    return groupedEquipments;
  }, {});
};

const checkImportInstallations = async (regionFolderId, importInstallations) => {
  const installations = await getInstallations(regionFolderId);

  const usedIds = indexArray(
    installations,
    (installation) => installation.DIcode
  );
  const canBeUploaded = (installation) =>
    usedIds[installation.DIcode] === undefined;

    for (const importInstallation of importInstallations) {
      if (!canBeUploaded(importInstallation)) {
        throw new Error(
          `L'installation ${importInstallation.DIcode} est déjà présente dans la liste d'installations`
        );
      }
    }
};

export const importInstallations = async (
  installationsSpreadsheetName,
  equipmentsSpreadsheetName,
  setState,
) => {
  const regionFolderId = getRegionFolderId();

  setState('Chargement des installations');

  const importInstallations = await getImportInstallations(
    regionFolderId,
    installationsSpreadsheetName
  );

  const nInstallations = importInstallations.length;

  setState('Vérification des installations');

  await checkImportInstallations(regionFolderId, importInstallations);

  setState('Chargement des équipements');

  const importEquipments = await getImportEquipments(
    regionFolderId,
    equipmentsSpreadsheetName
  );

  const groupedImportEquipments = groupEquipments(importEquipments);

  let i = 1;

  for (const installation of importInstallations) {

    setState(`Création de l'installation ${i++}/${nInstallations}`);

    const equipments = groupedImportEquipments[installation.DIcode] || [];

    await createInstallation(installation, {
      equipments: equipments.map((equipment, index) => [index, equipment]),
    });
  }

  setState(`Chargement de la liste des installations`);

  const newInstallationList = await loadInstallations();

  setState(null);

  return newInstallationList;
};
