import React, { useState } from 'react';
import './Anomaly.css';
import { useHistory } from 'react-router';
import { IonPage, IonIcon, IonItem, IonLabel, IonSelectOption, IonSelect, IonTextarea, IonContent, IonRow, IonFabButton, IonGrid, IonCol } from '@ionic/react';
import { camera, alert, arrowBack } from 'ionicons/icons';
import { Camera, CameraResultType } from '@capacitor/camera';
import { withStore } from '../../store/react-pure-store';
import store from '../../store/store';
import iAnomaly from '../../models/anomaly.module';
import { useSaveHandler } from '../../helpers/hooks';
import ImageViewer, { getViewerPhotoProps } from '../component/ImageViewer';

const Anomaly: any = ({ anomaly, match: { params: { anomalyType } }, saveAnomaly, installationName, inventory }: { anomaly: iAnomaly, match: any, saveAnomaly, installationName, inventory }) => {

  const [imageUrl, setImageUrl] = useState<string>();
  const [criticalityValue, setCriticalityValue] = useState(anomaly.criticality);
  const [reasonValue, setReasonValue] = useState(anomaly.reason);
  const [observationValue, setObservationValue] = useState(anomaly.observation);
  const [imageOpen, setImageOpen] = useState(false);
  const history = useHistory();

  useSaveHandler(() => {
    const anomalyUpdates = {
      criticality: criticalityValue,
      reason: reasonValue,
      observation: observationValue,
      PV: criticalityValue !== '',
      photoFileId: imageUrl ? null : anomaly.photoFileId,
      thumbnailLink: imageUrl ? null : anomaly.thumbnailLink,
      photoLocalBlob: imageUrl || anomaly.photoLocalBlob,
      photoLocalBlobDirty: imageUrl ? true : anomaly.photoLocalBlobDirty,
    };
    saveAnomaly(anomalyUpdates)
  });

  const takePicture = async () => {
    try {
      const image = await Camera.getPhoto({
        quality: 90,
        allowEditing: false,
        resultType: CameraResultType.Uri
      });

      // image.webPath will contain a path that can be set as an image src. 
      // You can access the original file using image.path, which can be 
      // passed to the Filesystem API to read the raw data of the image, 
      // if desired (or pass resultType: CameraResultType.Base64 to getPhoto)
      if (image.webPath !== undefined) {
        setImageUrl(image.webPath);
      }
      
      // imageElement.src = imageUrl;
      // camera logo url: https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTDPYM9KWj50OrzBMgTZNI9CGarOoBrULEMIF_LSBa6eF_BgcfM4A&s
    }
    catch (err) {
      console.error(err);
    }
  };

  return (
    <IonPage>

      <IonItem onClick={history.goBack} color='primary' class="ion-no-padding" lines="none">
        <IonGrid>
          <IonRow style={{ height: "60px" }} class="ion-align-items-center">
            <IonCol size="2">
              <IonIcon icon={arrowBack} style={{ fontSize: "18px" }} />
            </IonCol>
            <IonCol size="10">
              <IonLabel style={{ fontSize: "16px" }}>ANOMALIE - {installationName}</IonLabel>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonItem>

      <IonContent color="secondary">
        <IonItem lines="none">
          <IonGrid>
            <IonRow class="ion-justify-content-center" style={{ marginTop: '15px', marginBottom: '5px' }}>
              <IonLabel style={{ color: 'gray' }}>
              {inventory.designation}
                </IonLabel>
            </IonRow>
            <IonRow class="ion-justify-content-center" style={{ fontSize: '13px', color: 'gray', marginBottom: '10px' }}>
              <IonLabel>
              {inventory.room}
                </IonLabel>
            </IonRow>
          </IonGrid>
        </IonItem>

        {anomalyType === 'security' && <>

          <IonItem lines="none" color="secondary">
            <IonGrid class="ion-padding">
              <IonRow class="ion-justify-content-center">
                <IonIcon color="medium" icon={alert} style={{ fontSize: '40px' }} />
              </IonRow>
              <IonRow class="ion-justify-content-center">
                <IonLabel color="medium">ANOMALIES SECURITE</IonLabel>
              </IonRow>
            </IonGrid>
          </IonItem>
        </>
        }
        {anomalyType === 'maintenability' &&
          <>
            <IonItem lines="none" color="secondary">
              <IonGrid class="ion-padding">
                <IonRow class="ion-justify-content-center">
                  <IonIcon color="medium" icon={alert} style={{ fontSize: '40px' }} />
                </IonRow>
                <IonRow class="ion-justify-content-center">
                  <IonLabel color="medium">ANOMALIES MAINTENABILITE</IonLabel>
                </IonRow>
              </IonGrid>
            </IonItem>
          </>
        }
        {anomalyType === 'another' &&
          <>
            <IonItem lines="none" color="secondary">
              <IonGrid class="ion-padding">
                <IonRow class="ion-justify-content-center">
                  <IonIcon color="medium" icon={alert} style={{ fontSize: '40px' }} />
                </IonRow>
                <IonRow class="ion-justify-content-center">
                  <IonLabel color="medium">AUTRE ANOMALIE</IonLabel>
                </IonRow>
              </IonGrid>
            </IonItem>
          </>
        }

        <IonItem >
          <IonLabel color="medium" style={{ fontSize: '13px' }}>
            CRITICITE :
              </IonLabel>
          <IonSelect onIonChange={(e) => setCriticalityValue(e.detail.value || '')} interface="action-sheet" placeholder="RAS" value={criticalityValue} cancel-text="Annuler">
            <IonSelectOption value="">RAS</IonSelectOption>
            <IonSelectOption value="Défaut mineur">Défaut mineur</IonSelectOption>
            <IonSelectOption value="Défaut moyen">Défaut moyen</IonSelectOption>
            <IonSelectOption value="Défaut majeur">Défaut majeur</IonSelectOption>
          </IonSelect>
        </IonItem>

        {anomalyType === 'security' &&
          <>
            <IonItem style={{ marginBottom: "10px" }}>
              <IonLabel color="medium" style={{ fontSize: '13px' }}>
                MOTIF :
              </IonLabel>
              <IonSelect onIonChange={(e) => setReasonValue(e.detail.value || '')} interface="action-sheet" 
              value={reasonValue} style={{ minWidth: '280px', textAlign: "center" }} cancel-text="Annuler">
                <IonSelectOption value="Danger pour les biens">Danger pour les biens</IonSelectOption>
                <IonSelectOption value="Danger pour les personnes">Danger pour les personnes</IonSelectOption>
                <IonSelectOption value="Pas traces des contrôles reglementaires">Pas traces des contrôles reglementaires</IonSelectOption>
                <IonSelectOption value="Accès non sécurisé">Accès non sécurisé</IonSelectOption>
                <IonSelectOption value="Non conforme à la réglementation">Non conforme à la réglementation</IonSelectOption>
                <IonSelectOption value="Voir commentaire ci-dessous">Voir commentaire ci-dessous</IonSelectOption>
              </IonSelect>
            </IonItem>
          </>
        }
        {anomalyType === 'maintenability' &&
          <>
            <IonItem style={{ marginBottom: "10px" }}>
              <IonLabel color="medium" style={{ fontSize: '13px' }}>
                MOTIF :
                </IonLabel>
              <IonSelect onIonChange={(e) => setReasonValue(e.detail.value || '')} interface="action-sheet" value={reasonValue} style={{ minWidth: '280px', textAlign: "center" }} cancel-text="Annuler">
                <IonSelectOption value="Accès difficile">Accès difficile</IonSelectOption>
                <IonSelectOption value="Absence de moyen de manutention">Absence de moyen de manutention</IonSelectOption>
                <IonSelectOption value="Matériel vétuste">Matériel vétuste</IonSelectOption>
                <IonSelectOption value="Péremption pièces détachées">Péremption pièces détachées</IonSelectOption>
                <IonSelectOption value="Absence de redondance (secours)">Absence de redondance (secours)</IonSelectOption>
                <IonSelectOption value="Absence d'historique de maintenance">Absence d'historique de maintenance</IonSelectOption>
                <IonSelectOption value="Voir commentaire ci-dessous">Voir commentaire ci-dessous</IonSelectOption>
              </IonSelect>
            </IonItem>
          </>
        }
        {anomalyType === 'another' &&
          <>
            <IonItem style={{ marginBottom: "10px" }}>
              <IonLabel color="medium" style={{ fontSize: '13px' }}>
                MOTIF :
                </IonLabel>
              <IonSelect onIonChange={(e) => setReasonValue(e.detail.value || '')} interface="action-sheet" value={reasonValue} style={{ minWidth: '280px', textAlign: "center" }} cancel-text="Annuler">
                <IonSelectOption value="Absent de la liste d'appel d'offre">Absent de la liste d'appel d'offre</IonSelectOption>
                <IonSelectOption value="Hors Périmètre Contractuel">Hors Périmètre Contractuel</IonSelectOption>
                <IonSelectOption value="Périmètre à valider">Périmètre à valider</IonSelectOption>
                <IonSelectOption value="Documents manquants ou incomplets">Documents manquants ou incomplets</IonSelectOption>
                <IonSelectOption value="Attente rapport sous traitant">Attente rapport sous traitant</IonSelectOption>
                <IonSelectOption value="Local encombré / matériaux à évacuer">Local encombré / matériaux à évacuer</IonSelectOption>
                <IonSelectOption value="Luminaire H.S. / Absence d'éclairage">Luminaire H.S. / Absence d'éclairage</IonSelectOption>
                <IonSelectOption value="Dépoussiérage / Nettoyage à prévoir">Dépoussiérage / Nettoyage à prévoir</IonSelectOption>
                <IonSelectOption value="Hors service">Hors service</IonSelectOption>
                <IonSelectOption value="A l'arrêt, valider son maintien à l'arrêt">A l'arrêt, valider son maintien à l'arrêt</IonSelectOption>
                <IonSelectOption value="Travaux à prévoir">Travaux à prévoir</IonSelectOption>
                <IonSelectOption value="Voir commentaire ci-dessous">Voir commentaire ci-dessous</IonSelectOption>
              </IonSelect>
            </IonItem>
          </>
        }

        <IonItem lines="none" style={{ height: '170px' }}>
          <IonLabel color="medium" position="floating" style={{ fontSize: '13px' }}>OBSERVATION :</IonLabel>
          <IonTextarea rows={5} cols={20} onIonChange={(e) => setObservationValue(e.detail.value || '')} value={observationValue} />
        </IonItem>

        <IonItem style={{ margin: "-30px" }}>
          <IonGrid>
            <IonRow style={{ display: 'flex', alignItems: 'center' }}>
              <IonCol size='8' style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '20px', marginBottom: '20px' }}>
                <div style={{ width: '120px', height: '80px', backgroundColor: '#e5e5e8', display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: '10px' }}>
                  <p style={{ color: '#9ba7ab' }}>
                  {imageUrl || anomaly.photoLocalBlob || anomaly.thumbnailLink ? (
                    <img referrerPolicy="no-referrer" src={imageUrl || anomaly.photoLocalBlob || anomaly.thumbnailLink || undefined} alt='Anomaly' onClick={() => setImageOpen(true)} />
                  ) : (
                    "Photo"
                  )}
                  </p>
                </div>
              </IonCol>
              <IonCol size='4'>
                <IonFabButton style={{ width: '40px', height: '40px', marginLeft: 'auto', marginRight: 'auto' }} color="dark">
                  <IonIcon icon={camera} onClick={takePicture} />
                </IonFabButton>
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonItem>

        <ImageViewer
          title={`ANOMALIE - ${installationName}`}
          {...getViewerPhotoProps({
            imageUrl,
            photoLocalBlob: anomaly.photoLocalBlob,
            photoFileId: anomaly.photoFileId,
          })}
          isOpen={imageOpen}
          setOpen={setImageOpen}
        />

      </IonContent>

    </IonPage >

  );
};

export default withStore(() => store.storeFor(state => state.currentInstallation),
  ({ name, equipments }, { match: { params: { equipmentId, anomalyType } } }) => {
    const anomalies = equipments[equipmentId].anomalies;
    return {
      installationName: name,
      inventory: equipments[equipmentId].inventory,
      anomaly: anomalies[anomalyType] !== undefined ? anomalies[anomalyType] : ({
        criticality: '',
        reason: '',
        observation: '',
        PV: false,
        photoName: '',
        photoFileId: null,
        thumbnailLink: null,
        photoLocalBlob: null,
        photoLocalBlobDirty: false,
      }),
    };
  },
  ({ match: { params: { equipmentId, anomalyType } } }) => ({
    saveAnomaly: anomalyUpdates => ({ equipments }) => {
      const anomaly = equipments[equipmentId].anomalies[anomalyType];

      if (
        anomalyUpdates.criticality === '' &&
        anomalyUpdates.reason === '' &&
        anomalyUpdates.observation === '' &&
        !anomalyUpdates.PV &&
        (anomaly === undefined || anomaly.photoName === '') &&
        anomalyUpdates.thumbnailLink === null &&
        anomalyUpdates.photoFileId === null &&
        anomalyUpdates.photoLocalBlob === null &&
        anomalyUpdates.photoLocalBlobDirty === false
      ) {
        anomalyUpdates = undefined;
      }

      if (anomalyUpdates && equipments[equipmentId].anomalies[anomalyType]) {
        Object.assign(equipments[equipmentId].anomalies[anomalyType], anomalyUpdates);
      }
      else {
        equipments[equipmentId].anomalies[anomalyType] = anomalyUpdates;
      }
    },
  }))(Anomaly);
