import React, { useState } from 'react';
import {
  IonContent,
  IonPage,
  IonGrid,
  IonRow,
  IonImg,
  IonFab,
  IonFabButton,
  IonIcon,
  IonCard,
  IonCardContent,
  IonInput, IonLabel, IonSegment, IonSegmentButton, IonButton, IonSpinner
} from '@ionic/react';
import { returnLeft } from 'ionicons/icons';
import { exportInstallations } from '../../controllers/procedures/exportController';
import { compileInventories } from '../../controllers/procedures/compilationController';
import { importInstallations } from '../../controllers/procedures/importController';
import { withStore } from '../../store/react-pure-store';
import store from '../../store/store';
import iInstallationList from '../../models/installationList.module';
import { versionName } from '../../config/versionName.json';

interface ProceduresProps {
  setInstallationList: (installationList: iInstallationList) => void;
};

const Procedures: React.FC<ProceduresProps> = ({ setInstallationList }) => {
  const [activeMenu, setActiveMenu] = useState<string>('import');
  
  const [importInstallationFile, setImportInstallationFile] = useState<string>('');
  const [importMaterialFile, setImportMaterialFile] = useState<string>('');
  const [importLoading, setImportLoading] = useState<boolean>(false);
  const [importState, setImportState] = useState<string | null>(null);
  
  const [exportListInstallationFile, setExportListInstallationFile] = useState<string>('');
  const [exportLoading, setExportLoading] = useState<boolean>(false);

  const [compileTargetFolder, setCompileTargetFolder] = useState<string>('');
  const [compileLoading, setCompileLoading] = useState<boolean>(false);

  const onClickImport = () => {
    setImportLoading(true);
    importInstallations(importInstallationFile, importMaterialFile, setImportState)
      .then((installationList) => {
        setImportLoading(false);
        if (installationList !== null) {
          setInstallationList(installationList);
        }
        alert(`L'import est terminé`);
      }).catch((err) => {
        setImportLoading(false);
        
        if (err.body) {
          try {
            if (err.status) {
              console.error(`error status: ${err.status}`);
            }

            console.error(JSON.parse(err.body));
          }
          catch (err) {
            console.error(err);
          }
        }
        else {
          console.error(err);
        }

        alert(`Une erreur est survenue${err.status ? ` (status code : ${err.status})` : ''} :\n${err.message || err.body}`);
      });
  }

  const onClickExport = () => {
    setExportLoading(true);
    exportInstallations(exportListInstallationFile)
      .then(() => {
        setExportLoading(false);
        alert(`L'export est terminé`);
      }).catch((err) => {
        setExportLoading(false);
        console.error(err);
        alert(`Une erreur est survenue :\n${err.message}`);
      });
  }
  
  const onClickCompile = () => {
    setCompileLoading(true);
    compileInventories(compileTargetFolder)
      .then(() => {
        setCompileLoading(false);
        alert(`La compilation est terminé`);
      }).catch((err) => {
        setCompileLoading(false);
        console.error(err);
        alert(`Une erreur est survenue :\n${err.message}`);
      });
  }

  return (
    <IonPage>
      <IonContent color="secondary">
        <IonFab vertical="top" horizontal="end" slot="fixed">
          <IonFabButton routerLink="/home">
            <IonIcon icon={returnLeft} />
          </IonFabButton>
        </IonFab>
        <IonGrid class="ion-no-padding">
          <IonRow class="ion-justify-content-center">
            <IonImg src="/assets/starterlight.png" alt="Starterlight Logo"/>
          </IonRow>
          <IonRow class="ion-justify-content-center" style={{
            color: '#555',
            fontSize: '0.75em',
            marginTop: '-3em',
            marginBottom: '2em',
          }}>
            Version: {versionName}
          </IonRow>
          <IonRow style={{backgroundColor: 'white'}}>
            <IonSegment onIonChange={e => setActiveMenu(e.detail.value as string)} value={activeMenu}>
              <IonSegmentButton value="import">
                <IonLabel>Import</IonLabel>
              </IonSegmentButton>
              <IonSegmentButton value="export">
                <IonLabel>Export</IonLabel>
              </IonSegmentButton>
              <IonSegmentButton value="compile">
                <IonLabel>Compilation</IonLabel>
              </IonSegmentButton>
            </IonSegment>
          </IonRow>
          <IonRow>
            {
              activeMenu === "import" && (
                <IonCard style={{width: '100%', backgroundColor: 'white'}}>
                  <IonCardContent>
                    <IonLabel>Fichier installations</IonLabel>
                    <IonInput value={importInstallationFile} placeholder="Fichier installations" onIonChange={e => setImportInstallationFile(e.detail.value!)}/>
                    <IonLabel>Fichier matériels</IonLabel>
                    <IonInput value={importMaterialFile} placeholder="Fichier matériels" onIonChange={e => setImportMaterialFile(e.detail.value!)}/>
                    <IonButton onClick={onClickImport} disabled={importLoading}>
                      {importLoading && <>
                        <IonSpinner name="crescent" />
                        &nbsp;
                      </>}
                      Importer
                    </IonButton>
                    {
                      importState !== null && <div>{importState}</div>
                    }
                  </IonCardContent>
                </IonCard>
              )
            }
            {
              activeMenu === "export" && (
                <IonCard style={{width: '100%', backgroundColor: 'white'}}>
                  <IonCardContent>
                    <IonLabel>Fichier liste d'installations</IonLabel>
                    <IonInput value={exportListInstallationFile} placeholder="Fichier liste d'installations" onIonChange={e => setExportListInstallationFile(e.detail.value!)}/>
                    <IonButton onClick={onClickExport} disabled={exportLoading}>
                      {exportLoading && <>
                        <IonSpinner name="crescent" />
                        &nbsp;
                      </>}
                      Exporter
                    </IonButton>
                  </IonCardContent>
                </IonCard>
              )
            }
            {
              activeMenu === "compile" && (
                <IonCard style={{width: '100%', backgroundColor: 'white'}}>
                  <IonCardContent>
                    <IonLabel>Nom de dossier cible</IonLabel>
                    <IonInput value={compileTargetFolder} placeholder="Nom de dossier cible" onIonChange={e => setCompileTargetFolder(e.detail.value!)}/>
                    <IonButton onClick={onClickCompile} disabled={compileLoading}>
                      {compileLoading && <>
                        <IonSpinner name="crescent" />
                        &nbsp;
                      </>}
                      Compiler les données
                    </IonButton>
                  </IonCardContent>
                </IonCard>
              )
            }
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default withStore(store, null, () => ({
  setInstallationList: (installationList) => state => {
    state.installationList = installationList;
  },
}))(Procedures);
