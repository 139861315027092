import {
  copyFile,
  createFolder,
  createSpreadsheet,
  listDriveFiles,
  loadSpreadsheet,
  writeSpreadsheet,
} from "../gapi";
import {
  mapSheetToStoreCreator,
  mapStoreToSheetCreator,
  sheetColumnsCreator,
} from "../mappers";
import { installationListMapping } from "./sheetDataMappings";
import { inventoryMapping } from "../sheetDataMappings";
import {
  Types,
  getFile,
  getFiles,
  getExportsFolderId,
  getRegionFolderId,
  getInstallationsFolderId,
  getInstallationFolderId,
  checkDestinationFolder,
} from "../util";

const loadInstallationList = async (exportFolderId, spreadsheetName) => {
  const spreadsheetId = await getFile(
    exportFolderId,
    spreadsheetName,
    Types.Spreadsheet
  );

  const spreadsheetData = await loadSpreadsheet(
    spreadsheetId,
    mapSheetToStoreCreator(installationListMapping)
  );

  return spreadsheetData.map(({ DIcode }) => DIcode);
};

const getInstallationEquipmentsFileIds = async (
  installationsFolderId,
  installationId
) => {
  const installationFolderId = await getInstallationFolderId(
    installationsFolderId,
    installationId
  );

  const installationFiles = await getFiles(installationFolderId, {
    Inventaire: Types.Spreadsheet,
    Photos: Types.Folder,
  });

  if (installationFiles["Inventaire"] === undefined) {
    throw new Error(
      `Spreadsheet d'inventaire (${installationFolderId}) introuvable`
    );
  }

  if (installationFiles["Photos"] === undefined) {
    throw new Error(`Dossier de photos (${installationFolderId}) introuvable`);
  }

  return {
    inventorySpreadsheetId: installationFiles["Inventaire"],
    photosFolderId: installationFiles["Photos"],
  };
};

const createExportFiles = async (exportFolderId, spreadsheetName) => {
  const childExportFolderId = await createFolder(
    exportFolderId,
    spreadsheetName
  );

  const inventorySpreadsheetId = await createSpreadsheet(
    childExportFolderId,
    "Inventaire",
    ["Feuille 1"]
  );

  const photosFolderId = await createFolder(childExportFolderId, "Photos");

  return {
    inventorySpreadsheetId,
    photosFolderId,
  };
};

const getInstallationEquipments = async (
  installationInventorySpreadsheetId
) => {
  const data = await loadSpreadsheet(
    installationInventorySpreadsheetId,
    mapSheetToStoreCreator(inventoryMapping),
    "Feuille 1"
  );

  return data;
};

const copyInstallationPhotos = async (
  installationPhotosFolderId,
  photosFolderId
) => {
  const fileList = await listDriveFiles(installationPhotosFolderId);

  for (const file of fileList) {
    await copyFile(file.id, photosFolderId, file.name);
  }
};

const writeEquipments = async (inventorySpreadsheetId, equipments) => {
  await writeSpreadsheet(
    inventorySpreadsheetId,
    equipments.map((equipment, index) => [index, equipment]),
    equipments.length > 0
      ? mapStoreToSheetCreator(inventoryMapping)
      : sheetColumnsCreator(inventoryMapping),
    "Feuille 1"
  );
};

export const exportInstallations = async (spreadsheetName) => {
  const regionFolderId = getRegionFolderId();

  const exportFolderId = await getExportsFolderId(regionFolderId);

  const installationList = await loadInstallationList(
    exportFolderId,
    spreadsheetName
  );

  await checkDestinationFolder(exportFolderId, spreadsheetName);

  const { inventorySpreadsheetId, photosFolderId } = await createExportFiles(
    exportFolderId,
    spreadsheetName
  );

  const installationsFolderId = await getInstallationsFolderId(regionFolderId);

  const equipments = [];

  await Promise.all(
    installationList.map(async (installationId) => {
      const {
        inventorySpreadsheetId: installationInventorySpreadsheetId,
        photosFolderId: installationPhotosFolderId,
      } = await getInstallationEquipmentsFileIds(
        installationsFolderId,
        installationId
      );

      const installationEquipments = await getInstallationEquipments(
        installationInventorySpreadsheetId
      );

      equipments.push(...installationEquipments);

      await copyInstallationPhotos(installationPhotosFolderId, photosFolderId);
    })
  );

  await writeEquipments(inventorySpreadsheetId, equipments);
};
